 <div class="container">
      <div class="container-client">
        <div class="header">
          <h1 class="title">{{'NEW_RESOURCE' | translate}}</h1>
          <app-back-arrow-settings [callback]="setListingResources"></app-back-arrow-settings>
          </div>
        <div class="form-card">
          <form [formGroup]="resourceForm" (ngSubmit)="createResource()" class="content row">
            <div class="title">
              <span>{{'NEW_RESOURCE' | translate}}</span>
            </div>
            <div class="row">
              <div class="column col-md-6">
                <label class="form-label" for="name">{{'NAME' | translate}}</label>
                <input class="form-control" id="name" formControlName="name" type="text" placeholder="{{'RESOURCES_NAME' | translate}}">
              </div>
              <div class="column col-md-6">
                <label class="form-label" for="email">{{ 'EMAIL' | translate}}</label>
                <input class="form-control" id="email" formControlName="email" type="email" placeholder="email@gmail.com">
              </div>
            </div>
            <div class="row mt-2">
              <div class="column col-md-6 d-none">
                <label class="form-label" for="timezone">Timezone</label>
                <input class="form-control" id="timezone" formControlName="timezone" type="text"
                  placeholder="Ej: America/Costa_Rica">
              </div>
              <div class="column col-md-6 d-none">
                <label class="form-label" for="password">{{'PASSWORD' | translate}}</label>
                <div class="pool-register">
                  <input class="form-control" id="password" formControlName="password" type="password">
                  <button type="button" class="btn btn-secondary">Mostrar</button>
                </div>
              </div>
            </div>
            <div class="create-btn">
              <button class="btn primary-btn" type="submit">{{'CREATE_RESOURCE' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div class="modal client-confirm-modal" id="successResourceModal" tabindex="-1">
      <div class="modal-dialog client-modal-dialog" role="document">
        <div class="modal-content client-modal-content">
          <div class="modal-body client-modal-body">
            <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
            <p class="text-center">{{'RESOURCE_CREATED_SUCCESS' | translate}}</p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal error-confirm-modal fade" id="requiredResourceModal" tabindex="-1">
      <div class="modal-dialog error-modal-dialog" role="document">
        <div class="modal-content error-modal-content">
          <div class="modal-body error-modal-body">
            <h3 class="text-center">{{'ERROR' | translate}}</h3>
            <p class="text-center">{{ 'ALL_FIELDS_REQUIRED' | translate }}</p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal client-confirm-modal fade" id="errorResourceModal" tabindex="-1">
      <div class="modal-dialog client-modal-dialog" role="document">
        <div class="modal-content client-modal-content">
          <div class="modal-body client-modal-body">
            <h3 class="text-center">{{'ERROR' | translate}}</h3>
            <p class="text-center">{{'¡ERROR_CREATE_RESOURCE!' | translate}}</p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
