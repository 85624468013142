<!-- <div class="container"> -->
  <div class="container-inventory">
    <!-- <div class="header">
      <h1 class="title">Impuestos</h1>
    </div> -->

    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div *ngIf="!loading" class="form-group-container">
        <div class="label">
          <div class="label-group">
            <i class="feather" data-feather="percent"></i>
            <strong>{{'TAX' | translate}}</strong>
          </div>
        </div>
        <div class="info-container">
          <div class="data-container">
            <label class="data-label" for="">{{'TYPE_TAX' | translate}}</label>
            <select class="form-select" [(ngModel)]="taxId" (change)="changeTax($event)">
              <option *ngFor="let tax of taxes" [value]="tax.taxId">
                  {{ tax.taxName }}
              </option>
          </select>
          </div>
        </div>
        <div class="form-buttons">
          <button type="button" class="btn btn-outline-secondary submit-btn" (click)="saveChanges()">
            {{'SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->

<div class="modal tax-modal" id="taxModal" tabindex="-1">
  <div class="modal-dialog tax-modal-dialog" role="document">
    <div class="modal-content tax-modal-content">
      <div class="modal-body tax-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal tax-confirm-modal fade" id="successTaxModal" tabindex="-1">
  <div class="modal-dialog tax-modal-dialog" role="document">
    <div class="modal-content tax-modal-content">
      <div class="modal-body tax-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'TAX_TYPE_SAVED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
