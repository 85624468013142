<div class="container">
  <div class="container-client">
    <div class="header">
      <h1 class="title">{{'PROJECTS' | translate}}</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingProjects()">{{'CREATE' | translate}}</button>
    </div>


    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>

      <div *ngIf="showNoProjectsMessage && !loading" class="no-orders">
        <span> {{'NO_PROJECTS' | translate}}</span>
      </div>

      <table *ngIf="!loading && projects.length > 0" class="table table-bordered table-hover">
        <thead>
            <th scope="col" class="table-start">{{'PROJECT_NAME' | translate}}</th>
            <th scope="col" class="table-end"> {{'PROJECT_ID' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let project of projects">
            <td>{{ project.projectName || 'Sin nombre' }}</td>
            <td>{{ project.projectId }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
