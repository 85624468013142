<div class="container">
  <div class="container-client">
    <div class="header">
      <h1 class="title">{{'RESOURCES' | translate}}</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingResources()">{{'CREATE' | translate}}</button>
    </div>

    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading && resources.length > 0" class="table table-bordered table-hover">
        <thead >
            <th scope="col" class="table-start">{{'RESOURCE_NAME' | translate}}</th>
            <th scope="col" class="table-end">{{'RESOURCE_ID' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let resource of resources">
            <td>{{ resource.resourceName || 'Sin nombre' }}</td>
            <td>{{ resource.resourceId }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="resources.length === 0" class="no-orders">
        <span>{{'NO_RESOURCES' | translate}}</span>
      </div>
    </div>
  </div>
</div>
