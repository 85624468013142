<div class="container">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="container-component" *ngIf="!loading">
    <div class="header">
      <h3 class="title">{{'EDIT_COMPONENT' | translate}}</h3>
    </div>
    <form>
      <div class="form-info">
        <div class="form-trio col-md-6">
          <div class="form-floating">
            <input type="text" class="form-control" id="componentName" placeholder="{{'COMPONENT_NAME' | translate}}" [(ngModel)]="component.componentName" name="componentName" (ngModelChange)="changeComponentName($event)" maxlength="40"/>
            <label for="componentName">{{'COMPONENT_NAME' | translate}}</label>
          </div>
          <div class="form-floating" *ngIf="getFieldVisibilityConfig().includes('title')">
            <input type="text" class="form-control" id="componentTitle" placeholder="{{'TITLE' | translate}}" [(ngModel)]="component.title" name="componentTitle" (ngModelChange)="changeComponentTitle($event)" maxlength="50"/>
            <label for="componentTitle">{{'TITLE' | translate}}</label>
          </div>
          <div class="form-floating" *ngIf="getFieldVisibilityConfig().includes('subtitle')">
            <input type="text" class="form-control" id="componentSubtitle" placeholder="{{'SUBTITLE' | translate}}" [(ngModel)]="component.subtitle" name="componentSubtitle" (ngModelChange)="changeComponentSubtitle($event) "maxlength="50"/>
            <label for="componentSubtitle">{{'SUBTITLE' | translate}}</label>
          </div>
          <div *ngIf="getFieldVisibilityConfig().includes('description')">
            <label class="form-label info-description" for="component_description">{{'DESCRIPTION' | translate}}</label>

            <!-- Editor de Quill para la edición -->
            <quill-editor
              class="description-editor"
              id="description-edit"
              name="description"
              [(ngModel)]="component.description"
              [modules]="modules"
              [styles]="{
                borderRadius: '0 0 10px 10px',
                padding: '5px',
                marginBottom: '2vh'
              }"
              (onEditorChanged)="descriptionChanged($event)"
              #descriptionInput="ngModel"
            >
            </quill-editor>

            <!-- Mostrar los caracteres restantes -->
            <div>
              <small>{{'REMAINING_CHARACTERS' | translate}} {{ remainingCharacters }} / {{ maxLength }}</small>
            </div>
          </div>


        </div>
        <div *ngIf="component.componentParentId !== 3"class="preview-image-container col-md-4">
          <label for="component_img">{{component.componentTypeName}}</label>
          <img  class="design-image" [src]="sanitizeImageURL(component.imageUrl)" alt="Design Image"/>
        </div>
      </div>
      <div *ngIf="component.componentParentId == 3" class="input-container">
        <div class="form-info">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="collections" [(ngModel)]="selectedOption" (change)="onOptionChange('collections')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio2">{{'COLLECTIONS' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="categories" [(ngModel)]="selectedOption" (change)="onOptionChange('categories')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio1">{{'CATEGORIES' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="subCategories" [(ngModel)]="selectedOption" (change)="onOptionChange('subCategories')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio2">{{'SUBCATEGORY' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="subSubcategories" [(ngModel)]="selectedOption" (change)="onOptionChange('subSubcategories')" name="selectedOption">
            <label class="form-check-label" for="inlineRadio2">{{'SUB_SUBCATEGORIES' | translate}}</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="custom" disabled>
            <label class="form-check-label" for="inlineRadio3">Personalizado</label>
          </div>
        </div>

        <div class="form-info">
          <div *ngIf="selectedOption === 'collections'" class="form-floating">
            <select class="form-select" id="collections" aria-label="Floating label select example" [(ngModel)]="collectionId" (change)="onCollectionChange($event)" name="collectionId">
              <option selected disabled>{{'SELECT_COLLECTION' | translate}}</option>
              <option *ngFor="let collection of collections" [value]="collection.collectionId">
                {{ collection.collectionName }}
              </option>
            </select>
            <label for="collections">{{'COLLECTIONS' | translate}}</label>
          </div>

          <div *ngIf="selectedOption === 'categories'" class="form-floating">
            <select class="form-select" id="category" aria-label="Floating label select example" [(ngModel)]="categoryId" (change)="onCategoryChange($event)" name="categoryId">
              <option selected disabled>{{'SELECT_CATEGORY' | translate}}</option>
              <option *ngFor="let category of categories" [value]="category.categoryId">
                {{ category.categoryName }}
              </option>
            </select>
            <label for="category">{{'CATEGORY' | translate}}</label>
          </div>

          <div *ngIf="selectedOption === 'subCategories'" class="form-floating">
            <select class="form-select" id="subCategory" aria-label="Floating label select example" [(ngModel)]="subCategoryId" (change)="onSubCategoryChange($event)" name="subCategoryId">
              <option selected disabled>{{'SELECT_CATEGORY' | translate}}</option>
              <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId">
                {{ subCategory.subCategoryName }}
              </option>
            </select>
            <label for="subCategory">{{'SUBCATEGORY' | translate}}</label>
          </div>

          <div *ngIf="selectedOption === 'subSubcategories'" class="form-floating">
            <select class="form-select" id="subSubCategory" aria-label="Floating label select example" [(ngModel)]="subSubCategoryId" (change)="onSubSubCategoryChange($event)" name="subSubCategoryId">
              <option selected disabled>{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
              <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId">
                {{ subSubCategory.subSubCategoryName }}
              </option>
            </select>
            <label for="subSubCategory">{{'SUB_SUBCATEGORY' | translate}}</label>
          </div>
        </div>
      </div>

      <div *ngIf="component.componentParentId === 3" class="component-types">
        <h5>{{ 'SELECT_LAYOUT' | translate }}</h5>
        <div class="component-type-selection">
          <div class="form-check form-check-inline" *ngFor="let type of componentTypes">
            <input class="form-check-input d-none" type="radio" [value]="type.componentTypeId" [(ngModel)]="component.componentTypeId" [id]="'componentType' + type.componentTypeId" name="componentTypeId" [checked]="component.componentTypeId === type.componentTypeId"/>
            <label class="form-check-label" [for]="'componentType' + type.componentTypeId">
              <p class="image-label">{{ type.componentTypeName }}</p>
              <img [src]="type.imageUrl" [alt]="type.componentTypeDescription"
                   class="component-type-image"
                   [class.selected]="component.componentTypeId === type.componentTypeId"
                   (click)="onComponentTypeSelected(type.componentTypeId)"/>
            </label>
          </div>
        </div>
      </div>

      <div class="col-md-6" *ngIf="getFieldVisibilityConfig().includes('itemQuantity')">
        <label class="form-label" for="componentAmount">{{ 'ITEMS_QUANTITY' | translate }}</label>
        <select class="form-select" id="componentAmount" [(ngModel)]="component.itemQuantity" name="itemQuantity">
          <option selected disabled>{{ 'SELECT_ITEMS_QUANTITY' | translate }}</option>
          <option *ngFor="let quantity of [4, 8, 12, 16]" [value]="quantity">
            {{ quantity }}
          </option>
        </select>
      </div>

      <div *ngIf="component.componentParentId !== 3" >
        <div class="images-container">
          <div class="img-label">{{ 'ADD_ITEMS' | translate }}</div>
          <div>
            <button class="add-img" (click)="addNewField()">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48">
                <path fill="none" stroke="#6BBECD" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                    d="M16 6H8a2 2 0 0 0-2 2v8m10 26H8a2 2 0 0 1-2-2v-8m26 10h8a2 2 0 0 0 2-2v-8M32 6h8a2 2 0 0 1 2 2v8m-10 8H16m8 8V16" />
              </svg>
            </button>
          </div>
          <div cdkDropList cdkDropListOrientation="horizontal" class="image-list">
            <div class="input-container">
              <div *ngFor="let info of component.componentInfo; let i = index" class="image-box">
                <div class="form-duo col-md-8">
                  <div class="form-info">
                    <div  class="form-floating form-input col-md-6" *ngIf="getFieldVisibilityConfig().includes('infoTitle')">
                      <input type="text"  class="form-control" [(ngModel)]="info.infoTitle" (ngModelChange)="changeComponentInfoTitle($event, i)" placeholder="Titulo" name="imageInfoTitle-{{i}}" maxlength="50" >
                      <label for="component_input">{{'TITLE' | translate}}</label>
                    </div>
                    <div class="form-floating form-input col-md-5" *ngIf="getFieldVisibilityConfig().includes('urlName')">
                      <input type="text" class="form-control" [(ngModel)]="info.urlName" (ngModelChange)="changeComponentUrlName($event, i)" placeholder="Nombre del enlace" name="imageUrlName-{{i}}" maxlength="50">
                      <label for="component_input">{{'NAME_LINK_BUTTON' | translate}}</label>
                    </div>
                  </div>
                  <div class="form-duo">
                    <div class="form-input form-floating" *ngIf="getFieldVisibilityConfig().includes('redirectUrl')">
                      <input type="text" class="form-control" [(ngModel)]="info.redirectUrl" (ngModelChange)="changeComponentRedirectUrl($event, i)" placeholder="{{'LINK_URL_OPTIONAL' | translate}}" name="imageRedirectUrl-{{i}}">
                      <label for="component_input">{{'LINK_URL_OPTIONAL' | translate}}</label>
                    </div>

                      <div *ngIf="getFieldVisibilityConfig().includes('infoDescription')">
                        <label class="form-label" for="infoDescription">{{'DESCRIPTION' | translate}}</label>
                        <div class="form-input">
                            <quill-editor
                              class="info-description-editor"
                              id="info-description-edit-{{i}}"
                              name="infoDescription-{{i}}"
                              [(ngModel)]="info.infoDescription"
                              [modules]="modules"
                              [styles]="{
                                borderRadius: '0 0 10px 10px',
                                padding: '5px',
                                marginBottom: '2vh'
                              }"
                              (onEditorChanged)="changeComponentInfoDescription($event, i)"
                              #infoDescriptionInput="ngModel"
                            >
                            </quill-editor>
                            <div *ngIf="remainingInfoCharacters >= 0">
                              {{'REMAINING_CHARACTERS' | translate}} {{ remainingInfoCharacters }}
                            </div>
                          </div>
                      </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <button type="button" class="btn btn-danger delete-button" (click)="removeField(i)">x</button>
                  <img *ngIf="info.infoImageUrl && !info.selected" [src]="sanitizeImageURL(info.infoImageUrl)" alt="Image Preview">
                  <img *ngIf="info.infoImageUrl && info.selected" [src]="sanitizeImageURL(info.infoImageUrl)" alt="New Image">
                  <a *ngIf="getFieldVisibilityConfig().includes('infoImageUrl')" class="add-button" (click)="triggerFileInput(i)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48"><g fill="#5E35B1"><path d="M33.9 12.1H14.2L17.6 7c.4-.6 1-.9 1.7-.9h9.6c.7 0 1.3.3 1.7.9zM14 11H8V9.2C8 8.5 8.5 8 9.2 8h3.6c.7 0 1.2.5 1.2 1.2z"/><path d="M40 42H8c-2.2 0-4-1.8-4-4V14c0-2.2 1.8-4 4-4h32c2.2 0 4 1.8 4 4v24c0 2.2-1.8 4-4 4"/></g><path fill="#E8EAF6" d="M34 25c0-5.5-4.5-10-10-10c-2.4 0-4.6.8-6.3 2.2l1.2 1.6C20.3 17.7 22 17 24 17c4.4 0 8 3.6 8 8h-3.5l4.5 5.6l4.5-5.6zm-4.9 6.2C27.7 32.3 25.9 33 24 33c-4.4 0-8-3.6-8-8h3.5L15 19.4L10.5 25H14c0 5.5 4.5 10 10 10c2.4 0 4.6-.8 6.3-2.2z"/></svg>
                  </a>
                  <input type="file" id="btn-upload-{{i}}" class="d-none" (change)="onFileSelected($event, i)">
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
          {{'SAVE' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="modal component-confirm-modal fade" id="successEditComponentModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'COMPONENT_SAVED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="modal component-confirm-modal fade" id="errorEditComponentModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal component-confirm-modal fade" id="errorEditMaxMinItemModal" tabindex="-1">
  <div class="modal-dialog component-modal-dialog" role="document">
    <div class="modal-content component-modal-content">
      <div class="modal-body component-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{ errorMessage }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
