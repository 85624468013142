  <div class="container-client my-4">
    <div class="header">
      <h1 class="title">{{'NEW_PROJECT' | translate}}</h1>
      <app-back-arrow-settings [callback]="setListingProjects"></app-back-arrow-settings>
      </div>
    <div class="form-card">
      <form [formGroup]="projectForm" (ngSubmit)="createProject()">
        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label" for="name">{{'PROJECT_NAME' | translate}}</label>
            <input class="form-control" id="name" formControlName="name" type="text" placeholder="">
          </div>
          <div class="col-md-6">
            <label class="form-label" for="slug">{{'IDENTIFIER' | translate}}</label>
            <input class="form-control" id="slug" formControlName="slug" type="text" placeholder="Ej: my-test-project">
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label class="form-label">{{'ALLOW_CONFERENCE' | translate}}</label>
            <select class="form-select" formControlName="allow_conference">
              <option value="1">{{'YES' | translate}}</option>
              <option value="0">{{'NO' | translate}}</option>
            </select>
          </div>
          <div class="col-md-6">
            <label class="form-label" for="resource">Recurso</label>
            <select class="form-select" id="resource" formControlName="resource" (change)="changeResource($event)">
              <option *ngFor="let resource of resources" [value]="resource.resourceId">{{ resource.resourceName }}</option>
            </select>
          </div>
        </div>

        <div formGroupName="availability">
          <div class="row mb-3">
            <div class="col-md-3 d-none">
              <label class="form-label">Modo</label>
              <input type="text" class="form-control" formControlName="mode">
            </div>
            <div class="col-md-4">
              <label class="form-label">{{'DURATION' | translate}}</label>
              <select class="form-control" formControlName="length">
                <option *ngFor="let hour of hours" [value]="hour + ' hours'">{{ hour }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="form-label">{{'FROM' | translate}}</label>
              <select class="form-control" formControlName="from">
                <option *ngFor="let hour of hours" [value]="hour + ' hours'">{{ hour }}</option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="form-label">{{'TO' | translate}}</label>
              <select class="form-control" formControlName="to">
                <option *ngFor="let month of months" [value]="month + ' months'">{{ month }}</option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <label class="form-label">{{'SPACING_SESSIONS' | translate}}</label>
              <select class="form-control" formControlName="buffer">
                <option *ngFor="let minute of minutes" [value]="minute + ' minutes'">{{ minute }}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="form-label">{{'IGNORE_EVENTS' | translate}}</label>
              <select class="form-select" formControlName="ignore_all_day_events">
                <option [ngValue]="true">{{'YES' | translate}}</option>
                <option [ngValue]="false">{{'NO' | translate}}</option>
              </select>
            </div>
          </div>
        </div>

        <div formArrayName="availability_constraints">
          <h4 class="subtitle">{{'AVAILABILITY_RESTRICTIONS' | translate}}</h4>
          <div *ngFor="let constraint of availabilityConstraints.controls; let i = index">
            <div [formGroupName]="i">
              <div formGroupName="allow_day_and_time">
                <div class="row mb-3">
                  <div class="col-md-3">
                    <label class="form-label">{{'DAY' | translate}}</label>
                    <!-- <select class="form-select" formControlName="day">
                      <option *ngFor="let day of daysOfWeek" [value]="day.toLowerCase()">{{day}}</option>
                    </select> -->
                    <select class="form-select" formControlName="day">
                      <option *ngFor="let day of daysOfWeekInSpanish; let i = index" [value]="getDayInEnglish(i)">{{ day }}</option>
                    </select>
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">{{'START_PROJECT' | translate}}</label>
                    <input type="time" class="form-control" formControlName="start">
                  </div>
                  <div class="col-md-3">
                    <label class="form-label">{{'END' | translate}}</label>
                    <input type="time" class="form-control" formControlName="end">
                  </div>
                  <div class="col-md-3">
                    <button type="button" class="btn btn-danger mt-4" (click)="removeAvailabilityConstraint(i)">{{'DELETE' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn primary-btn mb-3" (click)="addAvailabilityConstraint()">{{'ADD_RESTRICTION' | translate}}</button>
        </div>


        <div formGroupName="booking">
          <h4 class="subtitle">{{'BOOKINGS_DETAIL' | translate}}</h4>
          <div class="row mb-3">
            <div class="col-md-6">
              <label class="form-label">{{'DESCRIPTION' | translate}}</label>
              <input type="text" class="form-control" formControlName="description" placeholder="">
            </div>
            <div class="col-md-6">
              <label class="form-label">{{'EVENT' | translate}}</label>
              <input type="text" class="form-control" formControlName="what">
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6">
              <label class="form-label">{{'LOCATION' | translate}}</label>
              <input type="text" class="form-control" formControlName="where" placeholder="{{'VIRTUAL_ONSITE' | translate}}">
            </div>
            <div class="col-md-6 d-none">
              <label class="form-label">Gráfico</label>
              <input type="text" class="form-control" formControlName="graph">
            </div>
          </div>
        </div>

        <button type="submit" class="btn primary-btn">{{'CREATE_PROJECT' | translate}}</button>
      </form>
    </div>
  </div>



  <div class="modal client-confirm-modal" id="successProjectModal" tabindex="-1">
    <div class="modal-dialog client-modal-dialog" role="document">
      <div class="modal-content client-modal-content">
        <div class="modal-body client-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{'PROJECT_CREATE_SUCCESS' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal error-confirm-modal fade" id="requiredProjectModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{ 'ALL_FIELDS_REQUIRED' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal client-confirm-modal fade" id="errorProjectModal" tabindex="-1">
    <div class="modal-dialog client-modal-dialog" role="document">
      <div class="modal-content client-modal-content">
        <div class="modal-body client-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_CREATE_PROJECT' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

