import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Debounce } from 'angular-debounce-throttle';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';

import * as XLSX from "xlsx";

declare var $: any;

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  products: any[] = [];
  totalAmount: number = 0;
  searchString: string = '';

  isActive: string = '';
  creationDateSort: string = 'DESC';
  quantitySort: string = '';

  pages: number[] = [1];
  totalPages: number = 1;
  currentPage: number = 1;
  maxNumberOfPages: number = 5; //Odd numbers only
  amount: number = 20;

  loading: boolean = false;
  ModalLoading: boolean = false;

  selectedProductIds: any[] = [];

  //Items preview
  previewPageSize: number[] = [5, 10, 15, 20, 50, 100];
  previewPageSizeindex: number = 0;
  previewPageNumber = 1;
  previewTotalPages = 1;
  previewPages = [1];
  previewData:any[] = [];
  previewDataPagination: any[] = [];
  previewError:boolean = false;

  itemCodesString: string = "";

  //Shop keys
  categories: any = {};
  subCategories: any = {};
  subSubCategories: any = {};
  industries: any = {};
  collections: any = {};
  suppliers: any = {};
  taxes: any = {};
  states: any = [
    {
      state: 0,
      name: "inactivo"
    },
    {
      state: 1,
      name: "activo"
    }
  ];

  //uploading products
  uploadingProducts = 0;
  totalProducts = 0;
  uploadPercentaje = 0;
  uploadErrors: any[] = [];
  isUploading = false;
  uploadingStyle= 'width: 0%';

  //header format for validations
  xlsxValid: boolean = true;
  xlsxHeaderTemplateFormat: string[] = [
    "name",
    "description",
    "productCode",
    "variantType1",
    "variantValues1",
    "variantType2",
    "variantValues2",
    "variantType3",
    "variantValues3",
    "categoryId",
    "subCategoryId",
    "subSubCategoryId",
    "industryId",
    "collectionId",
    "supplierId",
    "state",
    "costPrice",
    "taxId",
    "salePrice",
    "discountPrice",
    "quantity"
  ]

  productTemplate: any[] = [
    {
      "name": "Nombre del producto",
      "description": "Descripción del producto",
      "productCode": "00001",
      "variantType1": "Color",
      "variantValues1": "Blanco",
      "variantType2": "Tipo",
      "variantValues2": "Polo",
      "variantType3": "",
      "variantValues3": "",
      "categoryId": 1,
      "subCategoryId": 0,
      "subSubCategoryId": 0,
      "industryId": 1,
      "collectionId": 0,
      "supplierId": 0,
      "state": 1,
      "costPrice": 3000,
      "taxId": 1,
      "salePrice": 7000,
      "discountPrice": 0,
      "quantity": 12
    }
  ]

  constructor(
    public appComponent: AppComponent,
    private apiService: ApiService,
    public themingService: ThemingService,
    public router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentPage = 1;
    this.getProducts();
  }

  @Debounce(500)
  serchProducts() {
    this.currentPage = 1;
    this.getProducts();
  }
  async getKeys(){
    const shopId = this.themingService.shopId;

    this.categories = await this.apiService.getCategories(shopId) as any;
    this.categories = this.categories.categories.map((item:any) => {
      return {
        category: item.categoryId,
        name: item.categoryName
      }
    })

    this.subCategories = await this.apiService.getSubCategories(shopId) as any;
    this.subCategories = this.subCategories.subCategories.map((item:any) => {
      return {
        subCategory: item.subCategoryId,
        name: item.subCategoryName,
        category_padre: item.categoryId
      }
    })
    //console.log(this.subCategories);
    
    
    this.subSubCategories = await this.apiService.getSubSubCategories(shopId) as any;
    this.subSubCategories = this.subSubCategories.subSubCategories.map((item:any) => {
      return {
        subSubCategory: item.subSubCategoryId,
        name: item.subSubCategoryName,
        sub_category_padre: item.subCategoryId
      }
    })
    //console.log(this.subSubCategories);
    
    this.taxes = await this.apiService.getTaxes(shopId) as any;
    this.taxes = this.taxes.filter((item:any) => item.taxId !== 2);
    this.taxes = this.taxes.map((item:any) => {
      return {
        tax: item.taxId,
        name: item.taxName
      }
    })
    //console.log(this.taxes);

    this.industries = await this.apiService.getIndustries() as any;
    this.industries = this.industries.industries.map((item:any) => {
      return {
        industry: item.industryTypeId,
        name: item.industryTypeName
      }
    })

    this.collections = await this.apiService.getCollections(shopId) as any;
    if(this.collections){
      this.collections = this.collections.collections.map((item:any) => {
        return {
          collection: item.collectionId,
          name: item.collectionName
        }
      })
    }

    this.suppliers = await this.apiService.getSuppliers(shopId) as any;
    if(this.suppliers){
      this.suppliers = this.suppliers.suppliers.map((item:any) => {
        return {
          supplier: item.supplierId,
          name: item.supplierName
        }
      })
    }
  }

  @Debounce(500)
  filterSearch() {
    this.currentPage = 1;
    this.getProducts();
  }

  async getProducts() {
    this.loading = true;
    this.selectedProductIds = []
    await this.apiService
      .getProducts(
        this.themingService.shopId,
        this.currentPage - 1,
        this.amount,
        this.searchString,
        this.isActive,
        this.creationDateSort,
        this.quantitySort
      )
      .then((data: any) => {
        console.log(data);
        this.totalPages = data.pagination[0].lastPage;
        this.formatPages();
        this.products = data.products.map((product: any) => {
          product.productCreationDate = this.appComponent.formatDate(product.productCreationDate);
          if(product.items){
            product.totalQuantity = product.items.reduce((acc: any, item: any) => acc + item.quantity, 0);
          }else{
            product.totalQuantity = "";
          }
          console.log(product.quantity);
          
            product.totalQuantity = product.productType == 2 ? product.quantity : product.totalQuantity;
          
          
          return product;
        });
      });
    this.loading = false;
  }

  syncInventory() {
    $('#productSyncModal').modal({ backdrop: 'static', keyboard: false });
    $('#productSyncModal').modal('show');
    this.apiService
      .updateInventory(this.themingService.shopId)
      .then((data: any) => {
        $('#productSyncModal').modal('hide');
        if (data == 200) {
          this.getProducts();
        } else {
          $('#productSyncErrorModal').modal('show');
        }
      })
      .catch((error: any) => {
        console.error(error);
        $('#productSyncModal').modal('hide');
        $('#productSyncErrorModal').modal('show');
      });
  }

  syncImages(){
    $('#productSyncModal').modal({ backdrop: 'static', keyboard: false });
    $('#productSyncModal').modal('show');
    this.apiService
      .updateImages(this.themingService.shopId)
      .then((data: any) => {
        $('#productSyncModal').modal('hide');
        if (data == 200) {
          this.getProducts();
        } else {
          $('#productSyncErrorModal').modal('show');
        }
      })
      .catch((error: any) => {
        console.error(error);
        $('#productSyncModal').modal('hide');
        $('#productSyncErrorModal').modal('show');
      });
  }

  goToProduct(productId: string) {
    this.router.navigate(['products/', productId]);
  }

  movePage(forward: boolean) {
    this.loading = true;
    this.currentPage = this.currentPage + (forward ? 1 : -1);
    this.getProducts();
  }

  goToPage(page: number) {
    this.loading = true;
    this.currentPage = page;
    this.getProducts();
  }

  async formatPages() {
    this.appComponent.goToTop();
    this.pages = [];
    let pagesLength = this.maxNumberOfPages;
    let startPage = 1;
    if (this.maxNumberOfPages >= this.totalPages) {
      pagesLength = this.totalPages;
    } else {
      startPage = this.currentPage - (this.maxNumberOfPages - 1) / 2;
      if (startPage < 1) {
        startPage = 1;
      }

      if (
        this.currentPage + (this.maxNumberOfPages - 1) / 2 >=
        this.totalPages
      ) {
        startPage = this.totalPages - this.maxNumberOfPages + 1;
      } else {
        pagesLength = pagesLength - 1;
      }

      if (startPage >= (this.maxNumberOfPages - 1) / 2) {
        startPage = startPage + 1;
        pagesLength = pagesLength - 1;
      }
    }
    this.pages = Array.from({ length: pagesLength }, (_, i) => i + startPage);
  }

  getCurrentDateTime():string {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1);
    const day = String(date.getDate());
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours from 24-hour format to 12-hour format
    const hours12 = hours % 12 || 12;
  
    const formattedDate = `${month}/${day}/${year}`;
    const formattedTime = `${hours12}:${minutes}:00 ${ampm}`;
  
    return `${formattedDate}, ${formattedTime}`;
  }

  getItemVariantValues(product:any):any[] {
    const itemVariantValues = [
      {
        "variantValueName":  product.variantValues1, //TODO
      },
      {
        "variantValueName":  product.variantValues2, //TODO
      },
      {
        "variantValueName":  product.variantValues3, //TODO
      }
    ].filter((item:any) => item.variantValueName)

    return itemVariantValues
  }

  getVariants(product:any):any[]{
    const variants = [
      {
        "variantTypeName": product.variantType1,
        "variantValues": [
          {
            "variantValueName": product.variantValues1,
            "variantValuePosition": 0
          },
        ]
      },
      {
        "variantTypeName": product.variantType2,
        "variantValues": [
          {
            "variantValueName": product.variantValues2,
            "variantValuePosition": 0
          },
        ]
      },
      {
        "variantTypeName": product.variantType3,
        "variantValues": [
          {
            "variantValueName": product.variantValues3,
            "variantValuePosition": 0
          },
        ]
      }
    ].map((item:any) => {
      const el = item.variantValues.filter((i:any) => i)
      if(el){
        return item
      }
      
    }).filter((item:any) => item.variantTypeName)
    return variants
  }
  
  getVariantValues(item:any, itemVariant:any ,variants:any){
    const allVariants = variants.find((el:any) => el.variantTypeName === itemVariant.variantTypeName)
    let uniqueVariants = item.variants.map((element:any) => {
      if(element.variantTypeName === allVariants.variantTypeName){
        let concatenatedValues = element.variantValues.concat(allVariants.variantValues)
        let mappedValues = concatenatedValues.map((object:any) => {
            return [JSON.stringify(object.variantValueName), object]
        });
        let mappedArray = new Map(mappedValues);
        
        let uniques = [...mappedArray.values()]; // Conversión a un array
        uniques = uniques.map((item:any, index:number) => {
          item.variantValuePosition = index
          return item
        })

        element.variantValues = uniques
      }
      return element;
    })

    return uniqueVariants;
  }

  getProductItems(product:any, items:any[]) {
    const item = {
      "itemCode": `${product.productCode}${items.length}`,
      "itemActive": product.state,
      "itemPrice": product.salePrice || 0,
      "itemDiscountPrice": product.discountPrice || 0,
      "itemShopPoints": [
        {
          "shopPointId": this.themingService.shopId,
          "itemQuantity":  product.quantity || 0
        }
      ],
      "itemVariantValues": this.getItemVariantValues(product)
    }
    const exisitsItem = items.some((el:any) => {
      const elValues =  Object.values(el.itemVariantValues.sort().map((i:any) => i.variantValueName)).join('')
      const itemValues =  Object.values(item.itemVariantValues.sort().map((i:any) => i.variantValueName)).join('')
      return elValues === itemValues
    })
    if(!exisitsItem){
      items.push(item)
    }
    return items
  }

  confirmUploadProduct() {
    const data = this.previewData.map((item:any) => item.productCode)
    const dataLength = new Set(data)
    this.totalProducts = [...dataLength].length
    // $('#productUploadModal').modal('hide');
    $('#processConfirmation').modal('show');
  }

  cancelProcess(){
    $('#processConfirmation').modal('hide');
  }

  async uploadProduct(){
    $('#processConfirmation').modal('hide');
    let products: any = []
    for (const product of this.previewData) {
      const existsProduct = products.find((item:any) => item.productCode === product.productCode)
      if(!existsProduct){
        products.push({
          "productCode": product.productCode,
          "shopId": this.themingService.shopId,
          "productCategoryId": product.categoryId,
          "productSubCategoryId": product.subCategoryId > 0 ? product.subCategoryId: 0,
          "productSubSubCategoryId": product.subSubCategoryId > 0 ? product.subSubCategoryId: 0,
          "productIndustryId": product.industryId,
          "productSupplierId": product.supplierId > 0 ? product.supplierId: 0,
          "productCollectionId": product.collectionId > 0 ? product.collectionId: 0,
          "productTaxId": product.taxId,
          "productName": product.name,
          "productDescription": product.description?this.convertToHtmlReadable(product.description):"",
          "productBaseCost": product.costPrice || 0,
          "productSellPrice": product.salePrice || 0,
          "productDiscountPrice": product.discountPrice || 0,
          "productCreationDate": this.getCurrentDateTime(),
          "productStatus": product.state,
          "items": this.getProductItems(product, []),
          "variants": this.getVariants(product),
          "imgURL": "X",
          "imgPosition": 1,
          "imgType": "X"
        })
      }else{
        products = products.map((item:any) => {
          if(item.productCode === product.productCode){
            const variants = this.getVariants(product)
            const itemVariants = item.variants
            for (const itemVariant of itemVariants) {
              item.variants = this.getVariantValues(item, itemVariant, variants)
            }
            item.items = this.getProductItems(product, item.items)
          }
          return item
        })
      }
    }

    let result:any[] = []
    this.uploadPercentaje = 0;
    this.isUploading = true;
    this.uploadingProducts = 0;
    this.totalProducts = products.length;

    for(let i=0; i<this.totalProducts; i+=10){
      let payload = {
        products: products.slice(i,i+10)
      }

      //
      this.uploadingProducts += payload.products.length;
      this.uploadPercentaje = Number(((this.uploadingProducts/this.totalProducts) * 100).toFixed(2))
      this.uploadingStyle = `width: ${this.uploadPercentaje}%`;

      let response = await this.apiService.uploadProducts(payload) as any[]
      result = result.concat(response)
    }

    this.itemCodesString = products.flatMap((product: any) => product.items.map((item: any) => item.itemCode))
    .join(', ');

    this.isUploading = false;

    this.uploadErrors = result

    $('#productUploadModal').modal('hide');
    $('#processResult').modal('show');
  }

  async exportItems(){
    let response = await this.apiService.exportItems(this.themingService.shopId, this.itemCodesString) as {status: 400, msg:"", data:[]};
  
    let exportItems = response.data;
    console.log("items to export: ",exportItems);

    const workbook = XLSX.utils.book_new();
    const sheetName = 'Items';
    const worksheet = XLSX.utils.json_to_sheet(exportItems);
    const fileName = 'Items.xlsx';
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, fileName);
  }

  convertToHtmlReadable(text: string): string {
    // Reemplazar caracteres especiales, espacios y saltos de linea a formatos legibles para HTML y permitidos en el payload
    const htmlReadable = text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/ /g, ' ')
      .replace(/\r/g, '<br>')
      .replace(/\n/g, ' ');
    
    return htmlReadable;
  }

  downloadErrors(){
    const productsWithError = this.uploadErrors.map((item:any) => item.product)
    let errors = this.previewData.filter((item:any) => productsWithError.includes(item.productCode))
    errors = errors.map((item:any) => {
      item.error = this.uploadErrors.find((el:any) => el.product === item.productCode).error.message
      return item
    })
    const workbook = XLSX.utils.book_new();
    const sheetName = 'Sheet1';
    const worksheet = XLSX.utils.json_to_sheet(errors);
    const fileName = 'Errores.xlsx';
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, fileName);
  }

  async downloadTemplate(){
    this.showDownloadModal();
    await this.getKeys()
    const workbook = XLSX.utils.book_new();

    this.productTemplate = this.productTemplate.map((item:any) => {
      const [category] = this.categories;
      const [industry] = this.industries;
      const [tax] = this.taxes;

      item.categoryId = category.category
      item.industryId = industry.industry
      item.taxId = tax.tax
      return item
    })

    const productsSheetName = 'Productos';
    const productsWorksheet = XLSX.utils.json_to_sheet(this.productTemplate);
    XLSX.utils.book_append_sheet(workbook, productsWorksheet, productsSheetName);

    const categoriesSheetName = 'Categorias';
    const categoriesWorksheet = XLSX.utils.json_to_sheet(this.categories);
    XLSX.utils.book_append_sheet(workbook, categoriesWorksheet, categoriesSheetName);

    const subCategoriesSheetName = 'Sub Categorias';
    const subCategoriesWorksheet = XLSX.utils.json_to_sheet(this.subCategories);
    XLSX.utils.book_append_sheet(workbook, subCategoriesWorksheet, subCategoriesSheetName);

    const subSubCategoriesSheetName = 'Sub Sub Categorias';
    const subSubCategoriesWorksheet = XLSX.utils.json_to_sheet(this.subSubCategories);
    XLSX.utils.book_append_sheet(workbook, subSubCategoriesWorksheet, subSubCategoriesSheetName);

    const taxesSheetName = 'Impuestos';
    const taxesWorksheet = XLSX.utils.json_to_sheet(this.taxes);
    XLSX.utils.book_append_sheet(workbook, taxesWorksheet, taxesSheetName);

    const industriesSheetName = 'Industrias';
    const industriesWorksheet = XLSX.utils.json_to_sheet(this.industries);
    XLSX.utils.book_append_sheet(workbook, industriesWorksheet, industriesSheetName);

    const collectionsSheetName = 'Coleciones';
    const collectionsWorksheet = XLSX.utils.json_to_sheet(this.collections);
    XLSX.utils.book_append_sheet(workbook, collectionsWorksheet, collectionsSheetName);

    const suppliersSheetName = 'Proveedores';
    const suppliersWorksheet = XLSX.utils.json_to_sheet(this.suppliers);
    XLSX.utils.book_append_sheet(workbook, suppliersWorksheet, suppliersSheetName);
    
    const statesSheetName = 'Estados';
    const statesWorksheet = XLSX.utils.json_to_sheet(this.states);
    XLSX.utils.book_append_sheet(workbook, statesWorksheet, statesSheetName);

    const fileName = 'Plantilla.xlsx';
    XLSX.writeFile(workbook, fileName);
  }

  getCategoryName(categoryId: number){
    const category = this.categories.find((item:any) => item.category === categoryId) ?? {category: categoryId, name: 'La categoría no existe'}
    return category.name
  }
  
  getSubCategoryName(subCategoryId: number, categoryId: number){
    //console.log("subCategoryId: ",subCategoryId)
    if(!subCategoryId){
      return ""
    }else{
      const subCategory = this.subCategories.find((item:any) => item.subCategory === subCategoryId) ?? {subCategory: subCategoryId, name: 'La sub categoría no existe'}
      if(!subCategory.name.includes("no existe") && subCategory.category_padre != categoryId){
        //console.log(subCategory.category_padre,categoryId)
        return 'La sub categoría no pertenece a la categoria';
      }
      return subCategory.name;
    }
  }

  getSubSubCategoryName(subSubCategoryId: number, subCategoryId: number){
    //console.log("subSubCategoryId: ",subSubCategoryId)
    if(!subSubCategoryId){
      return ""
    }else{
      const subSubCategory = this.subSubCategories.find((item:any) => item.subSubCategory === subSubCategoryId) ?? {subSubCategory: subSubCategoryId, name: 'La sub sub categoría no existe'}
      if(!subSubCategory.name.includes("no existe") && subSubCategory.sub_category_padre != subCategoryId){
        return 'La sub sub categoría no pertenece a la sub categoria';
      }
      return subSubCategory.name
    }
  }

  getIndustryName(industryId: number){
    const industry = this.industries.find((item:any) => item.industry === industryId) ?? {industry: industryId, name: 'La industria no existe'}
    return industry.name
  }

  getCollectionName(collectionId: number){
    if(!collectionId){
      return ""
    }
    const collection = this.collections.find((item:any) => item.collection === collectionId) ?? {collection: collectionId, name: 'La colección no existe'}
    return collection.name
  }

  getSupplierName(supplierId: number){
    if(!supplierId){
      return ""
    }
    const supplier = this.suppliers.find((item:any) => item.supplier === supplierId) ?? {supplier: supplierId, name: 'El proveedor no existe'}
    return supplier.name
  }

  getTaxName(taxId: number){
    //console.log("taxes: ", this.taxes);
    const tax = this.taxes.find((item:any) => item.tax === taxId && taxId !== 2) ?? {tax: taxId, name: 'El impuesto no existe'}
    return tax.name
  }

  getStateName(state: number){
    const stateName = ![0,1].includes(state) ? 'El estado no existe' : state === 0 ? 'Inactivo' : 'Activo'
    return stateName
  }

  getError(index:number){
    if(!index || isNaN(Number(index))){
      index = 0
    }
    const {taxId, categoryId, subCategoryId, subSubCategoryId, industryId, collectionId, supplierId, state} = this.previewData[index]

    let error = this.getTaxName(taxId)
    if(error.includes('no existe')) return error
    error = this.getCategoryName(categoryId)
    if(error.includes('no existe')) return error
    error = this.getIndustryName(industryId)
    if(error.includes('no existe')) return error
    error = this.getStateName(state)
    if(error.includes('no existe')) return error
    error = this.getSubCategoryName(subCategoryId, categoryId)
    if(error.includes('no existe') || error.includes('no pertenece')) return error
    error = this.getSubSubCategoryName(subSubCategoryId, subCategoryId)
    if(error.includes('no existe') || error.includes('no pertenece')) return error
    error = this.getCollectionName(collectionId)
    if(error.includes('no existe')) return error
    error = this.getSupplierName(supplierId)
    if(error.includes('no existe')) return error

    return ''
  }

  showErrorLines(){
    const data = this.previewData.filter((item:any, index:number) => this.getError(index));
    this.previewData = data;
    this.loadPreviewProducts();
    this.handleListOfPages()
  }

  /**
   * The function opens a product upload modal and resets the form.
   */
  async openProductUploadModal(){
    $('#productUploadModal').modal('show');
    this.ModalLoading = true
    this.previewData = [];
    this.previewDataPagination = [];
    this.previewPages = [];
    this.previewTotalPages = 0;
    const form = document.querySelector('#uploadItemsForm') as HTMLFormElement;
    form.reset();
    await this.getKeys();
    this.ModalLoading = false;
  }

  /**
   * This function reads an Excel file selected by the user, converts it to JSON format, and displays a
   * preview of the data.
   * @param {any} event - The event parameter is an object that represents the event that triggered the
   * file selection, such as a click or change event on a file input element.
   */
  onFileSelected(event: any) {
    this.previewError = false
    this.previewPageNumber = 1;
    const file: File = event.target.files[0];
    const fileReader: FileReader = new FileReader();

    fileReader.onload = (e: any) => {
      const arrayBuffer: ArrayBuffer = e.target.result;
      const data: Uint8Array = new Uint8Array(arrayBuffer);
      const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'array' });

      const worksheet: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];

      const [head]: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      let jsonData: any[] = XLSX.utils.sheet_to_json(worksheet);

      jsonData = jsonData.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => JSON.stringify(o) === JSON.stringify(obj))
      );

      //file format validation
      this.xlsxValid = (JSON.stringify(head) === JSON.stringify(this.xlsxHeaderTemplateFormat))
      if(!this.xlsxValid){
        return;
      }

      this.previewData = jsonData;

      for (const [index, item] of jsonData.entries()) {
        const error = this.getError(index)
        if(error){
          this.previewError = true
          break
        }
      }

      this.loadPreviewProducts();
      this.handleListOfPages()
    };

    fileReader.readAsArrayBuffer(file);
  }

  /**
   * The function "fillProductNameByCode" fills in the name and description of each item in the
   * previewData array based on its productCode.
   * @returns the updated previewData array with the name and description filled in for each item based
   * on the productCode.
   */
  fillProductNameByCode(){
    const data = this.previewData.map((item:any) => {
      const product = this.previewData.find((el:any) => el.productCode === item.productCode);
      const {productCode, name, description} = product;
      if(item.productCode === productCode){
        item.name = name
        item.description = description
      }
      return item;
    })

    this.previewData = data

    return data
  }

  /**
   * The function loads a preview of products based on the selected page size and page number.
   */
  loadPreviewProducts(){
    this.fillProductNameByCode();
    const selectedPageSize =  this.previewPageSize[this.previewPageSizeindex]
    const previewStart = selectedPageSize * (this.previewPageNumber - 1);
    const previewEnd = selectedPageSize * this.previewPageNumber;
    this.previewDataPagination = this.previewData.slice(previewStart, previewEnd);
    const NumberOfPages = (this.previewData.length / selectedPageSize);
    this.previewTotalPages = Math.ceil(NumberOfPages);
  }

  /**
   * This function generates a list of pages to display in a preview based on the current page number
   * and total number of pages.
   */
  handleListOfPages(){
    let pages = []
    let i = 1;
    let limit = i + 4;
    switch(this.previewPageNumber){
      case 1:
      case 2:
      case 3:
        i = 1
        break;
      case this.previewTotalPages:
      case (this.previewTotalPages - 1):
        i = this.previewTotalPages - 4
        limit = this.previewTotalPages
        break;
      default:
        i = this.previewPageNumber - 2;
        limit = i + 4;
        break;
    }

    if(limit > this.previewTotalPages) limit = this.previewTotalPages

    for(i; i <= limit; i++){
      pages.push(i);
    }
    this.previewPages = pages;
  }

  /**
   * This function moves the preview page forward or backward and loads the corresponding products.
   * @param {boolean} forward - A boolean value that determines whether the preview page should be
   * moved forward (true) or backward (false).
   */
  movePreviewPage(forward: boolean) {
    this.previewPageNumber = this.previewPageNumber + (forward ? 1 : -1);
    this.handleListOfPages();
    this.loadPreviewProducts();
  }

  /**
   * The function sets the preview page number, handles the list of pages, and loads preview products.
   * @param {number} page - The page parameter is a number that represents the page number to which the
   * user wants to navigate. This function updates the previewPageNumber property with the provided
   * page number and then calls two other functions to handle the list of pages and load the preview
   * products accordingly.
   */
  goToPreviewPage(page: number) {
    this.previewPageNumber = page;
    this.handleListOfPages();
    this.loadPreviewProducts();
  }


  async changeSelectedProduct(event: Event){
    const checkbox = event.target as HTMLInputElement;
    const id = checkbox.id;
    if (id === 'checkAllProducts') {
      if (checkbox.checked){
        const checkboxes = document.querySelectorAll('.checkProduct');
        this.selectedProductIds = [];
        checkboxes.forEach((checkbox: any) => {
          checkbox.checked = true;
          this.selectedProductIds.push(checkbox.getAttribute('data-productId'));
        });
      }else{
        const checkboxes = document.querySelectorAll('.checkProduct');
        this.selectedProductIds = [];
        checkboxes.forEach((checkbox: any) => {
          checkbox.checked = false;
        });
      }
    } else {
      const productId = checkbox.getAttribute('data-productId');
      if (checkbox.checked){
        this.selectedProductIds.push(productId);
      }else{
        this.selectedProductIds = this.selectedProductIds.filter((prodId) => prodId !== productId);
        const checkbox = document.getElementById('checkAllProducts') as HTMLInputElement;
        checkbox.checked = false;
      }
    }
    //console.log("productsID selected",this.selectedProductIds);
  }

  updateProductsStatus(status: number){
    let productsIdList = this.selectedProductIds.toString();
    this.apiService
      .updateProductsStatus(this.themingService.shopId,productsIdList, status)
      .then((data: any) => {
        $('#successBulkEdit').modal('show');
      })
      .catch((error: any) => {
        console.error(error);
        $('#errorBulkEdit').modal('show');
      });
  }
  

  showDownloadModal(){
    $('#downloadModal').modal('show');
    setTimeout(() => {
      $('#downloadModal').modal('hide');
    }, 4000);
  }

  closeModal(){
    $('#downloadModal').modal('hide');
    $('#successBulkEdit').modal('hide');
    $('#errorBulkEdit').modal('hide');
  }

}
