<nav class="navs">
  <ul class="nav nav-underline nav-client-type" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="resource-tab" data-bs-toggle="tab" data-bs-target="#resource" type="button" role="tab"
        aria-controls="resource" aria-selected="true">{{'RESOURCES' | translate}}</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab"
        aria-controls="projects" aria-selected="false">{{'PROJECTS' | translate}}</button>
    </li>
  </ul>
</nav>

<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="resource" role="tabpanel" aria-labelledby="resource-tab">
    <div *ngIf="getListingResources()">
      <app-timekit-resources></app-timekit-resources>
    </div>
    <div *ngIf="getCreatingResources()">
      <app-timekit-create-resource></app-timekit-create-resource>
    </div>
  </div>

  <div class="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
    <div *ngIf="getListingProjects()">
      <app-timekit-projects></app-timekit-projects>
    </div>
    <div *ngIf="getCreateProjects()">
      <app-timekit-create-project></app-timekit-create-project>
    </div>
  </div>
</div>
