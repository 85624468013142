import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth.service';
import { ThemingService } from './theming.service';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { SendCodeComponent } from './components/send-code/send-code.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { HomeComponent } from './pages/home/home.component';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { CreateProductComponent } from './components/create-product/create-product.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { UsersComponent } from './components/users/users.component';
import { AddStaffComponent } from './components/add-staff/add-staff.component';
import { BackArrowComponent } from './components/back-arrow/back-arrow.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { CreateCategoryComponent } from './components/create-category/create-category.component';
import { CreateCouponComponent } from './components/create-coupon/create-coupon.component';
import { OrderComponent } from './pages/order/order.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { CouponsComponent } from './pages/coupons/coupons.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductComponent } from './pages/product/product.component';
import { QuillModule } from 'ngx-quill';
import { SettingsComponent } from './pages/settings/settings.component';
import { OptionBoxComponent } from './components/option-box/option-box.component';
import { GeneralInfoComponent } from './components/general-info/general-info.component';
import { ThemingSettingsComponent } from './components/theming-settings/theming-settings.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import { SettingsModalComponent } from './components/settings-modal/settings-modal.component';
import { BackArrowSettingsComponent } from './components/back-arrow-settings/back-arrow-settings.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoryComponent } from './components/category/category.component';
import { RegisterComponent } from './pages/register/register.component';
import { TaxSettingsComponent } from './components/tax-settings/tax-settings.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientComponent } from './pages/client/client.component';
import { CreateCollectionComponent } from './components/create-collection/create-collection.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { EditCollectionComponent } from './components/edit-collection/edit-collection.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { CreateSupplierComponent } from './components/create-supplier/create-supplier.component';
import { EditSupplierComponent } from './components/edit-supplier/edit-supplier.component';
import { DailySalesComponent } from './components/daily-sales/daily-sales.component';
import { GiftCardsComponent } from './pages/gift-cards/gift-cards.component';
import { CreateGiftCardComponent } from './components/create-gift-card/create-gift-card.component';
import { EditGiftCardComponent } from './components/edit-gift-card/edit-gift-card.component';
import { GiftCardThumbnailComponent } from './components/gift-card-thumbnail/gift-card-thumbnail.component';
import { GiftCardsItemsComponent } from './pages/gift-cards-items/gift-cards-items.component';
import { CreateGiftCardItemsComponent } from './components/create-gift-card-items/create-gift-card-items.component';
import { GiftCardsItemComponent } from './pages/gift-cards-item/gift-cards-item.component';
import { SubCategoriesComponent } from './pages/sub-categories/sub-categories.component';
import { SubSubcategoriesComponent } from './pages/sub-subcategories/sub-subcategories.component';
import { EditSubcategoryComponent } from './components/edit-subcategory/edit-subcategory.component';
import { CreateSubcategoryComponent } from './components/create-subcategory/create-subcategory.component';
import { CreateSubSubcategoryComponent } from './components/create-sub-subcategory/create-sub-subcategory.component';
import { EditSubSubcategoryComponent } from './components/edit-sub-subcategory/edit-sub-subcategory.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CurrentPlanComponent } from './pages/current-plan/current-plan.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { CashRegisterComponent } from './pages/cash-register/cash-register.component';
import { CashRegistersComponent } from './pages/cash-registers/cash-registers.component';
import { CreateComponentComponent } from './components/create-component/create-component.component';
import { ComponentsComponent } from './components/components/components.component';
import { MatSelectModule } from '@angular/material/select';
import { EditComponentComponent } from './components/edit-component/edit-component.component';
import { ShopPointsComponent } from './components/shop-points/shop-points.component';
import { CreateShopPointComponent } from './components/create-shop-point/create-shop-point.component';
import { EditShopPointComponent } from './components/edit-shop-point/edit-shop-point.component';
import { MenuComponent } from './components/menu/menu.component';
import { CreateMenuComponent } from './components/create-menu/create-menu.component';
import { EditMenuComponent } from './components/edit-menu/edit-menu.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CreateClientComponent } from './components/create-client/create-client.component';
import { EditClientComponent } from './components/edit-client/edit-client.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PaymentSettingsComponent } from './components/payment-settings/payment-settings.component';
import { CreatePaymentComponent } from './components/create-payment/create-payment.component';
import { EditPaymentComponent } from './components/edit-payment/edit-payment.component';
import { TimekitRescheduleComponent } from './components/timekit-reschedule/timekit-reschedule.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TimekitBookingsComponent } from './components/timekit-bookings/timekit-bookings.component';
import { TimekitTabsComponent } from './components/timekit-tabs/timekit-tabs.component';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateComponent } from './components/translate/translate.component';
import { TimekitScheduleComponent } from './components/timekit-schedule/timekit-schedule.component';
import { TimekitOptionsComponent } from './components/timekit-options/timekit-options.component';
import { TimekitCreateProjectComponent } from './components/timekit-create-project/timekit-create-project.component';
import { TimekitCreateResourceComponent } from './components/timekit-create-resource/timekit-create-resource.component';
import { TimekitResourcesComponent } from './components/timekit-resources/timekit-resources.component';
import { TimekitProjectsComponent } from './components/timekit-projects/timekit-projects.component';
import { ItemBeneficiariesComponent } from './components/item-beneficiaries/item-beneficiaries.component';
import { OnlinePolicyComponent } from './components/online-policy/online-policy.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    BrowserAnimationsModule,
    QuillModule.forRoot(),
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    BrowserModule,
    DragDropModule,
    MatSelectModule,
    FullCalendarModule,
    HttpClientModule,
    TranslateModule.forRoot({
        defaultLanguage: 'es',
        useDefaultLang: true,
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule
  ],
  declarations: [
    AppComponent,
    SignInComponent,
    PasswordRecoveryComponent,
    CreateProductComponent,
    CreateCategoryComponent,
    CreateCouponComponent,
    ChangePasswordComponent,
    SendCodeComponent,
    GoBackComponent,
    HomeComponent,
    UsersComponent,
    AddStaffComponent,
    BackArrowComponent,
    SideBarComponent,
    UserDetailsComponent,
    UserProfileComponent,
    ConfirmationModalComponent,
    OrdersComponent,
    OrderComponent,
    CouponsComponent,
    ProductsComponent,
    ProductComponent,
    SettingsComponent,
    OptionBoxComponent,
    GeneralInfoComponent,
    ThemingSettingsComponent,
    EditProductComponent,
    SettingsModalComponent,
    BackArrowSettingsComponent,
    CategoriesComponent,
    CategoryComponent,
    RegisterComponent,
    TaxSettingsComponent,
    ClientsComponent,
    ClientComponent,
    CreateCollectionComponent,
    CollectionsComponent,
    EditCollectionComponent,
    SupplierComponent,
    CreateSupplierComponent,
    EditSupplierComponent,
    DailySalesComponent,
    GiftCardsComponent,
    CreateGiftCardComponent,
    EditGiftCardComponent,
    GiftCardThumbnailComponent,
    GiftCardsItemsComponent,
    CreateGiftCardItemsComponent,
    GiftCardsItemComponent,
    SubCategoriesComponent,
    SubSubcategoriesComponent,
    EditSubcategoryComponent,
    CreateSubcategoryComponent,
    CreateSubSubcategoryComponent,
    EditSubSubcategoryComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    CurrentPlanComponent,
    CashRegisterComponent,
    CashRegistersComponent,
    CreateComponentComponent,
    ComponentsComponent,
    EditComponentComponent,
    ShopPointsComponent,
    CreateShopPointComponent,
    EditShopPointComponent,
    MenuComponent,
    CreateMenuComponent,
    EditMenuComponent,
    CreateClientComponent,
    EditClientComponent,
    PaymentSettingsComponent,
    CreatePaymentComponent,
    EditPaymentComponent,
    TimekitRescheduleComponent,
    TimekitBookingsComponent,
    TimekitTabsComponent,
    TranslateComponent,
    TimekitScheduleComponent,
    TimekitOptionsComponent,
    TimekitCreateProjectComponent,
    TimekitCreateResourceComponent,
    TimekitResourcesComponent,
    TimekitProjectsComponent,
    ItemBeneficiariesComponent,
    OnlinePolicyComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      // Provide the APP_INITIALIZER, wait until the theming configuration is fetched and set up correctly
      useFactory: (themingService: ThemingService) => () =>
        themingService.initialize(),
      deps: [ThemingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => authService.initialize(),
      deps: [AuthService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
