<div class="container">
  <div *ngIf="collection" class="container-collection">
    <div class="header">
      <h1 class="title">{{'EDIT_COLLECTION' | translate}}</h1>
      <app-back-arrow-settings [callback]="setListingCollections"></app-back-arrow-settings>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div *ngIf="!loading" class="form-group-container">
        <label class="form-label" for="collection_name">{{'NAME' | translate}}</label>
        <input type="text" class="form-control" id="collectionName"
          placeholder="{{'COLLECTION_NAME' | translate}}"
          (keyup)="changeCollectionName($event)" value="{{collection.collectionName}}" #collectionName/>
        <label class="form-label" for="collection_description">{{'DESCRIPTION' | translate}}</label>
        <textarea class="form-control" id="collectionDescription" placeholder="{{'COLLECTION_DESCRIPTION' | translate}}"
          (keyup)="ChangeCollectionDescription($event)" #collectionDescription>{{collection.collectionDescription}}</textarea>
          <div class="logged-user-switch">
            <label class="form-label">{{'ONLY_VERIFIED' | translate}}</label>
            <mat-slide-toggle [(ngModel)]="collection.isForVerifiedClient" class="switch" (change)="onSwitchChange($event)"></mat-slide-toggle>
          </div>
        <div class="form-buttons">
          <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
            {{'SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal collection-confirm-modal fade" id="successCollectionModal" tabindex="-1">
  <div class="modal-dialog collection-modal-dialog" role="document">
    <div class="modal-content collection-modal-content">
      <div class="modal-body collection-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}!</h3>
        <p class="text-center">{{'COLLECTION_SAVED_SUCCESS' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal collection-confirm-modal fade" id="errorCollectionModal" tabindex="-1">
  <div class="modal-dialog collection-modal-dialog" role="document">
    <div class="modal-content collection-modal-content">
      <div class="modal-body collection-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}!</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
