<main class="main-container container">
  <div class="select-project">
    <div class="col-md-6">
      <label for="projects" class="form-label">{{'SELECT_PROJECT' | translate}}</label>
      <select class="form-select" (change)="onProjectChange($event)">
        <option selected disabled hidden>
          {{'SELECT-OPTION' | translate}}
        </option>
        <option *ngFor="let project of projects" [value]="project.projectId">
          {{ project.projectName }}
        </option>
      </select>
    </div>
  </div>
  <div id="bookingjs"></div>
</main>
