<main class="container main-container">
  <p>{{ data }}</p>
  <div class="select-project">
    <div class="col-md-6">
      <label for="projects" class="form-label">{{'SELECT_PROJECT' | translate}}</label>
      <select class="form-select" (change)="onProjectChange($event)">
        <option selected disabled hidden>
          {{'SELECT-OPTION' | translate}}
        </option>
        <option *ngFor="let project of projects" [value]="project.projectId">
          {{ project.projectName }}
        </option>
      </select>
    </div>
  </div>

    <header class="header">
      <h1 class="title">Agenda</h1>
      <button (click)="onSubmit()" class="custom-button btn-outline-primary primary-btn">
        {{'UPDATE' | translate}}
      </button>
    </header>
    <section class="content-container">
      <div class="title-container">
        <h2 class="title-table">{{'SCHEDULE' | translate}}</h2>
      </div>
      <div class="form-section">
        <form class="mb-2">
          <div *ngFor="let day of days" class="time-row">
            <label class="day-label">{{ getTranslatedDay(day.name) }}:</label>
            <span>desde</span>
            <input type="time" [(ngModel)]="day.startTime" name="{{day.name}}-start" class="time-input">
            <span>hasta</span>
            <input type="time" [(ngModel)]="day.endTime" name="{{day.name}}-end" class="time-input">
          </div>
        </form>
      </div>
    </section>
  </main>
