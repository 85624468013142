<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="cashRegister-container">
  <div class="header">
    <h1 class="title"> {{'CASH_REGISTERS' | translate}} {{ cashRegister?.cashRegisterId }}</h1>
    <app-back-arrow [route]="'/cashRegisters'"></app-back-arrow>
    </div>
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>
    <div *ngIf="!loading" class="info-container">
      <div class="form-card info">
        <h3 class="subtitle"> {{'AMOUNTS' | translate}}</h3>
        <table class="cash-register-info-container">
          <tr *ngFor="let paymentType of paymentTypeTotal">
            <td>{{ paymentType.paymentTypeId === 1 ? 'Tarjeta de Crédito' :
                  paymentType.paymentTypeId === 2 ? 'SINPE' :
                  paymentType.paymentTypeId === 3 ? 'Transferencia Bancaria' :
                  paymentType.paymentTypeId === 4 ? 'Efectivo' : '' }}</td>
            <td>{{ appComponent.formatMoney(paymentType.paymentTypeTotal) }}</td>
          </tr>
          <tr class="subtle-divider">
            <td class="form-label">Subtotal</td>
            <td>{{ appComponent.formatMoney(subtotal) }}</td>
          </tr>
          <tr class="subtle-divider">
            <td>{{'INITIAL_AMOUNT' | translate}}</td>
            <td> {{appComponent.formatMoney(cashRegister.initialAmount)}}</td>
          </tr>
          <tr>
            <td>{{'DEPOSITS' | translate}}</td>
            <td> {{appComponent.formatMoney(cashRegister.cashDeposit)}}</td>
          </tr>
          <tr>
            <td>{{'WITHDRAWALS' | translate}}</td>
            <td>{{appComponent.formatMoney(cashRegister.cashWithdrawn)}}</td>
          </tr>
          <tr class="subtle-divider">
            <td class="form-label">Total</td>
            <td class="form-label">{{ appComponent.formatMoney(totalPaymentAmount) }}</td>
          </tr>
        </table>
      </div>

      <div class="form-card info">
        <h3 class="subtitle"> {{'CASH_REGISTERS_INFO' | translate}}</h3>
        <table class="cash-register-info-container">
          <tr>
            <td> {{'SALESPERSON' | translate}}</td>
            <td>{{ vendorInfo.firstName }} {{ vendorInfo.lastName }}</td>
          </tr>
          <tr>
            <td> {{'STATUS' | translate}}</td>
            <td>{{ cashRegister.status == 0 ? 'Cerrada' : 'Abierta' }}</td>
          </tr>
          <tr>
            <td> {{'OPENING_DATE' | translate}}</td>
            <td>{{ appComponent.formatDate(cashRegister?.openDate) }}</td>
          </tr>
          <tr>
            <td> {{'CLOSING_DATE' | translate}}</td>
            <td>{{ cashRegister.status == 0 ? (appComponent.formatDate(cashRegister.closeDate)) : 'Pendiente' }}</td>
          </tr>
          <tr>
            <td class="form-label">{{'CLOSING_AMOUNT' | translate}}</td>
            <td class="form-label">{{ cashRegister.status == 0 ? (appComponent.formatMoney(cashRegister.finalAmount)) : 'Pendiente' }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div *ngIf="!loading" class="form-card">
      <h3 class="subtitle">{{'PAYMENT_LIST' | translate}}</h3>
      <table class="items-container table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">Id</th>
          <th scope="col"> {{'TYPE' | translate}}</th>
          <th scope="col">{{'DATE' | translate}}</th>
          <th scope="col" class="table-end"> {{'AMOUNT' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let payment of salePayments">
            <td>{{ payment.salePaymentId }}</td>
            <td>{{ payment.paymentTypeId === 1 ? 'Tarjeta de Crédito' :
                  payment.paymentTypeId === 2 ? 'SINPE' :
                  payment.paymentTypeId === 3 ? 'Transferencia Bancaria' :
                  payment.paymentTypeId === 4 ? 'Efectivo' : '' }}</td>
            <td>{{ appComponent.formatDate(payment.salePaymentDate) }}</td>
            <td>{{ appComponent.formatMoney(payment.salePaymentAmount) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
