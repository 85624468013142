import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ShopTheming, ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';

declare var $: any;

@Component({
  selector: 'app-timekit-create-project',
  templateUrl: './timekit-create-project.component.html',
  styleUrls: ['./timekit-create-project.component.scss']
})
export class TimekitCreateProjectComponent implements OnInit {

  data: any;
  projectForm: FormGroup;
  resources: any[] = [];
  loading: boolean = false;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  daysOfWeekInSpanish: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
  hours = Array.from({ length: 24 }, (_, i) => (i + 1).toString());
  availability_constraints: any[] = [];
  public shopTheme: ShopTheming[] = [];
  resourceName!: string;
  showNoResourcesMessage: boolean = false;
  timekitResourceId: number = 0;
  months = Array.from({ length: 12 }, (_, i) => i + 1);
  minutes = [0, 10, 15, 30];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService
  ) {
    this.projectForm = this.fb.group({
      name: ['', Validators.required],
      slug: ['', Validators.required],
      allow_conference: ['1', Validators.required],
      resource: [, Validators.required],
      availability: this.fb.group({
        mode: ['roundrobin_random', Validators.required],
        length: ['hours', Validators.required],
        from: ['hours', Validators.required],
        to: [' month', Validators.required],
        buffer: [' minutes', Validators.required],
        ignore_all_day_events: [false, Validators.required]
      }),
      availability_constraints: this.fb.array([]),
      booking: this.fb.group({
        graph: ['instant', Validators.required],
        what: ['', Validators.required],
        where: ['', Validators.required],
        description: ['', Validators.required]
      })
    });
    this.addAvailabilityConstraint();
  }

  ngOnInit(): void {
    this.getTimekitResources()
  }


  async getTimekitResources() {
    this.loading = true;
    this.showNoResourcesMessage = false;

    try {
      const response = await this.apiService.getTimekitResources(this.themingService.shopId) as { data: Array<{ timekitResourceId: number, resourceId: string, resourceName: string | null }> };


      this.resources = response.data || [];

      if (this.resources.length === 0) {
        this.showNoResourcesMessage = true;
      } else {
        const resourceIds = this.resources.map(resource => resource.resourceId);
        const resourceNames = this.resources.map(resource => resource.resourceName);
        const timekitResourceIds = this.resources.map(resource => resource.timekitResourceId);

        this.projectForm.get('resources')?.setValue(resourceIds);
        this.projectForm.get('resourceNames')?.setValue(resourceNames);
        this.projectForm.get('timekitResources')?.setValue(timekitResourceIds);
      }
    } catch (error) {
      this.resources = [];
    } finally {
      this.loading = false;
    }
  }

  changeResource(event: Event) {
    const element = event.target as HTMLSelectElement;
    const selectedResourceId = element.value;

    this.projectForm.get('resource')?.setValue(selectedResourceId);

    const selectedResource = this.resources.find(resource => resource.resourceId === selectedResourceId);

    if (selectedResource) {
      this.timekitResourceId = selectedResource.timekitResourceId;
      console.log('Selected resource:', selectedResource);
    } else {
      console.log('No se encontró un recurso con el resourceId seleccionado.');
    }
  }


  createProject(): void {

    if (this.projectForm.valid) {
        this.loading = true;

        const {
            name,
            slug,
            allow_conference,
            resources,
            availability,
            availability_constraints,
            booking
        } = this.projectForm.value;

        const shopId = this.themingService.shopId || 0;

        const payload = {
            stage: 'dev',
            action: 'projects',
            shopId: shopId,
            body: {
                name,
                slug,
                allow_conference,
                resources: [this.projectForm.get('resource')?.value],
                availability: {
                    mode: availability.mode || "roundrobin_random",
                    from: availability.from || "2 hours",
                    to: availability.to || "4 weeks",
                    length: availability.length || "2 hours",
                    buffer: availability.buffer || "10 minutes",
                    ignore_all_day_events: availability.ignore_all_day_events || true
                },
                availability_constraints: availability_constraints || [],
                booking: {
                    graph: booking.graph || "instant",
                    what: booking.what || "Bookings",
                    where: booking.where || "TBA",
                    description: booking.description || "Default description"
                }
            }
        };

        const resourceId = resources && resources.length > 0 ? resources[0] : 'Resource ID no encontrado';

        this.apiService.createProject(payload)
            .then((data: any) => {
                this.loading = false;
                let parsedBody;
                try {
                    parsedBody = JSON.parse(data.body);
                } catch (e) {
                    console.error("Error al parsear 'body' de la respuesta:", e);
                    return;
                }

                if (data.statusCode === 200 && parsedBody) {
                    const projectName = parsedBody.project?.data?.name || 'Nombre no encontrado';
                    const projectId = parsedBody.project?.data?.id || 'ID no encontrado';

                    this.createTimekitProjects(projectName, projectId, resourceId);

                    $('#successProjectModal').modal('show');
                    $('#successProjectModal').on('hidden.bs.modal', () => {
                        this.projectForm.reset();
                    });
                } else {
                    console.error("Respuesta inesperada:", parsedBody);
                }
            })
            .catch((error) => {
                this.loading = false;
                console.error("API error:", error);
                let errorMessage = 'An error occurred while creating the project';
                if (error.response) {
                    try {
                        const errorData = JSON.parse(error.response);
                        errorMessage = errorData.message || errorMessage;
                    } catch (e) {
                        console.error('Error parsing error response:', e);
                    }
                }
                alert(errorMessage);
            });
    } else {
        this.showRequiredFieldsModal();
    }
  }

  showErrorModal(): void {
    $('#errorResourceModal').modal('show');
    $('#errorResourceModal').on('hidden.bs.modal', () => {
    });
  }

  showRequiredFieldsModal(): void {
    $('#requiredProjectModal').modal('show');
  }

  closeModal() {
    $('#successProjectModal').modal('hide');
    $('#requiredProjectModal').modal('hide');
    $('#errorProjectModal').modal('hide');
  }


  createTimekitProjects(projectName: string, projectId: string, timekitResourceId: number): void {

    if (!timekitResourceId) {
      console.error('No timekitResourceId found.');
      return;
    }

    this.apiService
      .createTimekitProjects(
        this.themingService.shopId,
        this.timekitResourceId,
        projectName,
        projectId
      )
      .then((data: any) => {

        if (data.status === 200 && data.msg === 'success') {
          console.log("Proyecto guardado exitosamente en la base de datos.");
        } else {
          console.error('Error details:', data.msg || 'No additional error details available');
        }
      })
      .catch((error) => {
        this.loading = false;
        console.log('Error during save to database:', error);
      });
    }


  get availabilityConstraints() {
    return this.projectForm.get('availability_constraints') as FormArray;
  }

  public addAvailabilityConstraint(): void {
    const constraintsArray = this.projectForm.get('availability_constraints') as FormArray;
    constraintsArray.push(
      this.fb.group({
        allow_day_and_time: this.fb.group({
          day: ['', Validators.required],
          start: ['07:00', Validators.required],
          end: ['18:00', Validators.required]
        })
      })
    );
  }

  removeAvailabilityConstraint(index: number): void {
    (this.projectForm.get('availability_constraints') as FormArray).removeAt(index);
  }

  addBlockPeriod() {
    const blockPeriod = this.fb.group({
      block_period: this.fb.group({
        start: [''],
        end: ['']
      })
    });
    this.availability_constraints.push(blockPeriod);
  }

  setListingProjects(){
    this.settingsService.setListingProjects();
  }

  getDayInEnglish(index: number): string {
    return this.daysOfWeek[index].toLowerCase();
  }
}
