<div class="container">
	<div class="container-categories">
		<div class="header">
			<h1 class="title">{{ 'SUBCATEGORY' | translate }}</h1>
			<button *ngIf="!showCreateSubCategoryModal" class="btn btn-outline-primary primary-btn" (click)="openCreateSubCategoryModal()">{{ 'NEW' | translate }}</button>
			<button *ngIf="showCreateSubCategoryModal" class="btn btn-primary remove-btn" (click)="closeCreateSubCategoryModal()">{{ 'CANCEL' | translate }}</button>
		</div>
		<app-create-subcategory *ngIf="showCreateSubCategoryModal" (subCategoryCreated)="handleSubCategoryCreated()" [categoryId]="categoryId"></app-create-subcategory>
		<div class="form-card">
		<div class="loading" *ngIf="loading">
			<div class="spinner-border" role="status"></div>
		</div>
			<table *ngIf="!loading" class="table table-bordered table-hover">
				<thead>
				<th scope="col" class="table-start">Id</th>
				<th scope="col">{{ 'SUBCATEGORY' | translate }}</th>
				<th scope="col">{{ 'IMAGE' | translate }}</th>
				<th scope="col">{{ 'CATEGORY' | translate }}</th>
				<th scope="col">{{ 'EDIT' | translate }}</th>
				<th scope="col" class="table-end">{{ 'DELETE' | translate }}</th>
				</thead>
				<tbody>
				<tr *ngFor="let subCategory of subCategories">
					<td>{{ subCategory.subCategoryId }}</td>
					<td>{{ subCategory.subCategoryName }}</td>
					<td>
						<img class="image-box" *ngIf="subCategory.subCategoryImageUrl" [src]="subCategory.subCategoryImageUrl" alt="subCategoryImageUrl">
						<span *ngIf="!subCategory.subCategoryImageUrl">{{ 'NO_IMAGE' | translate }}</span>
					</td>
					<td>{{ subCategory.categoryName }}</td>
					<td>
					<button class="btn btn-primary primary-btn" (click)="openEditsubCategoryModal(subCategory.subCategoryId)">
						{{ 'EDIT' | translate }}
					</button>
					</td>
					<td>
					<button class="btn btn-primary remove-btn" (click)="modalDelete = true; subCategoryIdToDelete = subCategory.subCategoryId">
						{{ 'DELETE' | translate }}
					</button>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<app-confirmation-modal
	  *ngIf="modalDelete"
    [title]="'CONFIRM_DELETE_SUBCATEGORY' | translate"
    [message]="'DELETE_SUBCATEGORY_WARNING' | translate"
    [confirmText]="'DELETE' | translate"
    [cancelText]="'CANCEL' | translate"
	  (response)="deleteSubCategory($event)">
	</app-confirmation-modal>

	<section class="modal-section" *ngIf="showEditSubCategoryModal">
		<div class="modal-content">
		  <div class="modal-body">
			<app-edit-subcategory *ngIf="showEditSubCategoryModal" [categoryId]="selectedCategoryId" (subCategoryUpdated)="handleSubCategoryUpdated()" [id]="selectedSubCategoryId"></app-edit-subcategory>
			<div class="modal-btn">
			  <button class="btn btn-primary remove-btn" (click)="closeEditSubCategoryModal()">{{ 'CANCEL' | translate }}</button>
			</div>
		  </div>
		</div>
	  </section>

	<div class="modal delete-category-confirm-modal" id="deleteSubCategoryModal" tabindex="-1">
	  <div class="modal-dialog category-modal-dialog" role="document">
		<div class="modal-content category-modal-content">
		  <div class="modal-body category-modal-body">
			<h3 class="text-center">{{ 'SUCCESS' | translate }}!</h3>
			<p class="text-center">{{ 'SUBCATEGORY_DELETED_SUCCESS' | translate }}</p>
		  </div>
		  <div class="modal-buttons">
			<button type="button" class="btn btn-confirm" (click)="closeModal()">{{ 'CLOSE' | translate }}</button>
		  </div>
		</div>
	  </div>
	</div>
</div>
