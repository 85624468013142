<div class="container">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
  <div class="container-component" *ngIf="!loading">
    <div class="header">
      <h3 class="title">{{ 'CREATE_COMPONENT' | translate }}</h3>
    </div>

    <div>
      <label class="form-label" for="component_name">{{'COMPONENT_NAME' | translate}}</label>
      <input type="text" class="form-control" id="componentName" [(ngModel)]="componentName" placeholder="{{'COMPONENT_NAME' | translate}}" maxlength="40" required/>
    </div>

    <label class="form-label" for="componentParentId">{{'COMPONENT_TYPE' | translate}}</label>
    <select class="form-select" id="componentParentId" (change)="onChangeParentId($event)">
      <option selected disabled>{{ 'SELECT_COMPONENT_TYPE' | translate }}</option>
      <option *ngFor="let parent of uniqueParentIds" [value]="parent.id">
        {{ parent.name }}
      </option>
    </select>

    <div *ngIf="selectedParentId== 3"  class="input-container">
      <div class="form-info">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="collections" (change)="onOptionChange('collections')">
          <label class="form-check-label" for="inlineRadio2">{{'COLLECTIONS' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="categories" (change)="onOptionChange('categories')">
          <label class="form-check-label" for="inlineRadio1">{{'CATEGORIES' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="subCategories" (change)="onOptionChange('subCategories')">
          <label class="form-check-label" for="inlineRadio2">{{'SUBCATEGORY' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="subSubcategories" (change)="onOptionChange('subSubcategories')">
          <label class="form-check-label" for="inlineRadio2">{{'SUB_SUBCATEGORIES' | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="custom" disabled>
          <label class="form-check-label" for="inlineRadio3">Personalizado</label>
        </div>
      </div>

      <div class="form-info">
        <div *ngIf="selectedOption === 'collections'" class="form-floating" >
          <select class="form-select" id="collections" aria-label="Floating label select example" (change)="onCollectionChange($event)">
            <option selected disabled>{{'SELECT_COLLECTION' | translate}}</option>
            <option *ngFor="let collection of collections" [value]="collection.collectionId">
              {{ collection.collectionName }}
            </option>
          </select>
          <label for="collections">{{'COLLECTIONS' | translate}}</label>
        </div>

        <div *ngIf="selectedOption === 'categories'" class="form-floating">
          <select class="form-select" id="category" aria-label="Floating label select example" (change)="onCategoryChange($event)">
            <option selected disabled>{{'SELECT_CATEGORY' | translate}}</option>
            <option *ngFor="let category of categories" [value]="category.categoryId">
              {{ category.categoryName }}
            </option>
          </select>
          <label for="category">{{'CATEGORIES' | translate}}</label>
        </div>

        <div *ngIf="selectedOption === 'subCategories'" class="form-floating">
          <select class="form-select" id="subCategory" aria-label="Floating label select example" (change)="onSubCategoryChange($event)">
            <option selected disabled>{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
            <option *ngFor="let subCategory of subCategories" [value]="subCategory.subCategoryId">
              {{ subCategory.subCategoryName }}
            </option>
          </select>
          <label for="subCategory">{{'SUBCATEGORY' | translate}}</label>
        </div>

        <div *ngIf="selectedOption === 'subSubcategories'" class="form-floating">
          <select class="form-select" id="subSubCategory" aria-label="Floating label select example" (change)="onSubSubCategoryChange($event)">
            <option selected disabled>{{'SELECT_SUB_SUBCATEGORY' | translate}}</option>
            <option *ngFor="let subSubCategory of subSubCategories" [value]="subSubCategory.subSubCategoryId">
              {{ subSubCategory.subSubCategoryName }}
            </option>
          </select>
          <label for="subSubCategory">{{'SUB_SUBCATEGORIES' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="form-duo" *ngIf="selectedParentId">
      <label class="form-label" for="componentTypeDesignId">{{ 'LAYOUT' | translate }}</label>
      <select class="form-select" id="componentTypeDesignId" [disabled]="!designEnabled" (change)="changeComponentTypeId($event)">
        <option selected disabled>{{ 'SELECT_LAYOUT' | translate }}</option>
        <ng-container *ngIf="groupedDesigns[selectedParentId]">
          <option *ngFor="let design of groupedDesigns[selectedParentId]" [value]="design.typeId">
            {{ design.name }}
          </option>
        </ng-container>
      </select>

      <div *ngIf="selectedDesignDescription">
        <label class="subtitle-label" for="component_name">{{'DESCRIPTION' | translate}}</label>
        <p>{{ selectedDesignDescription }}</p>
      </div>

      <div *ngIf="selectedDesignImageUrl" class="form-duo">
        <label class="subtitle-label" for="component_img">{{ 'PREVIEW' | translate }}</label>
        <img [src]="sanitizeImageURL(selectedDesignImageUrl)" alt="Design Image" class="design-image"/>
      </div>

      <div class="component-image" *ngIf="componentForm.get('component.componentImage')?.value">
        <img [src]="sanitizeImageURL(componentForm.get('component.componentImage')?.value)" alt="Component Image">
      </div>
    </div>

    <div class="col-md-6" *ngIf="getFieldVisibilityConfig().includes('itemQuantity')">
      <label class="form-label" for="componentAmount">{{ 'ITEMS_QUANTITY' | translate }}</label>
      <select class="form-select" id="componentAmount" (change)="onItemQuantityChange($event)">
        <option selected disabled>{{ 'SELECT_ITEMS_QUANTITY' | translate }}</option>
        <ng-container *ngIf="groupedDesigns[selectedParentId]">
        <option>4</option>
        <option>8</option>
        <option>12</option>
        <option>16</option>
        </ng-container>
      </select>
    </div>

    <form [formGroup]="componentForm" (ngSubmit)="validateFormData()">
    <div formGroupName="component">

      <div  *ngIf="getFieldVisibilityConfig().includes('title')">
        <label class="form-label" for="component_title">{{ 'TITLE' | translate }}</label>
        <input type="text" class="form-control" id="componentTitle" formControlName="title" placeholder="{{ 'TITLE' | translate }}" maxlength="50" />
      </div>

      <div *ngIf="getFieldVisibilityConfig().includes('subtitle')">
        <label class="form-label" for="component_subtitle">{{'SUBTITLE' | translate}}</label>
        <input type="text" class="form-control" id="componentSubtitle" formControlName="subtitle" placeholder="{{'SUBTITLE' | translate}}" maxlength="50"/>
      </div>

      <div *ngIf="getFieldVisibilityConfig().includes('description')">
        <label class="form-label" for="component_description">{{'DESCRIPTION' | translate}}</label>
        <quill-editor
          class="description-editor"
          id="description-edit"
          [modules]="modules"
          formControlName="description" 
          (onEditorxChanged)="descriptionChanged($event)"
          [styles]="{
            borderRadius: '0 0 10px 10px',
            padding: '5px',
            marginBottom: '2vh'
          }">
        </quill-editor>
        <div>
          <small>{{'REMAINING_CHARACTERS' | translate}}: {{ remainingCharacters }} / {{ maxLength }}</small>
        </div>
      </div>
    </div>

      <div *ngIf="selectedDesign && selectedParentId !=3">
        <div class="subtitle-label">{{ 'ADD_ITEMS' | translate }}:</div>
        <div class="info-fields-container">
          <button class="add-img" (click)="addNewField()" type="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48">
              <path fill="none" stroke="#6BBECD" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M16 6H8a2 2 0 0 0-2 2v8m10 26H8a2 2 0 0 1-2-2v-8m26 10h8a2 2 0 0 0 2-2v-8M32 6h8a2 2 0 0 1 2 2v8m-10 8H16m8 8V16"/>
            </svg>
          </button>
        </div>
        <div cdkDropList cdkDropListOrientation="horizontal" class="image-list">
          <div class="input-container" formArrayName="infoFields">
            <div class="image-box" *ngFor="let field of infoFields.controls; let i = index" [formGroupName]="i">
              <div class="form-duo col-md-8">
                <div class="form-info">
                  <div class="form-floating form-input col-md-6" *ngIf="getFieldVisibilityConfig().includes('infoTitle')">
                    <input type="text" class="form-control" formControlName="infoTitle" placeholder="Título" maxlength="50">
                    <label for="infoTitle">{{ 'TITLE' | translate }}</label>
                  </div>
                  <div class="form-floating form-input col-md-5" *ngIf="getFieldVisibilityConfig().includes('urlName')">
                    <input type="text" class="form-control" formControlName="urlName" placeholder="Enlace / botón" maxlength="50">
                    <label for="urlName">{{ 'LINK_NAME' | translate }}</label>
                  </div>
                </div>
                <div class="form-floating form-input" *ngIf="getFieldVisibilityConfig().includes('redirectUrl')">
                  <input type="text" class="form-control" formControlName="redirectUrl" placeholder="Enlace de redirección">
                  <label for="redirectUrl">{{ 'REDIRECTION_LINK' | translate }}</label>
                </div>
                <div *ngIf="getFieldVisibilityConfig().includes('infoDescription')">
                  <label class="form-label form-input" for="infoDescription">{{'DESCRIPTION' | translate}}</label>
                  <quill-editor
                    class="info-description-editor form-input"
                    id="info-description-edit"
                    [modules]="modules"
                    formControlName="infoDescription"
                    (onEditorChanged)="infoDescriptionChanged($event)"
                    [styles]="{
                      borderRadius: '0 0 10px 10px',
                      padding: '5px',
                      marginBottom: '2vh'
                    }">
                  </quill-editor>
                  <div>
                    <small>
                      {{'REMAINING_CHARACTERS' | translate}}: {{ remainingInfoCharacters }} / {{ infoMaxLength }}
                    </small>
                  </div>
                </div>
              </div>
              <div class="image col-md-3" *ngIf="getFieldVisibilityConfig().includes('infoImageUrl')">
                <a class="add-button" (click)="triggerFileInput(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 256 256"><path fill="#5E35B1" d="M208 56h-27.72L169 39.12A16 16 0 0 0 155.72 32h-55.44A16 16 0 0 0 87 39.12L75.72 56H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h160a24 24 0 0 0 24-24V80a24 24 0 0 0-24-24m-48 88h-24v24a8 8 0 0 1-16 0v-24H96a8 8 0 0 1 0-16h24v-24a8 8 0 0 1 16 0v24h24a8 8 0 0 1 0 16"/></svg>
              </a>
              <input id="btn-upload-{{i}}" type="file" class="d-none" (change)="handleInfoImageChange(i, $event)">
              <img *ngIf="field.get('imageURL')?.value" [src]="sanitizeImageURL(field.get('imageURL')?.value)" alt="Image Preview">
            </div>
            <div>
              <button type="button" class="btn btn-danger delete-button" (click)="removeField(i)">
                x
              </button>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <button type="submit" class="btn btn-outline-secondary submit-btn">
          {{'CREATE' | translate}}
        </button>
      </div>
    </form>
  </div>

  <div class="modal component-confirm-modal fade" id="successcomponentModal" tabindex="-1">
    <div class="modal-dialog component-modal-dialog" role="document">
      <div class="modal-content component-modal-content">
        <div class="modal-body component-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'COMPONENT_CREATED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal component-confirm-modal fade" id="errorcomponentModal" tabindex="-1">
    <div class="modal-dialog component-modal-dialog" role="document">
      <div class="modal-content component-modal-content">
        <div class="modal-body component-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal component-confirm-modal fade" id="errorMaxMinItemModal" tabindex="-1">
    <div class="modal-dialog component-modal-dialog" role="document">
      <div class="modal-content component-modal-content">
        <div class="modal-body component-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{ errorMessage }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
