<div class="accordion mt-3" id="itemAccordion{{ itemId }}">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status"></div>
  </div>
    <div  *ngIf="!loading" class="accordion-item">
      <h2 class="accordion-header" [id]="'heading' + itemId">
        <button 
          class="accordion-button collapsed" 
          type="button" 
          data-bs-toggle="collapse" 
          [attr.data-bs-target]="'#collapse' + itemId" 
          aria-expanded="true" 
          [attr.aria-controls]="'collapse' + itemId">
          {{ 'BENEFICIARIES_ASSIGNED' | translate }} {{ assignedBeneficiaries.size }} / {{ maxBeneficiaries }}
        </button>
      </h2>
      <div [id]="'collapse' + itemId" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + itemId">
        <div  class="accordion-body">
          <ul class="beneficiaries-list">
            <ng-container *ngFor="let beneficiary of beneficiaries">
              <li>
                <input
                  type="checkbox"
                  class="styled-checkbox"
                  [id]="'beneficiary-' + beneficiary.beneficiaryNationalId"
                  [checked]="isChecked(beneficiary.beneficiaryNationalId)"
                  [attr.data-itemBeneficiaryId]="beneficiary.itemBeneficiaryId || ''"
                  (change)="toggleBeneficiarySelection($event, beneficiary)" />              
                <label [for]="'beneficiary-' + beneficiary.beneficiaryNationalId" class="beneficiary-label">
                  {{ beneficiary.beneficiaryName }}
                  <span *ngIf="beneficiary.isClient">({{ 'CLIENT' | translate }})</span>
                </label>
              </li>
            </ng-container>
          </ul>
          <div class="text-end mt-3">
            <button 
              type="button" class="submit-btn" 
              (click)="saveBeneficiaries()">
              {{ 'ASSIGN_BENEFICIARIES' | translate }}
            </button>
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <div class="modal beneficiaries-confirm-modal" id="successBeneficiariesModal" tabindex="-1">
    <div class="modal-dialog beneficiaries-modal-dialog" role="document">
      <div class="modal-content beneficiaries-modal-content">
        <div class="modal-body beneficiaries-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'MODAL_MESSAGE_SUCCESS_BENEFICIARIES' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal error-confirm-modal fade" id="errorBeneficiariesModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}!</h3>
          <p class="text-center">{{ 'MODAL_MESSAGE_ERROR_BENEFICIARIES' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal error-confirm-modal fade" id="itemHolderModalRequired" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}!</h3>
          <p class="text-center">{{ 'MODAL_MESSAGE_ITEM_HOLDER_REQUIRED' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal error-confirm-modal fade" id="maxBeneficiariesModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}!</h3>
          <p class="text-center">{{ 'MODAL_MESSAGE_MAX_BENEFICIARIES' | translate }} {{maxBeneficiaries}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal error-confirm-modal fade" id="errorNoBeneficiariesModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}!</h3>
          <p class="text-center">{{ 'MODAL_MESSAGE_NO_BENEFICIARIES' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  