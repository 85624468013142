<div *ngIf="permissions.home" class="form-card">
  <div class="label">
    <strong>{{ 'DAILY_SALES' | translate}}</strong>
  </div>
  <div class="filters visually-hidden">
    <div class="label">
      <strong > {{'SALES_AMOUNT' | translate}} </strong>
      <span>{{appComponent.formatMoney(totalSalesAmount)}}</span>
    </div>

    <div class="label">
      <strong > {{'AMOUNT_OF_SALES' | translate}} </strong>
      <span>{{totalSalesQuantity}}</span>
    </div>

  </div>
  <div class="filters">
    <!-- <input type="date" class="form-control" [(ngModel)]="date" (change)="onDateChange()"> -->
    <div class="date-range">
      <mat-form-field>
        <mat-label>{{'DATES' | translate}}</mat-label>
        <mat-date-range-input #dateRangeInput [rangePicker]="picker">
          <input #startDateInput matStartDate placeholder="Fecha inicial" (dateChange)="onStartDateChange($event.value)" [(ngModel)]="tempStartDate">
          <input #endDateInput matEndDate placeholder="Fecha final" (dateChange)="onEndDateChange($event.value)" [(ngModel)]="tempEndDate">
        </mat-date-range-input>
        <mat-hint>MM/DD/AAAA – MM/DD/AAAA</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="label">
      <strong >{{'AMOUNT' | translate}} POS: </strong>
      <span>{{appComponent.formatMoney(posTotalAmount)}}</span>
    </div>
    <div class="label">
      <strong >{{'AMOUNT' | translate}}  ONLINE: </strong>
      <span>{{appComponent.formatMoney(onlineTotalAmount)}}</span>
    </div>
    <div class="label">
      <strong >{{'AMOUNT' | translate}}  Total: </strong>
      <span>{{appComponent.formatMoney(totalSalesAmount)}}</span>
    </div>
    <!-- <select class="form-select visually-hidden" [(ngModel)]="channel" (change)="onChannelChange($event)">
      <option selected value="0">Canal de venta</option>
      <option value="1">ONLINE</option>
      <option value="2">POS</option>
    </select> -->
  </div>
  <div style="width: 100%; margin: auto;">
    <canvas id="canvas" width="400" height="400"></canvas>
  </div>
</div>
<div *ngIf="permissions.home">
  <div class="form-card" *ngIf="vendors.length> 0">
    <div class="label">
      <strong>VENTAS POR VENDEDOR</strong>
    </div>
    <div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading && vendors.length > 0" class="table-bordered table-hover">
      <thead>
        <tr class="table-header">
          <th scope="col">Vendedor</th>
          <th scope="col">Monto Vendido</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let vendor of vendors">
          <td>{{ vendor.firstName }} {{vendor.lastName}}</td>
          <td>{{ appComponent.formatMoney(vendor.totalSales) }}</td>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
</div>
