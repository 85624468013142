import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-online-policy',
  templateUrl: './online-policy.component.html',
  styleUrls: ['./online-policy.component.scss']
})
export class OnlinePolicyComponent implements OnInit {
  @ViewChild('editor') editorElement!: ElementRef;
  modules = {};
  editor: any;
  onlinePolicyContent: string = ''; 

  loading = false;
  isEditingOnlinePolicy: boolean = false;
 
  constructor(
    private appComponent: AppComponent,
    private themingService: ThemingService,
    private apiService: ApiService 
  ) {}

  ngOnInit(): void {
    this.appComponent.goToTop();
    const url = this.themingService.onlinePolicies;
    console.log("url online policy: ",url)
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    const self = this;
    xhr.onreadystatechange = function process() {
      if (xhr.readyState == 4 && xhr.status === 200) {
        self.onlinePolicyContent = xhr.responseText;
      }
    };
    xhr.send();
  }

  switchEditingOnlinePolicy(){
    this.isEditingOnlinePolicy = !this.isEditingOnlinePolicy;
  }

  saveOnlinePolicy() {
    this.loading = true;
    console.log("html a subir",this.onlinePolicyContent)
    let stage = environment.stage; 
    this.apiService.uploadHtmlContent(`${this.themingService.shopId}/${stage}/config-files/online-policy.html`, this.onlinePolicyContent, {})
    .then((response: any) => {
      console.log('Changes saved successfully:', response);
      $('#successOnlinePolicyModal').modal('show');
    })
    .catch((error: any) => {
      console.error('Error saving changes:', error);
      $('#errorOnlinePolicyModal').modal('show');
    });
    this.switchEditingOnlinePolicy();
  }

  closeModal(){
    $('#successOnlinePolicyModal').modal('hide');
    $('#errorOnlinePolicyModal').modal('hide');
  }
}
