<div class="container">
  <div class=" container-inventory">
		<div class="header">
				<h1 class="title">{{ 'NEW_POINT_OF_SALE' | translate }}</h1>
				<app-back-arrow-settings [callback]="setListingShopPoints"></app-back-arrow-settings>
		</div>
		<form #myForm="ngForm">

			<div class="form-card">
				<div class="row">
          <div class="col-12">
						<label class="form-label is-required" for="shopPointName">{{'NAME' | translate}}</label>
						<input type="text" class="form-control" id="shopPointName" placeholder="{{'POINT_OF_SALE_NAME' | translate}}"
						(keyup)="changeShopPointName($event)" value="{{shopPoint.name}}" #shopPointName/>
					</div>
					<div class="col-10">
						<label class="form-label" for="shopPointPhone">{{'PHONE' | translate}}</label>
						<input type="text" class="form-control" id="shopPointPhone" placeholder="{{'POINT_OF_SALE_PHONE' | translate}}"
						(keyup)="changeShopPointPhone($event)" value="{{shopPoint.phone}}" #shopPointPhone/>
					</div>
          <div class="col-2">
						<div><label class="form-label" for="shop_point_status">{{'STATUS' | translate}}</label></div>
						<div class="switch">
						<label class="switch-label">
							<input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
							<span class="slider round"></span>
						</label>
						</div>
						<div><span id="statusText" class="status-text">{{ isActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span></div>
					</div>
					<div class="col-12">
						<label class="form-label" for="shopPointLocation">{{'LOCATION' | translate}}</label>
						<textarea class="form-control" id="shopPointLocation" placeholder="{{ 'POINT_OF_SALE_LOCATION' | translate }}" (keyup)="ChangeShopPointLocation($event)" #shopPointLocation>{{shopPoint.location}}</textarea>
						<div class="form-buttons">
								<button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
								{{'CREATE' | translate}}
								</button>
						</div>
					</div>
				</div>
			</div>
		</form>
   </div>
</div>

<div class="modal shopPoint-confirm-modal" id="shopPointLoadingModal" tabindex="-1">
	<div class="modal-dialog shopPoint-modal-dialog" role="document">
		<div class="modal-content shopPoint-modal-content">
			<div class="modal-body shopPoint-modal-body">
				<div class="loading">
					<div class="spinner-border" role="status"></div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal shopPoint-confirm-modal" id="successShopPointsModal" tabindex="-1">
	<div class="modal-dialog shopPoint-modal-dialog" role="document">
		<div class="modal-content shopPoint-modal-content">
			<div class="modal-body shopPoint-modal-body">
				<h3 class="text-center">{{'SUCCESS' | translate}}</h3>
				<p class="text-center">{{ 'POINT_OF_SALE_CREATED_SUCCESS' | translate }}</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="modal error-confirm-modal fade" id="requiredShopPointModal" tabindex="-1">
	<div class="modal-dialog error-modal-dialog" role="document">
		<div class="modal-content error-modal-content">
			<div class="modal-body error-modal-body">
				<h3 class="text-center">{{'ERROR' | translate}}</h3>
				<p class="text-center">{{ 'POINT_OF_SALE_NAME_REQUIRED' | translate }}</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="modal shopPoint-confirm-modal fade" id="errorShopPointsModal" tabindex="-1">
	<div class="modal-dialog shopPoint-modal-dialog" role="document">
		<div class="modal-content shopPoint-modal-content">
			<div class="modal-body shopPoint-modal-body">
				<h3 class="text-center">{{'ERROR' | translate}}</h3>
				<p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
			</div>
			<div class="modal-buttons">
				<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
			</div>
		</div>
	</div>
</div>

