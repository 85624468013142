<div class="container">
  <div class="container-giftcards">
    <form #myForm="ngForm">
    <div class="form-card">
      <div class="form-group-container">
        <div>
          <label class="form-label is-required" for="giftCardName">{{ 'CARD_TYPE' | translate }}</label>
          <select class="form-control" id="giftCardName" (change)="onGiftCardNameChange($event)" [(ngModel)]="selectedGiftCardName" name="giftCardName">
            <ng-container *ngIf="!selectedGiftCardName; else selectedCard">
              <option value="" selected hidden>{{ 'SELECT_CARD' | translate }}</option>
            </ng-container>
            <ng-template #selectedCard>
              <option [value]="selectedGiftCardName">{{ selectedGiftCardName }}</option>
            </ng-template>
            <option *ngFor="let giftCard of giftCards" [value]="giftCard.name">{{ giftCard.name }}</option>
          </select>
        </div>
        <div class="input-container">
          <label class="form-label is-required" for="giftCard_amount">{{'AMOUNTS_AVAILABLE' | translate }}</label>
          <select class="form-control" [(ngModel)]="selectedAmount" name="amount" (change)="onAmountChange($event)" [disabled]="!selectedGiftCardName" [disabled]="!cardSelected">
              <ng-container *ngIf="amountsArray[0] === 0">
                  <option value="" selected hidden>{{'SELECT_CARD' | translate}}</option>
              </ng-container>
              <ng-container *ngIf="amountsArray[0] !== 0">
                  <option value="" selected hidden>{{'SELECT_AMOUNT' | translate}}</option>
                  <option *ngFor="let amount of amountsArray" [value]="amount">{{ appComponent.formatMoney(parseAmount(amount)) }}</option>
              </ng-container>
          </select>
      </div>
      </div>

        <div class="images-container">
          <div class="form-label">{{'IMAGE' | translate}}:</div>
          <div class="image-list">
            <div class="image-box">
              <img *ngIf="selectedGiftCard?.images" [src]="selectedGiftCard.images" alt="Gift Card Image"/>
            </div>
          </div>
        </div>
        <div class="amount-container">
          <div class="input-container">
            <div class="form-label">{{'PERSONALIZED_MESSAGE' | translate}}:</div>
            <textarea style="resize: none;  height: 80px;"(keyup)="ChangeGiftCardMessage($event)" [(ngModel)]="giftCard.giftCardMessage" name="giftCardMessage"></textarea>
          </div>
          <div class="input-container">
            <div class="form-label is-required">{{'CUSTOMER_EMAIL' | translate}}:</div>
            <input type="text" (input)="ChangeGiftCardEmail($event)" [(ngModel)]="giftCard.giftCardEmail" name="giftCardEmail">
          </div>
        </div>
          <div class="form-buttons">
            <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
              {{'CREATE' | translate}}
            </button>
          </div>
      </div>
    </form>
  </div>

  <div class="modal giftcard-confirm-modal" id="giftCardLoadingModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <div class="loading">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal giftcard-confirm-modal" id="successGiftCardModal" tabindex="-1">
  <div class="modal-dialog giftcard-modal-dialog" role="document">
    <div class="modal-content giftcard-modal-content">
      <div class="modal-body giftcard-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{'CARD_CREATED_SUCCESSFULLY' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
  </div>

  <div class="modal giftcard-confirm-modal fade" id="errorGiftCardModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CREATE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="requiredGiftCardModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'FILL_REQUIRED_FIELDS' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
