<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-giftcards">

    <div class="header">
      <h1 class="title">{{'NEW_CARD' | translate }}</h1>
      <app-back-arrow-settings [routerLink]="['/giftCardTypes']"></app-back-arrow-settings>
      </div>
  <form #myForm="ngForm">
    <div class="form-card">
      <div class="form-group-container">
        <div>
          <label class="form-label is-required" for="giftcard_name">{{'NAME' | translate}}</label>
          <input type="text" class="form-control" id="giftCardName" placeholder="{{'CARD_NAME' | translate}}"
            (keyup)="ChangeGiftCardName($event)" [(ngModel)]="giftCard.name" name="giftCardName" />
        </div>
        <div>
          <label class="form-label" for="giftCard_validityMonths">{{'VALIDITY_MONTHS' | translate}}</label>
          <select class="form-control" id="giftCardValidMonths" placeholder="Cantidad de meses" [(ngModel)]="giftCard.validMonths" name="giftCardValidMonths">
            <option value="0">{{'NO_EXPIRATION' | translate}}</option>
            <ng-container *ngFor="let month of monthsArray">
              <option [value]="month">{{ month === 0 ? 'Ilimitado' : month}}</option>
            </ng-container>
          </select>
        </div>
        <div>
          <label class="form-label" for="giftCard_stock">{{'QUANTITY' | translate}}</label>
          <select class="form-control" id="giftCardStock" placeholder="Cantidad de tarjetas"
                  (change)="ChangeGiftCardStock($event)" [(ngModel)]="giftCard.stock" name="giftCardStock">
            <option value="999999">{{ 'UNLIMITED' | translate }}</option>
            <option *ngFor="let quantity of quantityArray" [value]="quantity">{{ quantity }}</option>
          </select>
        </div>
      </div>
      <div class="switch-container">
      <div class="form-switch">
          <label class="form-label" for="giftCard_status"> {{'STATUS' | translate}}</label>
          <div class="switch-title-container">
            <label class="form-check-label switch" for="flexCheckDefault">
              <input class="form-check-input" type="checkbox" id="flexCheckDefault" [checked]="giftCard.status === 1" (change)="ChangeGiftCardStatus($event)">
              <span class="slider round"></span>
              <div class="switch-text">
                <span>{{ giftCard.status === 1 ? 'Activa' : 'Inactiva' }}</span>
              </div>
            </label>
        </div>
      </div>
    </div>
      <div class="amount-container">
        <div class="input-container">
          <label class="form-label is-required" for="giftCard_amount"> {{'AMOUNT' | translate}}</label>
          <button type="button" class="btn btn-outline-secondary small-btn confirm-btn text-white" (click)="addAmount()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus" _ngcontent-eos-c103=""><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
          </button>
        </div>
        <div class="value-container" *ngFor="let amountControl of amountFormArray.controls; let i = index">
          <input type="number" min="0" class="form-control" [formControl]="getFormControl(i)" placeholder="Monto {{ i + 1 }}" #giftCardAmount />
          <div class="delete-btn-container">
            <button type="button"class="btn btn-outline-secondary delVariantBtn small-btn cancel-btn"(click)="removeAmount(i)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash" _ngcontent-hyp-c84=""><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
            </button>
          </div>
        </div>
      </div>
        <div class="form-buttons">
          <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
             {{'CREATE' | translate}}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="modal giftcard-confirm-modal" id="giftCardLoadingModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <div class="loading">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal giftcard-confirm-modal" id="successGiftCardModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <h3 class="text-center"> {{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{'CARD_CREATED_SUCCESSFULLY' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal error-confirm-modal fade" id="requiredModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">! {{'ERROR' | translate}}</h3>
          <p class="text-center">{{'FILL_REQUIRED_FIELDS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal giftcard-confirm-modal fade" id="errorGiftCardModal" tabindex="-1">
    <div class="modal-dialog giftcard-modal-dialog" role="document">
      <div class="modal-content giftcard-modal-content">
        <div class="modal-body giftcard-modal-body">
          <h3 class="text-center"> {{'ERROR' | translate}}</h3>
          <p class="text-center">{{ 'ERROR_SAVING_CHANGES' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
