import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  @Output() categoryUpdated = new EventEmitter<void>();
  loading: boolean = false;
  category: any;
  categories: any = [];
  
  @Input()
  categoryId!: string;
  subCategoryId!: string;
  imageUrl: SafeUrl | null = null;

  constructor(
    public settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.getCategoryById(parseInt(this.categoryId), '');
    this.getCategories();
  }

  getCategoryById(id: number, _filter: string) {
    this.loading = true;
    this.apiService
      .getCategoryById(id, this.themingService.shopId)
      .then((data: any) => {
        this.category = data.category;
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.category.categoryImageUrl); // Previsualizar la imagen desde la base de datos
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changeCategoryName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.category.categoryName = element.value;
  }

  onSwitchChange(event: any) {
    this.category.isForVerifiedClient = event.checked ? 1 : 0;
  }

  triggerFileInput() {
    const fileInput = document.getElementById('optionImage') as HTMLInputElement;
    fileInput.click();
  }

  private resizeImage(image: HTMLImageElement, maxWidth: number, maxHeight: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    context?.drawImage(image, 0, 0, width, height);

    return canvas;
  }

  private convertCanvasToFile(canvas: HTMLCanvasElement, fileName: string): File {
    const dataURL = canvas.toDataURL('image/jpeg');
    const base64Data = dataURL.split(',')[1];
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
    return new File([blob], fileName);
  }

  async onImageChange(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(image.src); 

        image.onload = async () => {
          const resizedImage = this.resizeImage(image, 1400, 250);
          const resizedFile = this.convertCanvasToFile(resizedImage, file.name);

          try {
            const imageData = await this.uploadImage(resizedFile);
            this.category.categoryImageUrl = imageData.imageURL; 
          } catch (error) {
            console.error('Error uploading image:', error);
          }
        };
      };

      reader.readAsDataURL(file);
    }
  }

  uploadImage(image: File): Promise<any> {
    return this.blobFile(image)
      .then((res: any) => {
        const timestamp = Date.now();
        const ext = image.name.split('.').pop();
        const key = `${this.themingService.shopId}/${environment.stage}/images/category/banner${timestamp}.${ext}`;
        return this.apiService.uploadFile(key, res.blob, { position: 1, type: 'original' })
          .then((data: any) => {
            return {
              imageURL: data.imageURL,
              blob: res.blob,
            };
          });
      });
  }

  blobFile = async ($event: any): Promise<any> => {
    try {
      const unSafeImg = window.URL.createObjectURL($event);
      const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onload = () => {
          resolve({
            blob: $event,
            img,
            base: reader.result
          });
        };
        reader.onerror = (error) => {
          reject({
            blob: $event,
            img,
            base: null
          });
        };
        reader.readAsDataURL($event);
      });
    } catch (e) {
      return Promise.reject(null);
    }
  };

  getCategories() {
    this.apiService
      .getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  removeImage() {
    this.imageUrl = null;
    this.category.categoryImageUrl = ''; 
    const fileInput = document.getElementById('optionImage') as HTMLInputElement;
    fileInput.value = ''; 
  }
  
  validateFormData() {
    if (this.category.categoryParentId === 0) {
      this.category.categoryParentId = null;
    }

    if ((this.category.categoryActiveIcon) === "") {
      this.category.categoryActiveIcon = null;
    }

    if ((this.category.categoryNonActiveIcon) === "") {
      this.category.categoryNonActiveIcon = null;
    }

    this.loading = true;
    this.apiService.updateCategory(
      parseInt(this.categoryId),
      this.category.categoryIdParent,
      this.themingService.shopId,
      this.category.categoryName,
      this.category.categoryActiveIcon,
      this.category.categoryNonActiveIcon,
      this.category.categoryPosition,
      this.category.menuCategory,
      this.category.homeCategory || 1,
      this.category.categoryDesignType, 
      this.category.categoryImageUrl,
      this.category.isForVerifiedClient
    )
      .then((data: any) => {
        if (data.status === 200) {
          setTimeout(() => {
            this.categoryUpdated.emit();
          }, 2000);
          $('#successEditCategoryModal').modal('show');
          this.loading = false;
        }
        else {
          $('#errorEditCategoryModal').modal('show');
          this.loading = false;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  closeModal() {
    $('#successEditCategoryModal').modal('hide');
    $('#errorEditCategoryModal').modal('hide');
  }
}
