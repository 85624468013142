<!-- theming-settings.component.html -->
<div class="container">
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">{{'STORE_THEME' | translate}}</h1>
    </div>

    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>

    <div class="form-card" *ngIf="!loading">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="droplet"></i>
          <strong>{{ isEditingColors ? ('SELECT_COLORS' | translate) : ('COLORS' | translate) }}</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" *ngIf="!isEditingColors" (click)="switchEditingColors()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingColors" (click)="cancelEditingColors()">{{'CANCEL' | translate}}</a>
          <a class="primary-btn edit-link" *ngIf="isEditingColors" (click)="updateThemingColors()">{{'SAVE' | translate}}</a>
        </div>
      </div>
      <div class="info-container">
        <div class="color-container" *ngIf="!isEditingColors">
          <label class="data-label" for="">{{ 'MAIN' | translate }}</label>
          <div class="color-picker">
            <div class="container-image">
              <div class="colorful-svg" [style.--svg-color]="shopMainColor"></div>
              <div class="info-image">
                <img src="https://mobydyg-files.s3.amazonaws.com/mainColorExample" alt="Informative Image">
              </div>
            </div>
            <output class="data-output">{{ shopMainColor }}</output>
          </div>
        </div>
        <div class="editing color-container form-group" *ngIf="isEditingColors">
          <label class="data-label" for="mainColorInput">{{ 'MAIN' | translate }}</label>
        <div class="color-display">
          <input type="color" class="form-control form-control-color" id="mainColorInput" [(ngModel)]="shopMainColor" title="{{'SELECT_COLOR' | translate}}">
          <input type="text" class="form-control" [(ngModel)]="shopMainColor">
        </div>
      </div>
      <div class="color-container" *ngIf="!isEditingColors">
        <label class="data-label" for="">{{ 'HEADER' | translate }}</label>
        <div class="color-picker">
          <div class="container-image">
            <div class="colorful-svg" [style.--svg-color]="shopHeaderColor"></div>
            <div class="info-image">
              <img src="https://mobydyg-files.s3.amazonaws.com/shopHeaderColorExample" alt="Informative Image">
            </div>
          </div>
          <output class="data-output">{{ shopHeaderColor }}</output>
        </div>
      </div>
      <div class="editing color-container form-group" *ngIf="isEditingColors">
        <label class="data-label" for="secondaryColorInput">{{ 'HEADER' | translate }}</label>
        <div class="color-display">
          <input type="color" class="form-control form-control-color" id="secondaryColorInput" [(ngModel)]="shopHeaderColor" title="{{ 'SELECT_COLOR' | translate }}">
          <input type="text" class="form-control" [(ngModel)]="shopHeaderColor">
        </div>
      </div>
        <div class="color-container" *ngIf="!isEditingColors">
          <label class="data-label" for="">Menú</label>
          <div class="color-picker">
            <div class="container-image">
              <div class="colorful-svg" [style.--svg-color]="shopSecondaryColor"></div>
              <div class="info-image">
                <img src="https://mobydyg-files.s3.amazonaws.com/shopSecondaryColorExample" alt="Informative Image">
              </div>
            </div>
            <output class="data-output">{{ shopSecondaryColor }}</output>
          </div>
        </div>
        <div class="editing color-container form-group" *ngIf="isEditingColors">
          <label class="data-label" for="secondaryColorInput">Menú</label>
          <div class="color-display">
            <input type="color" class="form-control form-control-color" id="secondaryColorInput" [(ngModel)]="shopSecondaryColor" title="{{ 'SELECT_COLOR' | translate }}">
            <input type="text" class="form-control" [(ngModel)]="shopSecondaryColor">
          </div>
        </div>

        <div class="color-container" *ngIf="!isEditingColors">
          <label class="data-label" for="">{{ 'HIGHLIGHTED' | translate }}</label>
          <div class="color-picker">
            <div class="container-image">
              <div class="colorful-svg" [style.--svg-color]="shopMainColorDark"></div>
              <div class="info-image">
                <img src="https://mobydyg-files.s3.amazonaws.com/shopMainColorDarkExample.jpg" alt="Informative Image">
              </div>
            </div>
            <output class="data-output">{{ shopMainColorDark }}</output>
          </div>
        </div>
        <div class="editing color-container form-group" *ngIf="isEditingColors">
          <label class="data-label" for="shopMainColorDark">{{ 'HIGHLIGHTED' | translate }}</label>
          <div class="color-display">
            <input type="color" class="form-control form-control-color" id="shopMainColorDark" [(ngModel)]="shopMainColorDark" title="{{ 'SELECT_COLOR' | translate }}">
            <input type="text" class="form-control" [(ngModel)]="shopMainColorDark">
        </div>
        </div>
      </div>
    </div>
    <div class="form-card" *ngIf="!loading">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="image"></i>
          <strong>Logos</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" *ngIf="!isEditingImages" (click)="switchEditingImages()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingImages" (click)="this.isEditingImages = !this.isEditingImages;">{{'CANCEL' | translate}}</a>
          <a class="primary-btn edit-link" *ngIf="isEditingImages" (click)="saveThemingLogo()">{{'SAVE' | translate}}</a>
        </div>
      </div>
      <div class="info-container">
        <div class="info-container">
          <div class="data-container large" *ngIf="!isEditingImages">
            <div class="box col-md-4">
              <label class="data-label" for="">{{ 'MAIN_LOGO' | translate }}</label>
              <img class="data-output image-output" src="{{ shopTheme[0].shopLogoLarge }}" />
            </div>
            <div class="box col-md-4">
              <label class="data-label" for="">{{ 'SECONDARY_LOGO' | translate }}</label>
              <img class="data-output image-output" src="{{ shopTheme[0].shopLogoWhite }}" />
            </div>
            <div class="box col-md-4">
              <label class="data-label" for="">{{ 'MOBILE_LOGO' | translate }}</label>
              <img class="data-output image-output" src="{{ shopTheme[0].shopLogoSmall }}" />
            </div>
          </div>
          <div class="editing data-container large form-group" *ngIf="isEditingImages">
            <div class="box col-md-4">
              <label class="data-label" for="">{{ 'MAIN_LOGO' | translate }}</label>
              <img class="data-output image-output" *ngIf="!this.mainLogoFile" [src]="mainLogoUrl" alt="Banner">
              <img class="data-output image-output" *ngIf="this.mainLogoFile" [src]="this.mainLogoFile" alt="No Image">
              <input class="form-control form-control-sm" accept="image/*" type="file" (change)="onMainLogoSelected($event)"/>
            </div>
            <div class="box col-md-4">
              <label class="data-label" for="">{{ 'SECONDARY_LOGO' | translate }}</label>
              <img class="data-output image-output" *ngIf="!this.secondaryLogoFile" [src]="secondaryLogoUrl" alt="Banner">
              <img class="data-output image-output" *ngIf="this.secondaryLogoFile" [src]="this.secondaryLogoFile" alt="No Image">
              <input class="form-control form-control-sm" accept="image/*" type="file" (change)="onSecondaryLogoSelected($event)"/>
            </div>
            <div class="box col-md-4">
              <label class="data-label" for="">{{ 'MOBILE_LOGO' | translate }}</label>
              <img class="data-output image-output" *ngIf="!this.mobileLogoFile" [src]="mobileLogoUrl" alt="Banner">
              <img class="data-output image-output" *ngIf="this.mobileLogoFile" [src]="this.mobileLogoFile" alt="No Image">
              <input class="form-control form-control-sm" accept="image/*" type="file" (change)="onMobileLogoSelected($event)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-card" *ngIf="!loading">
      <app-menu></app-menu>
    </div>
      <div class="form-card" *ngIf="!loading">
        <app-components></app-components>
      </div>
    </div>
  </div>

  <div class="modal category-confirm-modal" id="themingSuccess" tabindex="-1">
    <div class="modal-dialog category-modal-dialog" role="document">
      <div class="modal-content category-modal-content">
        <div class="modal-body category-modal-body">
          <h3 class="text-center">{{ 'CHANGES_SAVED' | translate }}</h3>
          <p class="text-center">{{ 'CHANGES_SAVED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal category-confirm-modal" id="themingError" tabindex="-1">
    <div class="modal-dialog category-modal-dialog" role="document">
      <div class="modal-content category-modal-content">
        <div class="modal-body category-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal category-confirm-modal" id="themingEmpty" tabindex="-1">
    <div class="modal-dialog category-modal-dialog" role="document">
      <div class="modal-content category-modal-content">
        <div class="modal-body category-modal-body">
          <h3 class="text-center">{{ 'NO_CHANGES' | translate }}</h3>
          <p class="text-center">{{ 'NO_CHANGES_TO_SAVE' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal category-confirm-modal" id="loadingModal" tabindex="-1">
    <div class="modal-dialog category-modal-dialog" role="document">
      <div class="modal-content category-modal-content">
        <div class="modal-body category-modal-body">
          <h3 class="text-center">{{ 'LOADING' | translate }}</h3>
          <div class="loading">
            <div class="spinner-border" role="status"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
