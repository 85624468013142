import { Component, Input } from '@angular/core';
import { ITimekitComponent  } from '../timekit-tabs/timekit-tabs.component';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { FormBuilder, FormGroup } from '@angular/forms';

interface Day {
  name: string;
  startTime: string;
  endTime: string;
}

interface Project {
  timekitProjectId: number;
  shopId: number;
  timekitResourceId: number;
  projectId: string;
  projectName: string;
}

@Component({
  selector: 'app-timekit-schedule',
  templateUrl: './timekit-schedule.component.html',
  styleUrls: ['./timekit-schedule.component.scss']
})
export class TimekitScheduleComponent implements ITimekitComponent {
   @Input() data: any;
   public projects: Project[] = [];
   public selectedProjectId: string = '';
   public form: FormGroup;


  days: Day[] = [
    { name: 'Monday', startTime: '09:00', endTime: '17:00' },
    { name: 'Tuesday', startTime: '09:00', endTime: '17:00' },
    { name: 'Wednesday', startTime: '09:00', endTime: '17:00' },
    { name: 'Thursday', startTime: '09:00', endTime: '17:00' },
    { name: 'Friday', startTime: '09:00', endTime: '17:00' }
  ];

  dayTranslation: { [key: string]: string } = {
    'Monday': 'Lunes',
    'Tuesday': 'Martes',
    'Wednesday': 'Miércoles',
    'Thursday': 'Jueves',
    'Friday': 'Viernes'
  };

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      resources: ['']
    });
  }

  ngOnInit() {
    this.getTimekitProjects();
  }

  async getTimekitProjects() {
    try {
      const response = await this.apiService.getTimekitProjects(this.themingService.shopId) as { data: Project[] };
      this.projects = response.data || [];
    } catch (error) {
      console.error('Error al obtener proyectos:', error);
      this.projects = [];
    }
  }

  getTranslatedDay(day: string): string {
    return this.dayTranslation[day] || day;
  }

  async updateAvailability() {
    try {
      const formattedConstraints = this.days.map(day => ({
        allow_day_and_time: {
          day: day.name,
          start: parseInt(day.startTime.split(':')[0], 10),
          end: parseInt(day.endTime.split(':')[0], 10),
        }
      }));

      const payload = {
        action: 'updateAvailability',
        availability_constraints: formattedConstraints
      };

      const response = await this.apiService.updateAvailability(
        this.themingService.shopId,
        this.selectedProjectId,
        formattedConstraints
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  onProjectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedProjectId = selectElement.value;
    this.updateAvailability();
  }

  async onSubmit() {
    try {
      const response = await this.updateAvailability();
    } catch (error) {
      console.error('Error al actualizar disponibilidad:', error);
    }
  }
}
