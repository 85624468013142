<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-orders">
    <h1 class="title">{{'YOUR_ORDERS' | translate}}</h1>
    <div class="form-card">
      <div class="search-container">
        
        <div class="filters-container col-md-3">
          <input  class="form-control" type="text" placeholder="{{'SEARCH' | translate}}" [(ngModel)]="searchString" (keyup)="getSales();"/>
        </div>
        <div class="filters">
        <div class="filters-container col-md-3">
          <span class="form-label"> {{'FILTER_BY_PAYMENT' | translate}}</span>
          <select class="form-select" [(ngModel)]="selectedPaymentType" (change)="onPaymentTypeChange()" style="width: 200px;">
            <option value="Todos"> {{'ALL' | translate}}</option>
            <option value="Tarjeta de Crédito">{{'CREDIT_CARD' | translate}}</option>
            <option value="SINPE MOVIL">SINPE MOVIL</option>
            <option value="Transferencia Bancaria"> {{'BANK_TRANSFER' | translate}}</option>
            <option value="Efectivo"> {{'CASH' | translate}}<option>
            <option value="Payvalida">Payvalida</option>
            <option value="Link de Pago">{{'PAYMENT_LINK' | translate}}</option>
          </select>
        </div>
        <div class="filters-container col-md-3">
            <span class="form-label">{{'FILTER_BY_STATE' | translate}}</span>
            <select class="form-select" [(ngModel)]="selectedOrderState" (change)="onOrderStateChange()" style="width: 200px;">
              <option value="Todos"> {{'ALL' | translate}}</option>
              <option value="Cancelado"> {{'CANCELLED' | translate}}</option>
              <option value="Pendiente de verificación">{{'PENDING_VERIFICATION' | translate}}</option>
              <option value="Preparando orden">{{'PREPARING_ORDER' | translate}}</option>
              <option value="Entregado">{{'DELIVERED' | translate}}</option>
              <option value="Pendiente Compra Click">{{'PENDING_PURCHASE_CLICK' | translate}}</option>
            </select>
        </div>
        <div class="date-range col-md-4">
          <div class="filter-group">
            <span class="form-label"> {{'FILTER_BY_DATES' | translate}}</span>
            <div class="date-range">
              <button class="filter-btn" (click)="resetDateFilters()">
                <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="16" height="16" viewBox="0 0 256 256" xml:space="preserve">
                  <defs>
                  </defs>
                  <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                    <path d="M 75.702 53.014 c -2.142 7.995 -7.27 14.678 -14.439 18.816 c -7.168 4.138 -15.519 5.239 -23.514 3.095 c -16.505 -4.423 -26.335 -21.448 -21.913 -37.953 C 20.258 20.467 37.286 10.64 53.79 15.06 c 4.213 1.129 8.076 3.118 11.413 5.809 l -8.349 8.35 h 26.654 V 2.565 l -8.354 8.354 c -5.1 -4.405 -11.133 -7.61 -17.74 -9.381 C 33.451 -4.882 8.735 9.389 2.314 33.35 c -6.42 23.961 7.851 48.678 31.811 55.098 C 38.001 89.486 41.934 90 45.842 90 c 7.795 0 15.488 -2.044 22.42 -6.046 c 10.407 -6.008 17.851 -15.709 20.962 -27.317 L 75.702 53.014 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: white; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" /></g>
                  </svg>
              </button>
            </div>
          </div>
          <mat-form-field>
            <mat-label>{{'SELECT_A_RANGE' | translate}}</mat-label>
            <mat-date-range-input #dateRangeInput [rangePicker]="picker">
              <input #startDateInput matStartDate placeholder="Fecha inicial" [ngModel]="tempStartDate" (dateChange)="onStartDateChange($event.value)">
              <input #endDateInput matEndDate placeholder="Fecha final" [ngModel]="tempEndDate" (dateChange)="onEndDateChange($event.value)">
            </mat-date-range-input>
            <mat-hint>MM/DD/AAAA – MM/DD/AAAA</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
    </div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading && sales.length > 0" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">{{'ORDER_NUMBER' | translate}}</th>
          <th scope="col">{{'POINTS_OF_SALE' | translate}}</th>
          <th scope="col">{{'SALE_DATE' | translate}}</th>
          <th scope="col">{{'TOTAL_BILLED' | translate}}</th>
          <th scope="col">{{'PAYMENT_TYPE' | translate}}</th>
          <th scope="col">{{'ORDER_STATUS' | translate}}</th>
          <th scope="col" class="table-end">{{'SALES_CHANNEL' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let sale of sales" [ngClass]="{'online-row': sale.channelId === 1, 'pos-row': sale.channelId !== 1}">
            <td>
              <a [routerLink]="['/orders', sale.saleId]" routerLinkActive="active">{{ sale.saleShortCode }}</a>
            </td>
            <td *ngIf="!sale.shopPointsAmount">{{'STORE' | translate}}</td>
            <td *ngIf="sale.shopPointsAmount">{{ sale.shopPointsAmount < 2 ? sale.shopPointName : "Mixto" }}</td>
            <td>{{ formatDate(sale.saleDate) }}</td>
            <td>{{ appComponent.formatMoney(sale.saleEndPrice) }}</td>
            <td>{{ sale.paymentTypeName }}</td>
            <td>{{ saleStateName[sale.saleState] }}</td>
            <td>
              <span class="channel">
              {{ sale.channelId == 1 ? "Online" : "POS" }}
            </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="no-orders" *ngIf="showNoOrdersMessage && !sales.length">
        <span>{{'NO_ORDERS_EXITS' | translate}}</span>
      </div>
    </div>
    <div class="nav-container" *ngIf="!loading">
      <nav>
        <ul class="pagination">
          <li [ngClass]="{ 'page-item': true, disabled: currentPage == 1 }">
            <a class="page-link page-text-link" (click)="movePage(false)" tabindex="-1" unselectable="on"> {{'PREVIOUS' | translate}}</a>
          </li>
          <li *ngIf="pages[0] >= (maxNumberOfPages - 1) / 2" class="page-item disabled">
            <a class="page-link page-number-link" unselectable="on" style="opacity: 1">...</a>
          </li>
          <li *ngFor="let page of pages" [ngClass]="{ 'page-item': true, active: currentPage == page }">
            <a class="page-link page-number-link" (click)="goToPage(page)" unselectable="on">{{ page }}</a>
          </li>
          <li *ngIf="currentPage + (maxNumberOfPages - 1) / 2 < totalPages && totalPages > maxNumberOfPages" class="page-item disabled">
            <a class="page-link page-number-link" unselectable="on" style="opacity: 1">...</a>
          </li>
          <li [ngClass]="{ 'page-item': true, disabled: currentPage == totalPages }">
            <a class="page-link page-text-link" (click)="movePage(true)" tabindex="-1" unselectable="on"> {{'NEXT' | translate}}</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
