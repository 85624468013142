<div class="container">
    <div *ngIf="shopPoint" class="container-shopPoint">
        <div class="header">
            <h1 class="title">{{ 'EDIT_POINT_OF_SALE' | translate }}</h1>
            <app-back-arrow-settings [callback]="setListingShopPoints"></app-back-arrow-settings>
        </div>
        <div class="form-card">
            <div class="loading" *ngIf="loading">
            <div class="spinner-border" role="status"></div>
        </div>
        <div *ngIf="!loading" class="form-group-container">
            <div class="row">
                <div class="col-12">
                    <label class="form-label" for="shopPoint_name">{{'NAME' | translate}}</label>
                    <input type="text" class="form-control" id="shopPointName"
                        placeholder="{{ 'POINT_OF_SALE_NAME' | translate }}"
                        (keyup)="changeShopPointName($event)" value="{{shopPoint.shopPointName}}" #shopPointName/>
                </div>
                <div class="col-10">
                    <label class="form-label" for="shopPoint_name">{{'PHONE' | translate}}</label>
                    <input type="text" class="form-control" id="shopPointPhone"
                        placeholder="{{ 'POINT_OF_SALE_PHONE' | translate }}"
                        (keyup)="changeShopPoinPhone($event)" value="{{shopPoint.shopPointPhone}}" #shopPointPhone/>
                </div>
                <div class="col-2">
                    <div><label class="form-label" for="shop_point_status">{{'STATUS' | translate}}</label></div>
                    <div class="switch">
                    <label class="switch-label">
                        <input type="checkbox"id="shop_point_status" name="status" [(ngModel)]="isActive"/>
                        <span class="slider round"></span>
                    </label>
                    </div>
                    <div><span id="statusText" class="status-text">{{ isActive ? ('ACTIVE' | translate) : ('INACTIVE' | translate) }}</span></div>
                </div>
                <div class="col-12">
                    <label class="form-label" for="shopPointLocation">{{'LOCATION' | translate}}</label>
                    <textarea class="form-control" id="shopPointLocation" placeholder="{{ 'POINT_OF_SALE_LOCATION' | translate }}"
                        (keyup)="changeShopPointLocation($event)" #shopPointLocation>{{shopPoint.shopPointLocation}}</textarea>
                    <div class="form-buttons">
                        <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
                        {{'SAVE' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="modal shopPoint-confirm-modal fade" id="successShopPointModal" tabindex="-1">
    <div class="modal-dialog shopPoint-modal-dialog" role="document">
      <div class="modal-content shopPoint-modal-content">
        <div class="modal-body shopPoint-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'POINT_OF_SALE_SAVED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal shopPoint-confirm-modal fade" id="errorShopPointModal" tabindex="-1">
    <div class="modal-dialog shopPoint-modal-dialog" role="document">
      <div class="modal-content shopPoint-modal-content">
        <div class="modal-body shopPoint-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
