import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-edit-subcategory',
  templateUrl: './edit-subcategory.component.html',
  styleUrls: ['./edit-subcategory.component.scss']
})
export class EditSubcategoryComponent implements OnInit {
  @Output() subCategoryUpdated = new EventEmitter<void>();

  subCategory: any;
  categories: any =[];
  loading: boolean = false;
  subCategoryId!: string;

  @Input()subCatId!: string;
  @Input()id!: string;

  @Input()categoryId!: string;
  subCategories: any =[];
  imageUrl: SafeUrl | null = null;
  
  constructor(
    private settingsService: SettingsService,
    private apiService: ApiService,
    private themingService: ThemingService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.subCategoryId = this.id;
    this.getEditingSubCategory();
    this.getSubCategoryById();
    this.getCategories();
  }

  onSwitchChange(event: any) {
    this.subCategory.isForVerifiedClient = event.checked ? 1 : 0;
  }
  
  getEditingSubCategory(){
    this.subCategoryId = this.settingsService.getEditingSubCategory();
  }

  setListingSubCategories(){
    this.settingsService.setListingSubCategories();
  }

  getSubCategoryByCategoryId(categoryId: number) {
    this.apiService
      .getSubCategoryByCategoryId( categoryId,this.themingService.shopId, )
      .then((data: any) => {
        this.subCategories = data.subCategories;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
  
      });
  }

  getSubCategoryById() {
    this.loading = true;
    this.apiService
    .getSubCategoryById(this.id, this.themingService.shopId)
    .then((data: any) => {
      this.subCategory = data.subCategory;
      this.getSubCategoryByCategoryId(parseInt(this.subCategory.categoryId));
      this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.subCategory.subCategoryImageUrl);
    })
    .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
    }
  
    triggerFileInput() {
      const fileInput = document.getElementById('optionImage') as HTMLInputElement;
      fileInput.click();
    }
  
    private resizeImage(image: HTMLImageElement, maxWidth: number, maxHeight: number): HTMLCanvasElement {
      const canvas = document.createElement('canvas');
      let width = image.width;
      let height = image.height;
  
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
  
      canvas.width = width;
      canvas.height = height;
  
      const context = canvas.getContext('2d');
      context?.drawImage(image, 0, 0, width, height);
  
      return canvas;
    }
  
    private convertCanvasToFile(canvas: HTMLCanvasElement, fileName: string): File {
      const dataURL = canvas.toDataURL('image/jpeg');
      const base64Data = dataURL.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
  
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
  
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
  
      const blob = new Blob(byteArrays, { type: 'image/jpeg' });
      return new File([blob], fileName);
    }
  
    async onImageChange(event: any) {
      const file: File = event.target.files[0];
      if (file) {
        const reader = new FileReader();
  
        reader.onload = async (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(image.src); 
          image.onload = async () => {
            const resizedImage = this.resizeImage(image,  1400, 250);
            const resizedFile = this.convertCanvasToFile(resizedImage, file.name);
  
            try {
              const imageData = await this.uploadImage(resizedFile);
              this.subCategory.subCategoryImageUrl = imageData.imageURL; 
            } catch (error) {
              console.error('Error uploading image:', error);
            }
          };
        };
        reader.readAsDataURL(file);
      }
    }
  
    removeImage() {
      this.imageUrl = null;
      this.subCategory.subCategoryImageUrl = ''; 
      const fileInput = document.getElementById('optionImage') as HTMLInputElement;
      fileInput.value = ''; 
    }
  
  
    uploadImage(image: File): Promise<any> {
      return this.blobFile(image)
        .then((res: any) => {
          const timestamp = Date.now();
          const ext = image.name.split('.').pop();
          const key = `${this.themingService.shopId}/${environment.stage}/images/subcategory/banner${timestamp}.${ext}`;
          return this.apiService.uploadFile(key, res.blob, { position: 1, type: 'original' })
            .then((data: any) => {
              return {
                imageURL: data.imageURL,
                blob: res.blob,
              };
            });
        });
    }
  
    blobFile = async ($event: any): Promise<any> => {
      try {
        const unSafeImg = window.URL.createObjectURL($event);
        const img = this.sanitizer.bypassSecurityTrustUrl(unSafeImg);
        const reader = new FileReader();
  
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            resolve({
              blob: $event,
              img,
              base: reader.result
            });
          };
          reader.onerror = (error) => {
            reject({
              blob: $event,
              img,
              base: null
            });
          };
          reader.readAsDataURL($event);
        });
      } catch (e) {
        return Promise.reject(null);
      }
    };
    
    getCategories() {
      this.loading = true;
      this.apiService
      .getCategories(this.themingService.shopId)
      .then((data: any) => {
        this.categories = data.categories;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
    }
    
    getCategoryNameById(categoryId: string): string {
      const category = this.categories.find((cat: any) => cat.categoryId === categoryId);
      return category ? category.categoryName : 'Nombre de la categoría no encontrado';
    }
    
    changeSubCategoryName(event: Event){
      const element = event.target as HTMLInputElement;
      this.subCategory.subCategoryName = element.value;
    }

    validateFormData() {
      this.loading = true; // Activar el modal de carga al inicio

      const categoryIdNumber: number = parseInt(this.categoryId, 10);
      this.apiService.updateSubCategory(
          parseInt(this.id),
          this.themingService.shopId,
          this.subCategory.subCategoryName,
          this.subCategory.subCategoryPosition,
          this.subCategory.menuSubCategory,
          this.subCategory.homeSubCategory,
          categoryIdNumber,
          this.subCategory.subCategoryDesignType,
          this.subCategory.subCategoryImageUrl,
          this.subCategory.isForVerifiedClient,
      )
      .then((data: any) => {
        setTimeout(() => {
          this.subCategoryUpdated.emit();
        }, 2000);
          if (data.status === 200) {
              $('#successSubCategoryModal').modal('show');
          } else {
              $('#errorSubCategoryModal').modal('show');
          }
      })
      .catch((error) => {
          console.error(error);
      })
      .finally(() => {
          this.loading = false; // Desactivar el modal de carga al finalizar la solicitud
      });
    }

    closeModal(){
      $('#successSubCategoryModal').modal('hide');
      $('#errorSubCategoryModal').modal('hide');
    }

    preventNegativeNumbers(event: KeyboardEvent) {
      if (event.key === '-' || event.key === '+') {
        event.preventDefault(); 
      }
    }

}
