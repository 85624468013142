import { Component, OnInit, ViewEncapsulation, Renderer2, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ClipboardService } from 'ngx-clipboard';
import { ActivatedRoute, Router } from '@angular/router';
import * as feather from 'feather-icons';
import { identity, throwError } from 'rxjs';
import { ThisReceiver } from '@angular/compiler';
import { ShopTheming, ThemingService } from 'src/app/theming.service';
import { Index } from 'aws-sdk/clients/resourceexplorer2';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-create-product',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
})
export class CreateProductComponent implements OnInit {
  inputsDisabled: boolean = false;
  displayItemsTable: boolean = false;
  isChecked = false;

  categorySelected: boolean = false;
  subCategorySelected: boolean = false;
  subSubCategorySelected: boolean = false;

  counterImage: number = 0;
  modules = {};
  edit = false;

  pdfFileName: string | null = null;
  pdfFile: File | null = null;
  productFile: string = '';
  pdfSrc: string | null = null;
  timestamp: number;

  images = <any[]>[];
  imagesToDelete: any[] = [];
  thumbnailImagesToDelete = <any[]>[];
  thumbnailsSizes = {
    thumbnail: 300,
    small: 400,
    medium: 600,
  };
  uploadingImages = false;
  loadingImages = false;

  productImages: any = [];
  productImagesToDelete: any = [];

  imageSizes: any = {
    thumbnail: 300,
    small: 400,
    medium: 600,
    main: undefined,
  };

  combinations = <any[]>[];
  combinationsForItems = <any[]>[];

  variantGroupCombination1 = <any[]>[];
  variantGroupCombination2 = <any[]>[];
  variantGroupCombination3 = <any[]>[];

  quantityContainer: any;
  counterVariableInputs1: number = 0;
  counterVariableInputs2: number = 0;
  counterVariableInputs3: number = 0;
  areVariablesChecked = false;
  hiddenVar1: boolean = true;
  hiddenVar2: boolean = true;
  hiddenVar3: boolean = true;

  inputModifyHidden = false;

  items = <any[]>[];
  itemsProduct = <any[]>[];
  categories: any[] = [];
  subCategories: any[] = [];
  subSubCategories: any[] = [];
  productVariant: any[] = [];
  taxSelected: any;
  taxActive: any;
  taxes: any[] = [];
  industryTypes: any[] = [];
  suppliers: any[] = [];
  collections: any[] = [];

  saveProduct = false;
  productType = 1;
  productBeneficiaries = 0;
  productHolder = 0;

  currency = "";
  productCode = '';
  sale = 0;

  product = {
    id: 0,
    code: '',
    categoryId: 0,
    subCategoryId: 0,
    subSubCategoryId: 0,
    industryTypeId: 0,
    supplierId: 0,
    collectionId: 0,
    taxId: 0,
    name: '',
    imageURL: '',
    description: '',
    baseCost: 0,
    sellPrice: 0,
    discountPrice: 0,
    status: 1,
    gainMargin: 0,
    revenue: 0,
    imgURL: 'X',
    imgPosition: 1,
    imgType: 'X',
    sessions: 0
  };

  variant1 = {
    type: '',
    value: '',
  };
  variant2 = {
    type: '',
    value: '',
  };
  variant3 = {
    type: '',
    value: '',
  };

  variantGroup1Values = <any[]>[];
  variantGroup2Values = <any[]>[];
  variantGroup3Values = <any[]>[];

  variantType1 = {
    variantTypeName: '',
    variantValues: <any[]>[],
  };
  variantType2 = {
    variantTypeName: '',
    variantValues: <any[]>[],
  };
  variantType3 = {
    variantTypeName: '',
    variantValues: <any[]>[],
  };
  loading: boolean = false
  shopPoints: any;
  activeShopPoint = 0;
  activeNoVariantsShopPoint = 0;

  public shopTheme: ShopTheming[] = [];
  constructor(
    public themingService: ThemingService,
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private clipboardApi: ClipboardService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    public el: ElementRef,
    private translate: TranslateService
  ) {
    this.timestamp = Date.now();
    this.shopTheme = this.themingService.shopTheming;
  }

  ngOnInit(): void {
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        //['blockquote', 'code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [
          {
            size: ['small', false, 'large', 'huge'],
            // size: [
            //   '8px',
            //   '10px',
            //   '12px',
            //   '14px',
            //   '16px',
            //   '18px',
            //   '20px',
            //   '22px',
            //   '24px',
            // ],
          },
        ], // custom dropdown

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        //['link', 'image', 'video'], // link and image, video
        ['emoji'],
      ],
    };
    this.saveProduct = false;
    this.shopPoints = this.themingService.shopTheming[0].shopsLocation;
    console.log("shopPoints: ",this.shopPoints);
    console.log("Theming: ",this.themingService.shopTheming);
    console.log(this.themingService.shopTheming[0].currencyCode)
    if(this.themingService.shopTheming[0].currencyCode != "CRC"){
      this.currency = "$";
    }else{
      this.currency = "₡";
    }
    this.getTaxes();
    this.getCategory();
    this.getSuppliers();
    this.getCollections();
    this.getIndustry();

    this.product.id = parseInt(
      <any>this.route.snapshot.paramMap.get('idProduct')
    );

    this.init();
    this.disabledInputs();
    $('#createProductModal').modal({ backdrop: 'static', keyboard: false });
    $('#createProductModal').modal('show');
  }

  ngAfterViewInit() {
    feather.replace();
  }

  async init() {
    await this.getProductById();
    $(function () {
      $('[data-toggle="popover"]').popover();
    });
  }

  hideBtn(className: string, counter: number, parentId: string) {
    const elements = document.getElementsByClassName(className);
    let btn: any;
    let i = -1;

    if (counter === 1) {
      do {
        i++;
        if (elements[i].parentElement?.id == parentId) {
          btn = elements[i] as HTMLButtonElement;
          btn.setAttribute('hidden', '');
        }
      } while (elements[i].parentElement?.id !== parentId);
    }
  }

  showBtn(className: string, counter: number, parentId: string) {
    const elements = document.getElementsByClassName(className);
    let btn: any;
    let i = -1;

    if (counter > 1) {
      do {
        i++;
        if (elements[i].parentElement?.id == parentId) {
          btn = elements[i] as HTMLButtonElement;
          btn.removeAttribute('hidden');
        }
      } while (elements[i].parentElement?.id !== parentId);
    }
  }

  disabledAddInputBtn(idBtn: string, counter: number) {
    const button = document.getElementById(idBtn) as HTMLButtonElement;
    if (counter === 10) {
      button.setAttribute('disabled', '');
    } else {
      button.removeAttribute('disabled');
    }
  }

  identifyCounter(idParent: string) {
    if (idParent === 'formVar1') {
      return this.counterVariableInputs1;
    } else if (idParent === 'formVar2') {
      return this.counterVariableInputs2;
    } else {
      return this.counterVariableInputs3;
    }
  }

  descriptionChanged(event: any) {
    console.log(event);
    if(event.event == 'text-change' ){
      this.product.description = encodeURI(event.html);
    }
  }

  addInput(idParent: string, idBtn: string, counter: number) {
    if (counter < 10 && this.areVariablesChecked == true) {
      const parent = document.getElementById(idParent);
      counter += 1;

      const input = document.createElement('input');
      const container = document.createElement('div');
      const element = document.querySelector('.container') as HTMLElement;

      const attrs = element.getAttributeNames().reduce((acc, name) => {
        return { ...acc, [name]: element.getAttribute(name) };
      }, {});

      // 👇️ {id: 'blue', 'data-id': 'example', class: 'box'}
      console.log(attrs);

      const emulateViewId = Object.keys(attrs)[0];

      console.log(emulateViewId);

      input.setAttribute(emulateViewId, '');
      input.setAttribute('type', 'text');
      input.setAttribute(
        'class',
        'form-control variant-value variantValueInput ' + idParent
      );
      input.setAttribute('placeholder', 'Ingrese el valor');
      input.setAttribute('value', '');
      input.setAttribute('id', 'product_variable_value' + counter + idParent);
      input.addEventListener('blur', (e: Event) => {
        const element = e.target as HTMLInputElement;
        if(element.value === ''){
          return;
        }
        if(container.parentElement?.childNodes[0]){
          const input = container.parentElement?.childNodes[0] as HTMLElement;
          if(input.querySelector('input')?.value == ''){
            return;
          }
        }
        this.createItemList();
      })
      container.setAttribute(emulateViewId, '');
      container.setAttribute(
        'id',
        'product_variable_container' + counter + idParent
      );
      container.className = 'value-container';
      container.style.display = "flex";
      container.style.justifyContent = "space-between";
      container.style.padding = "10px";
      parent?.appendChild(container);
      container.appendChild(input);

      this.addDeleteInput(
        idParent,
        container,
        'product_variable_value' + counter + idParent,
        counter,
        idBtn
      );
      this.disabledAddInputBtn(idBtn, counter);
    }
    return counter;
  }

  addDeleteInput(
    idParent: string,
    container: any,
    idInput: string,
    counter: number,
    addInputBtn: string
  ) {
    const parent = document.getElementById(idParent);
    const btn = document.createElement('button');
    const icon = document.createElement('i');
    const element = document.querySelector('.container') as HTMLElement;

    const attrs = element.getAttributeNames().reduce((acc, name) => {
      return { ...acc, [name]: element.getAttribute(name) };
    }, {});

    // 👇️ {id: 'blue', 'data-id': 'example', class: 'box'}
    console.log(attrs);

    const emulateViewId = Object.keys(attrs)[0];

    console.log(emulateViewId);

    btn.setAttribute(emulateViewId, '');
    btn.setAttribute('type', 'button');
    btn.className = 'btn btn-outline-secondary variantValueInput  delVariantBtn small-btn cancel-btn';
    btn.style.marginLeft = "5px"
    btn.style.borderRadius = '30px';
    icon.style.color = '#ffffff'
    btn.style.border = 'none';
    btn.style.width = '60px';
    icon.style.marginTop = '-3px';
    btn.style.height = '35px';
    btn.style.backgroundColor = '#D44646';
    btn.setAttribute('id', 'deleteVarBtn' + counter + idParent);
    btn.appendChild(icon);
    icon.setAttribute(emulateViewId, '');
    icon.setAttribute('data-feather', 'trash');

    icon.className = 'feather';

    btn.addEventListener('click', () => {
      this.deleteInput(
        idParent,
        container,
        idInput,
        'deleteVarBtn' + counter + idParent,
        addInputBtn
      );
    });
    container.appendChild(btn);
    feather.replace();
  }

  deleteInput(
    idParent: string,
    container: any,
    idInput: string,
    idBtn: string,
    addInputBtn: string
  ) {
    let counter = this.identifyCounter(idParent);
    if (counter > 0) {
      const parent = document.getElementById(idParent);
      const child = document.getElementById(idInput) as HTMLElement;
      const btn = document.getElementById(idBtn) as HTMLElement;

      container.removeChild(child);
      container.removeChild(btn);
      parent?.removeChild(container);
    }
    if (idParent === 'formVar1') {
      counter = this.counterVariableInputs1 -= 1;
    } else if (idParent === 'formVar2') {
      counter = this.counterVariableInputs2 -= 1;
    } else {
      counter = this.counterVariableInputs3 -= 1;
    }

    this.createItemList();

    this.hideBtn('delVariantBtn', counter, idParent);
    this.disabledAddInputBtn(addInputBtn, counter);
  }

  removeElementsByClass(
    className: string,
    counter: number,
    addInputBtn: string
  ) {
    const elements = document.getElementsByClassName(className);
    counter -= elements.length / 2;
    while (elements.length > 0) {
      elements[0].parentNode?.removeChild(elements[0]);
    }

    this.disabledAddInputBtn(addInputBtn, counter);

    return counter;
  }

  deletePhoto(i: number) {
    let image = this.productImages.small[i];
    for (const key of Object.keys(this.productImages)) {
      if (image.file == undefined) {
        this.productImagesToDelete.push(
          JSON.parse(JSON.stringify(this.productImages[key][i].imageURL))
        );
      }
      this.productImages[key].splice(i, 1);
    }
    console.log(this.productImagesToDelete);
  }

  getProductById() {
    if (this.product.id > 0) {
      this.loadingImages = true;
      this.apiService
        .getProductById(this.product.id, this.themingService.shopId)
        .then(async (data: any) => {
          this.product.name = data.product.name;
          this.product.sellPrice = data.product.price;
          this.product.discountPrice = data.product.discount_price;
          this.product.categoryId = data.product.category;
          this.product.code = data.product.code;
          this.product.description = data.product.description;
          this.thumbnailImagesToDelete = data.thumbnailUrls;
          // this.items = data.items;
          // this.quantitySize = data.variants;
          this.images = [];
          this.imagesToDelete = [];
          let tempImages: any[] = [];
          this.counterImage = (<any>data.items[0].images).length;
          console.log(this.counterImage);
          if (data.items[0]) {
            for (let i = 0; i < (<any>data.items[0].images).length; i++) {
              let image = data.items[0].images[i].url;
              if (image != '' && image && image) {
                if (image) {
                  let num = data.items[0].images[i].number;
                  this.imagesToDelete.push(image);
                  await fetch(image, {
                    //this.randomCacheID(image), {
                    method: 'GET',
                  })
                    .then((res) => {
                      return res.blob();
                    }) // Gets the response and returns it as a blob
                    .then((blob) => {
                      // Here's where you get access to the blob
                      // And you can use it for whatever you want
                      // Like calling ref().put(blob)

                      // Here, I use it to make an image appear on the page
                      let objectURL = URL.createObjectURL(blob);
                      (<any>blob).name = image.split('/').slice(-1)[0];
                      (<any>blob).lastModified = new Date();
                      let file = <File>blob;
                      tempImages.push({
                        url: this.sanitizer.bypassSecurityTrustResourceUrl(
                          objectURL
                        ),
                        urlString: image,
                        file: file,
                        number: num,
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }
            }
            this.images = tempImages;
          } else {
            this.images = [];
            this.imagesToDelete = [];
          }
          this.loadingImages = false; //change
        });
    } else {
      this.product.id = 0;
    }
  }

  getProduct(id: number) {
    this.loadingImages = true;
    this.apiService
      .getProductById(id, this.themingService.shopId)
      .then(async (data: any) => {
        this.thumbnailImagesToDelete = data.thumbnailUrls;
        this.itemsProduct = data.items;
        this.imagesToDelete = [];
        let tempImages: any[] = [];
        if (data.items[0]) {
          for (let i = 0; i < (<any>data.items[0].images).length; i++) {
            let image = data.items[0].images[i].url;
            if (image != '' && image && image) {
              if (image) {
                let num = data.items[0].images[i].number;
                this.imagesToDelete.push(image);
                await fetch(image, {
                  //this.randomCacheID(image), {
                  method: 'GET',
                })
                  .then((res) => {
                    return res.blob();
                  }) // Gets the response and returns it as a blob
                  .then((blob) => {
                    // Here's where you get access to the blob
                    // And you can use it for whatever you want
                    // Like calling ref().put
                    // Here, I use it to make an image appear on the page
                    let objectURL = URL.createObjectURL(blob);
                    (<any>blob).name = image.split('/').slice(-1)[0];
                    (<any>blob).lastModified = new Date();
                    let file = <File>blob;
                    tempImages.push({
                      url: this.sanitizer.bypassSecurityTrustResourceUrl(
                        objectURL
                      ),
                      urlString: image,
                      file: file,
                      number: num,
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
            }
          }
        } else {
          this.images = [];
          this.imagesToDelete = [];
        }
        this.loadingImages = false; //change
        this.saveImages();
      });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
        const file = input.files[0];

        if (file.type !== 'application/pdf') {
            alert('Por favor, selecciona un archivo PDF.');
            this.pdfFile = null;
            this.pdfFileName = null;
            this.pdfSrc = null;
            return;
        }

        const maxSizeInMB = 5;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            $('#errorProductModal').modal('show');
            this.pdfFile = null;
            this.pdfFileName = null;
            this.pdfSrc = null;
            return;
        }

        this.pdfFile = file;
        this.pdfFileName = file.name;
        const fileURL = URL.createObjectURL(file);
        this.pdfSrc = fileURL;
    }
}

    async savePDF() {
    try {
        if (this.pdfFile) {
            const pdf = this.pdfFile;
            const ext = pdf.name.split('.').pop();

            const filePath = `${this.shopTheme?.[0]?.shopId}/${environment.stage}/products-pdf/pdf${this.timestamp}.${ext}`;
            const data: any = await this.apiService.uploadFile(filePath, pdf, {});

            this.productFile = data.imageURL || '';
        } else {
            console.warn('No se seleccionó ningún archivo PDF.');
        }
    } catch (error) {
        console.error('Error al cargar el archivo PDF:', error);
    }
}

  openPdfInNewTab(): void {
    if (this.pdfSrc) {
      window.open(this.pdfSrc, '_blank');
    }
  }

  getCategory() {
    this.apiService
        .getCategories(this.themingService.shopId)
        .then((data: any) => {
            this.categories = data.categories;
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            $('#createProductModal').modal('hide');
        });
  }


  getSubCategoryByCategoryId(categoryId: number) {
    this.apiService
      .getSubCategoryByCategoryId( categoryId,this.themingService.shopId, )
      .then((data: any) => {
        console.log(data);
        this.subCategories = data.subCategories;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }

  getSubSubCategoryByCategoryId(subCategoryId: number) {
    console.log(subCategoryId);
    this.apiService
      .getSubSubCategoryByCategoryId( subCategoryId,this.themingService.shopId, )
      .then((data: any) => {
        console.log(data);
        this.subSubCategories = data.subSubCategories;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
      });
  }

  getTaxes() {
    this.apiService
      .getTaxes(this.themingService.shopId)
      .then((data: any) => {
        this.taxActive = data.find((tax: any) => tax.taxActive == "1");
        if(this.taxActive.taxCode == "0"){
          this.product.taxId = parseInt(this.taxActive.taxId);
        }
        this.taxes = (data.filter((taxes: any) => taxes.taxParent == this.taxActive.taxId));
        this.taxes = this.taxes.sort((a, b) => a.taxValue - b.taxValue);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getIndustry() {
    this.apiService
      .getIndustries()
      .then((data: any) => {
        this.industryTypes = data.industries;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getSuppliers() {
    this.apiService
      .getSuppliers(this.themingService.shopId)
      .then((data: any) => {
        this.suppliers = data.suppliers;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getCollections() {
    this.apiService
      .getCollections(this.themingService.shopId)
      .then((data: any) => {
        this.collections = data.collections;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  cleanAll() {
    this.product.id = 0;
    this.product.code = '';
    this.themingService.shopId = 0;
    this.product.categoryId = 0;
    this.product.industryTypeId = 0;
    this.product.supplierId = 0;
    this.product.collectionId = 0;
    this.product.taxId = 0;
    this.product.name = '';

    this.product.description = '';
    this.product.baseCost = 0;
    this.product.sellPrice = 0;
    this.product.discountPrice = 0;
    this.product.gainMargin = 0;
    this.product.revenue = 0;
    this.product.status = 1;

    this.counterImage = 0;
    this.images = <any[]>[];
    this.imagesToDelete = [];
    this.thumbnailImagesToDelete = <any[]>[];
  }

  changeCode(event: Event) {
    const element = event.target as HTMLInputElement;
    this.product.code = element.value;
  }

  changeCategory(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.categoryId = parseInt(element.value);
    this.categorySelected = true;
    this.subCategorySelected = false;
    this.subSubCategorySelected = false;
    this.getSubCategoryByCategoryId(this.product.categoryId)
  }

  changeSubCategory(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.subCategoryId = parseInt(element.value);
    this.subCategorySelected = true;
    this.subSubCategorySelected = false;
    this.getSubSubCategoryByCategoryId(this.product.subCategoryId)
  }

  changeSubSubCategory(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.subSubCategoryId = parseInt(element.value);
    this.subSubCategorySelected = true;
  }

  changeIndustry(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.industryTypeId = parseInt(element.value);
  }

  changeSupplier(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.supplierId = parseInt(element.value);
  }

  changeCollection(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.collectionId = parseInt(element.value);
  }

  changeTax(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.product.taxId = parseFloat(element.value);
    this.calculateFinalPrice();
  }

  changeName(event: Event) {
    const element = event.target as HTMLInputElement;
    this.product.name = element.value;
  }

  changeDescription(event: Event) {
    const element = event.target as HTMLTextAreaElement;
    this.product.description = element.value;
  }

  changeBaseCost(event: Event) {
    const element = event.target as HTMLInputElement;
    if(parseFloat(element.value) >= 0){
      this.product.baseCost = parseFloat(element.value);
    }else{
      this.product.baseCost = 0;
    }
    this.calculateFinalPrice();
  }

  changeProductType(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.productType = parseInt(element.value);
    this.activeNoVariantsShopPoint = 0;
    this.fillCombinationsArray();
  }

  changeSessions(event: Event){
    const element = event.target as HTMLInputElement;
    if(element.value == ""){
      this.product.sessions = 0;
    }else{
      this.product.sessions = parseInt(element.value);
    }
  }

  changeBeneficiaries(event: Event){
    const element = event.target as HTMLInputElement;
    if(element.value == ""){
      this.productBeneficiaries = 0;
    }else{
      this.productBeneficiaries = parseInt(element.value);
    }
  }

  changeHolder(event: Event){
    const element = event.target as HTMLInputElement;
    if(element.value == ""){
      this.productHolder = 0;
    }else{
      this.productHolder = parseInt(element.value);
    }
    console.log("holder value: ", element.value);
  }

  calculateFinalPrice(){
    this.taxSelected = this.taxes.find((taxValue: any) => taxValue.taxId == this.product.taxId);
    this.taxSelected.taxValue = parseFloat(this.taxSelected.taxValue);

    if(this.product.gainMargin > 0){
      let sellprice = 0;
      sellprice = this.product.baseCost + (this.product.baseCost * (this.product.gainMargin / 100));
      this.product.sellPrice =
      sellprice + (sellprice * (this.taxSelected.taxValue/100));
    }else{
      this.product.sellPrice = this.product.baseCost + (this.product.baseCost * (this.taxSelected.taxValue/100));
    }
  }

  changeSellPrice(event: Event) {
    const element = event.target as HTMLInputElement;
    if (parseFloat(element.value) >= 0) {
      this.product.sellPrice = parseFloat(element.value);
    } else {
      this.product.sellPrice = 0;
    }
  }

  changeDiscountPrice(event: Event) {
    const element = event.target as HTMLInputElement;
    this.product.discountPrice = parseFloat(element.value);
    return this.product.discountPrice;
  }

  changeGainMargin(event: Event) {
    const element = event.target as HTMLInputElement;
    if (parseFloat(element.value) > 0) {
      this.product.gainMargin = parseFloat(element.value);
      if(this.taxSelected){
        let sellprice = 0;
        sellprice = this.product.baseCost + (this.product.baseCost * (this.product.gainMargin / 100));
        this.product.sellPrice =
        sellprice + (sellprice * (this.taxSelected.taxValue/100));
      }else{
        this.product.sellPrice =
          this.product.baseCost +
          this.product.baseCost * (this.product.gainMargin / 100);
      }
    }else{
      this.product.sellPrice = this.product.baseCost
    }
  }

  revenueValue() {
    const taxPercentasje = this.taxSelected ? this.taxSelected.taxValue/100 : 1
    if(this.product.gainMargin > 0){
      let sellprice = 0;
      sellprice = this.product.baseCost + (this.product.baseCost * (this.product.gainMargin / 100));
      this.product.revenue = sellprice * taxPercentasje
    }else{
      this.product.revenue = this.product.baseCost * taxPercentasje
    }
    return this.product.revenue;
  }

  variablesHidden(event: Event) {
    const element = event.target as HTMLInputElement;
    this.quantityContainer = document.getElementById("container-quantity") as HTMLElement;
    this.areVariablesChecked = element.checked;
    if (this.areVariablesChecked == true) {
      this.hiddenVar1 = !this.areVariablesChecked;
      this.quantityContainer.classList.add("visually-hidden");
    } else {
      this.hiddenVar1 = !this.areVariablesChecked;
      this.hiddenVar2 = !this.areVariablesChecked;
      this.hiddenVar3 = !this.areVariablesChecked;
      this.quantityContainer.classList.remove("visually-hidden");
    }

    this.displayItemsTable = false;
  }

  removeImage(image: any) {
    this.images.splice(this.images.indexOf(image), 1);
    this.counterImage = this.counterImage - 1;
  }

  downloadImage(position: number) {
    fetch(this.productImages.main[position].imageURL)
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.download = this.product.code + '-' + (position + 1) + '.png';
        a.href = blobUrl;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    // const link = document.createElement('a');
    // let url = this.getFileLocation(this.productImages.main[position].imageURL);
    // link.href = url.origin + url.pathname;
    // link.download =
    //   this.product.product.productCode + '-' + (position + 1) + '.png';
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  async loadNewImages(
    code: string,
    imagesToDelete: any[],
    newImages: any[],
    thumbnailImagesToDelete: any[]
  ) {
    // this.uploadingImages = true;
    console.log('|||||||||||||');
    console.log(imagesToDelete);
    console.log('-----------');
    for (let url of imagesToDelete.concat(thumbnailImagesToDelete)) {
      if (url != '' && url) {
        console.log(url);

        await this.apiService.deleteFile(
          url.replace('https://mobydyg-files.s3.amazonaws.com/', '')
        );
      }
    }

    newImages.sort((a, b) => {
      return a.number - b.number;
    });

    let urls = <any[]>[];
    let thumbnailsUrls = <any[]>[];
    let timestamp = Date.now();
    console.log(timestamp);
    for (let i = 0; i < newImages.length; i++) {
      let image = newImages[i];
      console.log(image);
      await this.apiService
        .uploadFile(
          this.themingService.shopId +
            '/item_images/' +
            environment.stage +
            '/' +
            code +
            '-' +
            image.number +
            '-' +
            timestamp +
            '.' +
            image.file.name.split('.').slice(-1)[0],
          image.file,
          {}
        )
        .then((url: any) => {
          urls.push(url);
        });

      //Thumbnails
      for (const [type, size] of Object.entries(this.thumbnailsSizes)) {
        var fileReader = new FileReader();
        var aux_this = this;
        fileReader.readAsDataURL(image.file);
        await new Promise((resolve, reject) => {
          fileReader.onload = () => {
            let img = new Image();
            img.src = <string>fileReader.result;
            var c = document.createElement('canvas');
            var blob;

            img.onload = async function () {
              c.width = size;
              c.height = c.width;

              let newWidth = img.width;
              let newHeight = img.height;
              if (img.width > img.height) {
                if (img.width > c.width) {
                  newHeight *= c.width / newWidth;
                  c.height = newHeight;
                  newWidth = c.width;
                }
              } else {
                if (img.height > c.height) {
                  newWidth *= c.height / newHeight;
                  c.width = newWidth;
                  newHeight = c.height;
                }
              }
              var ctx = c.getContext('2d');
              (<any>ctx).drawImage(img, 0, 0, newWidth, newHeight); // draw in image
              c.toBlob(
                async function (blob0) {
                  blob = blob0;
                  (<any>blob).name = image.file.name.split('/').slice(-1)[0];
                  (<any>blob).lastModified = new Date();
                  let file = <File>blob;

                  await aux_this.apiService
                    .uploadFile(
                      aux_this.themingService.shopId +
                        '/item_images/' +
                        environment.stage +
                        '/' +
                        code +
                        '-' +
                        type +
                        '-' +
                        (i + 1).toString() +
                        '-' +
                        timestamp +
                        '.' +
                        file.name.split('.').slice(-1)[0],
                      file,
                      {}
                    )
                    .then((url: any) => {
                      thumbnailsUrls.push([url, type]);
                      resolve({});
                    })
                    .catch((error: any) => {
                      console.error(error);
                      reject(error);
                    });
                },
                'image/png',
                1
              );
            };
          };
        });
      }
    }

    this.imagesToDelete = urls;
    this.thumbnailImagesToDelete = thumbnailsUrls;

    let idItems = this.items.map((item: any) => {
      return { id_item: item.id_item, urls: urls, thumbnails: thumbnailsUrls };
    });

    console.log(idItems);
    this.apiService
      .updateItemsImages(idItems)
      .then((response: any) => {
        console.log(response);
        // this.getProduct();
        this.uploadingImages = false;
      })
      .catch((error: any) => {
        this.uploadingImages = false;
        console.error(error);
      });
  }

  saveImages() {
    let images = this.images.map((image, i) => {
      image.number = i + 1;
      return image;
    });

    this.loadNewImages(
      this.productCode,
      this.imagesToDelete,
      images,
      this.thumbnailImagesToDelete
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    for (const key of Object.keys(this.imageSizes)) {
      moveItemInArray(
        this.productImages[key],
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  getFileLocation = function (href: string) {
    var l = document.createElement('a');
    l.href = href;
    return l;
  };

  copyUrl(e: any, id: string) {
    $('#' + id).popover('show');
    this.clipboardApi.copyFromContent(e);
    setTimeout(() => {
      $('#' + id).popover('hide');
    }, 1000);
  }

  async uploadImage() {
    var inputFile = $(
      '<input type="file" id="fileInput" multiple="true" style="visibility: hidden; height: 0; width: 0; overflow: hidden" />'
    );
    let aux_this = this;
    inputFile.bind('change', (event: any) => {
      aux_this.readFile(event.target.files);
    });
    inputFile.trigger('click');
  }

  async readFile(files: any) {
    let aux_this = this;
    for (let i = 0; i < (<any>files).length; i++) {
      var promise = new Promise(async (resolve, reject) => {
        let timestamp = Date.now().toString() + '-' + (i + 1);
        var reader = new FileReader();
        reader.readAsDataURL((<any>files)[i]); // read file as data url
        reader.onload = async () => {
          // called once readAsDataURL is completed
          if (reader.result != null) {
            var blob = (<any>files)[i].slice(
              0,
              (<any>files)[i].size,
              'image/png'
            );
            let mainFile = new File([blob], timestamp + '-main', {
              type: 'image/png',
            });
            aux_this.productImages.main = aux_this.productImages.main || [];
            aux_this.productImages.main.push({
              imageURL: this.sanitizer.bypassSecurityTrustResourceUrl(
                <any>reader.result
              ),
              file: mainFile,
            });

            let promises = [];

            for (const key of Object.keys(aux_this.imageSizes)) {
              promises.push(
                new Promise((resolve2, reject2) => {
                  if (aux_this.imageSizes[key] != undefined) {
                    let img = new Image();
                    img.src = <string>reader.result;
                    var c = document.createElement('canvas');
                    var blob;

                    img.onload = async function () {
                      c.width = aux_this.imageSizes[key];
                      c.height = c.width;

                      let newWidth = img.width;
                      let newHeight = img.height;
                      if (img.width > img.height) {
                        if (img.width > c.width) {
                          newHeight *= c.width / newWidth;
                          c.height = newHeight;
                          newWidth = c.width;
                        }
                      } else {
                        if (img.height > c.height) {
                          newWidth *= c.height / newHeight;
                          c.width = newWidth;
                          newHeight = c.height;
                        }
                      }
                      var ctx = c.getContext('2d');
                      (<any>ctx).drawImage(img, 0, 0, newWidth, newHeight); // draw in image
                      c.toBlob(
                        async function (blob0) {
                          blob = blob0;
                          (<any>blob).name = timestamp + '-' + key;
                          (<any>blob).lastModified = new Date();
                          let file = <File>blob;
                          var reader2 = new FileReader();
                          reader2.readAsDataURL(file); // read file as data url
                          reader2.onload = () => {
                            aux_this.productImages[key] =
                              aux_this.productImages[key] || [];
                            aux_this.productImages[key].push({
                              imageURL:
                                aux_this.sanitizer.bypassSecurityTrustResourceUrl(
                                  <any>reader2.result
                                ),
                              file: file,
                            });
                            resolve2(0);
                          };
                        },
                        'image/png',
                        1
                      );
                    };
                  } else {
                    resolve2(0);
                  }
                })
              );
            }
            await Promise.all(promises);
            resolve(0);
          } else {
            reject();
          }
        };
      });
    }
  }

  throttleActions(listOfCallableActions: any[], limit: number) {
    // We'll need to store which is the next promise in the list.
    let i = 0;
    let resultArray = new Array(listOfCallableActions.length);

    // Now define what happens when any of the actions completes. Javascript is
    // (mostly) single-threaded, so only one completion handler will call at a
    // given time. Because we return doNextAction, the Promise chain continues as
    // long as there's an action left in the list.
    function doNextAction(): any {
      if (i < listOfCallableActions.length) {
        // Save the current value of i, so we can put the result in the right place
        let actionIndex = i++;
        let nextAction = listOfCallableActions[actionIndex];
        return Promise.resolve(nextAction())
          .then((result) => {
            // Save results to the correct array index.
            resultArray[actionIndex] = result;
            return;
          })
          .then(doNextAction);
      }
    }

    // Now start up the original <limit> number of promises.
    // i advances in calls to doNextAction.
    let listOfPromises = [];
    while (i < limit && i < listOfCallableActions.length) {
      listOfPromises.push(doNextAction());
    }
    return Promise.all(listOfPromises).then(() => resultArray);
  }

  showVariables() {
    let counter = 0;
    let parentId = '';
    if (this.hiddenVar1 === false) {
      if (this.hiddenVar2 === false) {
        this.hiddenVar3 = false;
        parentId = 'formVar3';
        counter = this.addInput(
          'formVar3',
          'valBtn3',
          this.counterVariableInputs3
        );
        this.counterVariableInputs3 = counter;
      } else if (this.hiddenVar3 === false) {
        this.hiddenVar2 = false;
        parentId = 'formVar2';
        counter = this.addInput(
          'formVar2',
          'valBtn2',
          this.counterVariableInputs2
        );
        this.counterVariableInputs2 = counter;
      } else {
        this.hiddenVar2 = false;
        parentId = 'formVar2';
        counter = this.addInput(
          'formVar2',
          'valBtn2',
          this.counterVariableInputs2
        );
        this.counterVariableInputs2 = counter;
      }
    } else {
      this.hiddenVar1 = false;
      parentId = 'formVar1';
      counter = this.addInput(
        'formVar1',
        'valBtn1',
        this.counterVariableInputs1
      );
      this.counterVariableInputs1 = counter;
    }
    this.hideBtn('delVariantBtn', counter, parentId);
  }

  async createProduct() {
    $('#createProductModal').modal({ backdrop: 'static', keyboard: false });
    $('#createProductModal').modal('show');

    let variants: any[] = [];
    this.itemsProduct = [];
    this.fillItemsArray();

    if (this.variantType1.variantTypeName) {
        variants.push(this.variantType1);
    }
    if (this.variantType2.variantTypeName) {
        variants.push(this.variantType2);
    }
    if (this.variantType3.variantTypeName) {
        variants.push(this.variantType3);
    }

    console.log(variants);
    console.log('ITEMS: ', this.itemsProduct);

    let quantityIsValid = true;
    if (
        variants.length === 0 &&
        (!this.itemsProduct[0]?.itemShopPoints[0]?.itemQuantity)
    ) {
        quantityIsValid = false;
    }

    let productSessions = this.productType === 1 || this.productType === 3 ||
        (this.productType === 2 && this.product.sessions >= 0);

    console.log(
        this.product.categoryId,
        this.product.industryTypeId,
        this.product.code,
        this.product.taxId,
        this.product.name,
        quantityIsValid,
        this.product.sellPrice,
    );

    if (
        !this.product.categoryId ||
        !this.product.industryTypeId ||
        !this.product.code ||
        !this.product.taxId ||
        !this.product.name ||
        !quantityIsValid ||
        !this.product.sellPrice ||
        !productSessions
    ) {
        $('#createProductModal').modal('hide');
        $('#createValidationModal').modal('show');
        console.log('Required Fields Empty');
        return;
    }

    try {
        if (this.pdfFile) {
            console.log('Subiendo archivo PDF...');
            await this.savePDF();
            console.log('Archivo PDF guardado exitosamente:', this.productFile);
        }

        this.saveProduct = false;

        const data: any = await this.apiService.createProduct(
            this.product.code,
            this.themingService.shopId,
            this.product.categoryId,
            this.product.subCategoryId,
            this.product.subSubCategoryId,
            this.product.industryTypeId,
            this.product.supplierId ?? 0,
            this.product.collectionId ?? 0,
            this.product.taxId,
            this.product.name,
            this.product.description,
            this.product.baseCost,
            this.product.sellPrice,
            this.product.discountPrice > 0 ? this.product.discountPrice : 0,
            new Date().toLocaleString('en-US', {
                timeZone: 'America/Costa_Rica',
            }),
            this.product.status,
            this.itemsProduct,
            variants,
            this.product.imgURL,
            this.product.imgPosition,
            this.product.imgType,
            this.productType,
            this.product.sessions,
            this.productFile
        );

        console.log("Success:", data);
        if (data.statusCode === 200) {
            this.saveProduct = true;
        } else {
            $('#errorCreateProductModal').modal('show');
            console.error("Error. Status Code:", data.statusCode);
        }
    } catch (error) {
        $('#errorCreateProductModal').modal('show');
        console.error("Error:", error);
    } finally {
        $('#createProductModal').modal('hide');
    }
}

  variableButtonDisabled() {
    let buttonDisabled: boolean = false;
    if (
      this.hiddenVar1 === false &&
      this.hiddenVar2 === false &&
      this.hiddenVar3 === false
    ) {
      buttonDisabled = true;
    }
    return buttonDisabled;
  }

  areVariablesActive() {
    if (
      this.hiddenVar1 === true &&
      this.hiddenVar2 === true &&
      this.hiddenVar3 === true
    ) {
      this.areVariablesChecked = false;
      this.displayItemsTable = false;
    }
  }

  varBlock1() {
    this.hiddenVar1 = true;
    document.querySelectorAll('.variantValueInput');

    this.areVariablesActive();
  }

  varBlock2() {
    this.hiddenVar2 = true;

    this.areVariablesActive();
  }

  varBlock3() {
    this.hiddenVar3 = true;

    this.areVariablesActive();
  }

  disabledInputs() {
    if (this.product.id !== 0) {
      this.inputsDisabled = true;
    } else {
      this.inputsDisabled = false;
    }
  }

  enableInputs() {
    this.inputsDisabled = false;
    this.inputModifyHidden = true;
  }

  enableItemTable() {
    this.displayItemsTable = true;
  }

  determineCombinationsLength() {
    if (this.counterVariableInputs1 > 0) {
      if (this.counterVariableInputs2 > 0 && this.counterVariableInputs3 > 0) {
        this.combinations.length =
          this.counterVariableInputs1 *
          this.counterVariableInputs2 *
          this.counterVariableInputs3;
      } else if (this.counterVariableInputs2 > 0) {
        this.combinations.length =
          this.counterVariableInputs1 * this.counterVariableInputs2;
      } else if (this.counterVariableInputs3 > 0) {
        this.combinations.length =
          this.counterVariableInputs1 * this.counterVariableInputs3;
      } else {
        this.combinations.length = this.counterVariableInputs1;
      }
    } else if (this.counterVariableInputs2 > 0) {
      if (this.counterVariableInputs3 > 0) {
        this.combinations.length =
          this.counterVariableInputs2 * this.counterVariableInputs3;
      } else {
        this.combinations.length = this.counterVariableInputs2;
      }
    } else {
      this.combinations.length = this.counterVariableInputs3;
    }
  }

  fillVariantArrays() {
    this.variantGroupCombination1.length = 0;
    this.variantGroupCombination2.length = 0;
    this.variantGroupCombination3.length = 0;

    if (this.counterVariableInputs1 > 0) {
      const elements = document.getElementsByClassName('formVar1');
      const typeElement = document.getElementById(
        'variantTypes1'
      ) as HTMLInputElement;

      this.variantType1.variantTypeName = typeElement.value;

      for (let i = 0; i < this.counterVariableInputs1; i++) {
        let input = elements[i] as HTMLInputElement;

        this.variantGroup1Values.push({
          variantValueName: input.value,
          variantValuePosition: i,
        });
      }

      this.variantType1.variantValues = this.variantGroup1Values;
    }

    if (this.counterVariableInputs2 > 0) {
      const elements = document.getElementsByClassName('formVar2');
      const typeElement = document.getElementById(
        'variantTypes2'
      ) as HTMLInputElement;

      this.variantType2.variantTypeName = typeElement.value;

      for (let i = 0; i < this.counterVariableInputs2; i++) {
        let input = elements[i] as HTMLInputElement;

        this.variantGroup2Values.push({
          variantValueName: input.value,
          variantValuePosition: i,
        });
      }

      this.variantType2.variantValues = this.variantGroup2Values;
    }

    if (this.counterVariableInputs3 > 0) {
      const elements = document.getElementsByClassName('formVar3');
      const typeElement = document.getElementById(
        'variantTypes3'
      ) as HTMLInputElement;

      this.variantType3.variantTypeName = typeElement.value;

      for (let i = 0; i < this.counterVariableInputs3; i++) {
        let input = elements[i] as HTMLInputElement;

        this.variantGroup3Values.push({
          variantValueName: input.value,
          variantValuePosition: i,
        });
      }

      this.variantType3.variantValues = this.variantGroup3Values;
    }
  }

  fillVariantArraysToGenerateItems() {
    if (this.counterVariableInputs1 > 0) {
      const elements = document.getElementsByClassName('formVar1');
      const typeElement = document.getElementById('variantTypes1');

      for (let i = 0; i < this.counterVariableInputs1; i++) {
        let input = elements[i] as HTMLInputElement;
        let typeInput = typeElement as HTMLInputElement;

        console.log(typeInput.value);

        this.variantGroupCombination1.push({
          type: typeInput.value,
          value: input.value,
        });
      }
    }

    if (this.counterVariableInputs2 > 0) {
      const elements = document.getElementsByClassName('formVar2');
      const typeElement = document.getElementById('variantTypes2');

      for (let i = 0; i < this.counterVariableInputs2; i++) {
        let input = elements[i] as HTMLInputElement;
        let typeInput = typeElement as HTMLInputElement;

        this.variantGroupCombination2.push({
          type: typeInput.value,
          value: input.value,
        });
      }
    }

    if (this.counterVariableInputs3 > 0) {
      const elements = document.getElementsByClassName('formVar3');
      const typeElement = document.getElementById('variantTypes3');

      for (let i = 0; i < this.counterVariableInputs3; i++) {
        let input = elements[i] as HTMLInputElement;
        let typeInput = typeElement as HTMLInputElement;

        this.variantGroupCombination3.push({
          type: typeInput.value,
          value: input.value,
        });
      }
    }

    this.fillCombinationsArray();
  }

  fillCombinationsArray() {
    let currentCombinations = <any[]>[];

    if (
      this.hiddenVar1 === false &&
      this.hiddenVar2 === false &&
      this.hiddenVar3 === false
    ) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          for (let k = 0; k < this.variantGroupCombination3.length; k++) {
            currentCombinations[r] =
              this.variantGroupCombination1[i].value +
              this.variantGroupCombination2[j].value +
              this.variantGroupCombination3[k].value;
            r++;
          }
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          currentCombinations[r] =
            this.variantGroupCombination1[i].value +
            this.variantGroupCombination2[j].value;
          r++;
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          currentCombinations[r] =
            this.variantGroupCombination1[i].value +
            this.variantGroupCombination3[j].value;
          r++;
        }
      }
    } else if (this.hiddenVar2 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          currentCombinations[r] =
            this.variantGroupCombination2[i].value +
            this.variantGroupCombination3[j].value;
          r++;
        }
      }
    } else if (this.hiddenVar1 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        currentCombinations[r] = this.variantGroupCombination1[i].value;
        r++;
      }
    } else if (this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        currentCombinations[r] = this.variantGroupCombination2[i].value;
        r++;
      }
    } else {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination3.length; i++) {
        currentCombinations[r] = this.variantGroupCombination3[i].value;
        r++;
      }
    }

    this.combinations = currentCombinations;

    console.log(this.combinations);
    this.generateCombinationsList(this.combinations);
    this.fillCombinationsArrayForItemValidations();
  }

  fillCombinationsArrayForItemValidations() {
    let combinationsForItems = <any[]>[];

    if (
      this.hiddenVar1 === false &&
      this.hiddenVar2 === false &&
      this.hiddenVar3 === false
    ) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          for (let k = 0; k < this.variantGroupCombination3.length; k++) {
            combinationsForItems[r] = this.combinationsForItems.push({
              variant1: this.variantGroupCombination1[i].value,
              variant2: this.variantGroupCombination2[j].value,
              variant3: this.variantGroupCombination3[k].value,
            });
            r++;
          }
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination2.length; j++) {
          combinationsForItems[r] = this.combinationsForItems.push({
            variant1: this.variantGroupCombination1[i].value,
            variant2: this.variantGroupCombination2[j].value,
            variant3: null,
          });
          r++;
        }
      }
    } else if (this.hiddenVar1 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          combinationsForItems[r] = this.combinationsForItems.push({
            variant1: this.variantGroupCombination1[i].value,
            variant2: null,
            variant3: this.variantGroupCombination3[j].value,
          });
          r++;
        }
      }
    } else if (this.hiddenVar2 === false && this.hiddenVar3 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        for (let j = 0; j < this.variantGroupCombination3.length; j++) {
          combinationsForItems[r] = this.combinationsForItems.push({
            variant1: null,
            variant2: this.variantGroupCombination2[i].value,
            variant3: this.variantGroupCombination3[j].value,
          });
          r++;
        }
      }
    } else if (this.hiddenVar1 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination1.length; i++) {
        combinationsForItems[r] = this.combinationsForItems.push({
          variant1: this.variantGroupCombination1[i].value,
          variant2: null,
          variant3: null,
        });
        r++;
      }
    } else if (this.hiddenVar2 === false) {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination2.length; i++) {
        combinationsForItems[r] = this.combinationsForItems.push({
          variant1: null,
          variant2: this.variantGroupCombination2[i].value,
          variant3: null,
        });
        r++;
      }
    } else {
      let r = 0;
      for (let i = 0; i < this.variantGroupCombination3.length; i++) {
        combinationsForItems[r] = this.combinationsForItems.push({
          variant1: null,
          variant2: null,
          variant3: this.variantGroupCombination3[i].value,
        });
        r++;
      }
    }

    console.log('Items combination', this.combinationsForItems);
  }

  onUnlimitedChange(event: Event){
    const element = event.target as HTMLInputElement
    const shopPointId = element.getAttribute('data-shopPointId');
    const input = this.el.nativeElement.querySelector(`.no-variant-quantity[data-shopPointId="${shopPointId}"]`);
    if(input){
      if(parseInt(element.value) == 0){
        input.disabled = false;
        input.value = 0;
      }else if(parseInt(element.value) == 1){
        input.disabled = true;
        input.value = 0;
      }
    }
  }

  generateCombinationsList(currentCombinations: any[]) {
    let listContainer: any;
    listContainer = this.el.nativeElement.querySelector('#itemCombinationList')
    $(listContainer).empty();

    let item = this.renderer.createElement("div");

    for (let i = 0; i < currentCombinations.length; i++) {

      //Contenedores
      const container = this.renderer.createElement("div");
      container.className = 'container-fluid item';

      const dataRow = this.renderer.createElement("div");
      dataRow.className = 'row';

      const colItem = this.renderer.createElement('div');
      const colHolder = this.renderer.createElement('div');
      const colCode = this.renderer.createElement('div');
      const colPrice = this.renderer.createElement('div');
      const colDiscount = this.renderer.createElement('div');
      const colBeneficiaries = this.renderer.createElement('div');
      const colQuantity = this.renderer.createElement('div');
      let labelsClass = this.productType == 1? '': '';

      if(this.productType == 3){
        colItem.className = 'col-md-9';
        colHolder.className = 'form-check form-switch col-md-3';
        colCode.className = 'form-floating col-md-3';
        colPrice.className = 'form-floating col-md-2';
        colDiscount.className = 'form-floating col-md-3';
        colBeneficiaries.className = 'form-floating col-md-2';
        colQuantity.className = 'form-floating col-md-2';
      }
      else if(this.productType == 1){

        colItem.className = 'col-md-12 d-flex flex-column justify-content-center align-items-start';
        colHolder.className = 'visually-hidden';
        colCode.className = 'form-floating col-md-3';
        colPrice.className = 'form-floating col-md-3';
        colDiscount.className = 'form-floating col-md-3';
        colBeneficiaries.className = 'visually-hidden';
        colQuantity.className = 'form-floating col-md-3';
      }


      const inputspan = this.renderer.createElement('h4');
      const inputspanText = this.renderer.createText(currentCombinations[i]);
      inputspan.className = 'subtitle';
      this.renderer.appendChild(inputspan, inputspanText);

      // this.renderer.appendChild(colItem, labelItem);
      this.renderer.appendChild(colItem, inputspan);

      // Holder Checkbox
      if(this.productType == 3){
        const inputHolder = this.renderer.createElement('input');
        inputHolder.className = 'form-check-input';
        inputHolder.id = 'holder' + i;
        this.renderer.setAttribute(inputHolder, 'type', 'checkbox');

        const labelHolder = this.renderer.createElement('label');
        const labelHolderText = this.renderer.createText('Titular Obligatorio');
        labelHolder.className = "form-check-label";
        this.renderer.appendChild(labelHolder, labelHolderText);

        this.renderer.appendChild(colHolder, inputHolder);
        this.renderer.appendChild(colHolder, labelHolder);
      }

      //Code Input
      const labelCode = this.renderer.createElement('label');
      const labelCodeText = this.renderer.createText('Código');
      labelCode.className = labelsClass;
      this.renderer.appendChild(labelCode, labelCodeText);

      const inputCode = this.renderer.createElement('input');
      inputCode.className = 'form-control';
      inputCode.value = this.product.code + i;
      this.renderer.setAttribute(inputCode, 'type', 'text');

      this.renderer.appendChild(colCode, inputCode);
      this.renderer.appendChild(colCode, labelCode);

      //Beneficiaries Input
      if(this.productType == 3){
        const labelBeneficiaries = this.renderer.createElement('label');
        const labelBeneficiariesText = this.renderer.createText('Beneficiarios');

        this.renderer.appendChild(labelBeneficiaries, labelBeneficiariesText);

        const inputBeneficiaries = this.renderer.createElement('input');
        inputBeneficiaries.className = 'form-control';
        inputBeneficiaries.id = 'beneficiariy' + i;
        inputBeneficiaries.value = this.productBeneficiaries;
        this.renderer.setAttribute(inputBeneficiaries, 'type', 'number');

        this.renderer.appendChild(colBeneficiaries, inputBeneficiaries);
        this.renderer.appendChild(colBeneficiaries, labelBeneficiaries);
      }

      //Price Input
      const labelPrice = this.renderer.createElement('label');
      const labelPriceText = this.renderer.createText('Precio');
      labelPrice.className = labelsClass;
      this.renderer.appendChild(labelPrice, labelPriceText);

      const inputPrice = this.renderer.createElement('input');
      inputPrice.className = 'form-control';
      inputPrice.id = 'price' + i;
      inputPrice.value = this.product.sellPrice.toString();
      this.renderer.setAttribute(inputPrice, 'type', 'number');

      this.renderer.appendChild(colPrice, inputPrice);
      this.renderer.appendChild(colPrice, labelPrice);

      //Discount Price Input
      const labelDiscountPrice = this.renderer.createElement('label');
      const labelDiscountPriceText = this.renderer.createText('Precio Descuento');
      labelDiscountPrice.className = labelsClass;
      this.renderer.appendChild(labelDiscountPrice, labelDiscountPriceText);

      const inputDiscountPrice = this.renderer.createElement('input');
      inputDiscountPrice.className = 'form-control';
      inputDiscountPrice.id = 'discountPrice' + i;
      inputDiscountPrice.value = this.product.discountPrice.toString();
      this.renderer.setAttribute(inputDiscountPrice, 'type', 'number');

      this.renderer.appendChild(colDiscount, inputDiscountPrice);
      this.renderer.appendChild(colDiscount, labelDiscountPrice);

      //Quantity Price Input
      const labelQuantity = this.renderer.createElement('label');
      const labelQuantityText = this.renderer.createText('Cantidad');
      labelQuantity.className = labelsClass;
      this.renderer.appendChild(labelQuantity, labelQuantityText);

      // const inputQuantityPrice = this.renderer.createElement('input');
      // inputQuantityPrice.className = 'form-control';
      // inputQuantityPrice.id = 'quantity' + i;
      // inputQuantityPrice.value = this.product.discountPrice.toString();
      // this.renderer.setAttribute(inputQuantityPrice, 'type', 'number');

      // this.renderer.appendChild(colQuantity, labelQuantity);
      // this.renderer.appendChild(colQuantity, inputQuantityPrice);

      const cloneColQuantity = colQuantity.cloneNode(true);
      //Sucursales
      this.shopPoints.forEach((shopPoint: any, index: any) => {
        // const cloneColQuantity = colQuantity.cloneNode(true);
        console.log("index: ",index)
        if(index == 0){
          // const labelQuantity = this.renderer.createElement('label');
          // const labelQuantityText = this.renderer.createText('Cantidad');
          // labelQuantity.className = 'form-label';
          // this.renderer.appendChild(labelQuantity, labelQuantityText);

          const quantityInput = this.renderer.createElement('input');
          quantityInput.className = this.activeShopPoint==0? 'form-control quantityInput quantityMain' : 'form-control quantityInput visually-hidden quantityMain';
          quantityInput.value = 0;
          this.renderer.setAttribute(quantityInput, 'disabled', 'true');
          this.renderer.setAttribute(quantityInput, 'data-shopPoint', '0');
          this.renderer.setAttribute(quantityInput, 'data-item', i.toString());


          // this.renderer.appendChild(cloneColQuantity, labelQuantity);
          this.renderer.appendChild(cloneColQuantity, quantityInput);
        }
        const quantityInput = this.renderer.createElement('input');
        quantityInput.className = this.activeShopPoint==shopPoint.shopPointId? 'form-control quantityInputs' : 'form-control quantityInput visually-hidden';
        quantityInput.id = 'quantity' + index;
        quantityInput.value = 0;
        this.renderer.setAttribute(quantityInput, 'type', 'number');
        this.renderer.setAttribute(quantityInput, 'data-shopPoint', shopPoint.shopPointId);
        this.renderer.setAttribute(quantityInput, 'data-item', i.toString());


        this.renderer.appendChild(cloneColQuantity, quantityInput);

      });
      this.renderer.appendChild(cloneColQuantity, labelQuantity);

      this.renderer.appendChild(dataRow, colItem);
      this.renderer.appendChild(dataRow, colHolder);
      this.renderer.appendChild(dataRow, colCode);


      this.renderer.appendChild(dataRow, colPrice);
      this.renderer.appendChild(dataRow, colDiscount);
      this.renderer.appendChild(dataRow, colBeneficiaries);
      this.renderer.appendChild(dataRow, cloneColQuantity);

      this.renderer.appendChild(container, dataRow);
      // this.renderer.appendChild(container, detailRow);

      this.renderer.appendChild(item, container);
    }

    this.renderer.appendChild(listContainer, item);

  }


  changeNoVariantsShopPoint(){
    const quantities = this.el.nativeElement.querySelector('.noVariantsQuantities');
    const quantityInputs = quantities.querySelectorAll('.no-variant-quantity');
    const unlimitedInputs = quantities.querySelectorAll('.no-variant-unlimited');
    quantityInputs.forEach((element:any) => {
      const dataShopPointId = element.getAttribute('data-shopPointId');
      if (dataShopPointId === this.activeNoVariantsShopPoint) {
        element.classList.remove('visually-hidden');
      } else {
        element.classList.add('visually-hidden');
      }
    });
    unlimitedInputs.forEach((element:any) => {
      const dataShopPointId = element.getAttribute('data-shopPointId');
      if (dataShopPointId === this.activeNoVariantsShopPoint) {
        element.classList.remove('visually-hidden');
      } else {
        element.classList.add('visually-hidden');
      }
    });


    const quantityMain = quantities.querySelector('.quantityMain');
    let sum = 0;
    let isUnlimited = false;
    quantityInputs.forEach((element:any) => {
      if (!element.classList.contains('quantityMain')) {
        const shopPointId = element.getAttribute('data-shopPointId');
        const unlimitedSelect = this.el.nativeElement.querySelector(`.no-variant-unlimited[data-shopPointId="${shopPointId}"]`);
        if(element.value >= 0){
          sum += parseInt(element.value);
        }
        if(unlimitedSelect && unlimitedSelect.value == 1){
          isUnlimited = true;
        }
      }
      this.translate.get('UNLIMITED').subscribe((text: string) => {
        quantityMain.value = (isUnlimited == true)? text :sum.toString();
      });
    })
  }

  showShopPoint(){
    console.log("active shop point: ",this.activeShopPoint);
    const listContainer = this.el.nativeElement.querySelector('#itemCombinationList');
    const quantityInputs = listContainer.querySelectorAll('.quantityInput');
    const quantityMainInputs = listContainer.querySelectorAll('.quantityMain');
    console.log("inputs cantidad: ",quantityInputs);

    quantityMainInputs.forEach((element:any) => {
      let sum = 0;

      const neighboringInputs = element.parentNode.querySelectorAll('input.quantityInput');

      neighboringInputs.forEach((input: any) => {
        if (!input.classList.contains('quantityMain')) {
          sum += parseInt(input.value);
        }
      });

      element.value = sum.toString();
    })

    quantityInputs.forEach((element:any) => {


      const dataShopPoint = element.getAttribute('data-shopPoint');
      if (dataShopPoint === this.activeShopPoint) {
        element.classList.remove('visually-hidden');
      } else {
        element.classList.add('visually-hidden');
      }
    });
  }

  fillItemsArray() {

    const listItemsContainer = this.el.nativeElement.querySelector('#itemCombinationList');
    const items = listItemsContainer.querySelectorAll('.item');

    const table = document.getElementById(
      'variantsCombinationList'
    ) as HTMLTableElement;

    /** Starts Code for Items without variants */
    if(this.combinationsForItems.length < 1){
      const variantToggle = document.getElementById('flexCheckDefault') as HTMLInputElement;
      const productQuantity = document.getElementById('product_quantity') as HTMLInputElement;
      if(variantToggle?.checked == false || this.productType == 2){
        let shopPointQuantities: any[] = [];
        const quantities = this.el.nativeElement.querySelector('.noVariantsQuantities');
        const quantityInputs = quantities.querySelectorAll('.no-variant-quantity');
        quantityInputs.forEach((element:any) => {
          if (!element.classList.contains('quantityMain')) {
            const shopPointId = element.getAttribute('data-shopPointId');
            const unlimitedSelect = this.el.nativeElement.querySelector(`.no-variant-unlimited[data-shopPointId="${shopPointId}"]`);
            let itemUnlimited = 0;
            if(unlimitedSelect && parseInt(unlimitedSelect.value) == 1){
              itemUnlimited = 1;
            }
            shopPointQuantities.push(
              {
                shopPointId: element.getAttribute('data-shopPointId'),
                itemQuantity: element.value ,
                itemUnlimited: itemUnlimited
              }
            )
          }
        })
        this.itemsProduct = [{
          itemActive: 1,
          itemCode: this.product.code,
          itemPrice: this.product.sellPrice,
          itemBeneficiaries: this.productBeneficiaries,
          itemHolder: this.productHolder ? 1 : 0,
          itemDiscountPrice: this.product.discountPrice,
          itemShopPoints: shopPointQuantities
        }]
      }
    }
    /** Ends Code for Items without variants */

    for (let i = 0; i < this.combinationsForItems.length; i++) {

      if (
        this.combinationsForItems[i].variant1 !== null &&
        this.combinationsForItems[i].variant2 !== null &&
        this.combinationsForItems[i].variant3 !== null
      ) {

      }
    }
    items.forEach((item: any, i: any) => {
      const codigoInput = item.querySelector('input[type="text"]');
      const precioInput = item.querySelector('input[type="number"][id^="price"]');
      const precioDescuentoInput = item.querySelector('input[type="number"][id^="discountPrice"]');
      const beneficiariesInput = item.querySelector('input[type="number"][id^="beneficiariy"]');
      const holderInput = item.querySelector('input[type="checkbox"][id^="holder"]');
      const cantidadInputs = item.querySelectorAll('input.quantityInput');

      const cantidades: any = [];

      cantidadInputs.forEach((input: any) => {
        const shopPointId = input.getAttribute('data-shoppoint');
        const value = input.value;
        if(shopPointId > 0){
          cantidades.push({ shopPointId: shopPointId, itemQuantity: value, itemUnlimited: 0 });
        }
      });

      this.combinationsForItems[i].variant1;

      let itemVaraintValues: any = [];
      if(this.combinationsForItems[i].variant1 != null){
        itemVaraintValues.push({
          variantValueName: this.combinationsForItems[i].variant1,
        })
      }
      if(this.combinationsForItems[i].variant2 != null){
        itemVaraintValues.push({
          variantValueName: this.combinationsForItems[i].variant2,
        })
      }
      if(this.combinationsForItems[i].variant3 != null){
        itemVaraintValues.push({
          variantValueName: this.combinationsForItems[i].variant3,
        })
      }
      console.log("combinationsfor item i: ",this.combinationsForItems[i]);

      let productItemHolder = 0;
      if(this.productType == 3){
        productItemHolder = holderInput.checked ? 1 : 0;
      }

      this.itemsProduct.push({
        itemCode: codigoInput.value,
        itemActive: 1,
        itemPrice: precioInput.value,
        itemDiscountPrice: precioDescuentoInput.value,
        itemShopPoints: cantidades,
        itemVariantValues: itemVaraintValues,
        itemBeneficiaries: this.productType == 3 ? parseInt(beneficiariesInput.value) : 0,
        itemHolder: productItemHolder,
      });

    });
    console.log("combinaciones: ",this.combinationsForItems);
    console.log("itemsproduct: ",this.itemsProduct);

    // for (let i = 0; i < this.combinationsForItems.length; i++) {
    //   if (
    //     this.combinationsForItems[i].variant1 !== null &&
    //     this.combinationsForItems[i].variant2 !== null &&
    //     this.combinationsForItems[i].variant3 !== null
    //   ) {
    //     this.itemsProduct.push({
    //       itemCode:
    //         table.rows[i].cells[4].getElementsByTagName('input')[0].value,
    //       itemActive: 1,
    //       itemPrice:
    //         table.rows[i].cells[1].getElementsByTagName('input')[0].value
    //       ,
    //       itemDiscountPrice:
    //         table.rows[i].cells[2].getElementsByTagName('input')[0].value
    //       ,
    //       itemShopPoints: [
    //         {
    //           shopPointId: this.themingService.shopId,
    //           itemQuantity:
    //             table.rows[i].cells[3].getElementsByTagName('input')[0].value,
    //         },
    //       ],
    //       itemVariantValues: [
    //         {
    //           variantValueName: this.combinationsForItems[i].variant1,
    //         },
    //         {
    //           variantValueName: this.combinationsForItems[i].variant2,
    //         },
    //         {
    //           variantValueName: this.combinationsForItems[i].variant3,
    //         },
    //       ],
    //     });
    //   } else if (
    //     this.combinationsForItems[i].variant1 !== null &&
    //     this.combinationsForItems[i].variant2 !== null
    //   ) {
    //     this.itemsProduct.push({
    //       itemCode:
    //         table.rows[i].cells[4].getElementsByTagName('input')[0].value,
    //       itemActive: 1,
    //       itemPrice:
    //         table.rows[i].cells[1].getElementsByTagName('input')[0].value
    //       ,
    //       itemDiscountPrice:
    //         table.rows[i].cells[2].getElementsByTagName('input')[0].value
    //       ,
    //       itemShopPoints: [
    //         {
    //           shopPointId: this.themingService.shopId,
    //           itemQuantity:
    //             table.rows[i].cells[3].getElementsByTagName('input')[0].value,
    //         },
    //       ],
    //       itemVariantValues: [
    //         {
    //           variantValueName: this.combinationsForItems[i].variant1,
    //         },
    //         {
    //           variantValueName: this.combinationsForItems[i].variant2,
    //         },
    //       ],
    //     });
    //   } else if (
    //     this.combinationsForItems[i].variant1 !== null &&
    //     this.combinationsForItems[i].variant3 !== null
    //   ) {
    //     this.itemsProduct.push({
    //       itemCode:
    //         table.rows[i].cells[4].getElementsByTagName('input')[0].value,
    //       itemActive: 1,
    //       itemPrice:
    //         table.rows[i].cells[1].getElementsByTagName('input')[0].value
    //       ,
    //       itemDiscountPrice:
    //         table.rows[i].cells[2].getElementsByTagName('input')[0].value
    //       ,
    //       itemShopPoints: [
    //         {
    //           shopPointId: this.themingService.shopId,
    //           itemQuantity:
    //             table.rows[i].cells[3].getElementsByTagName('input')[0].value,
    //         },
    //       ],
    //       itemVariantValues: [
    //         {
    //           variantValueName: this.combinationsForItems[i].variant1,
    //         },
    //         {
    //           variantValueName: this.combinationsForItems[i].variant3,
    //         },
    //       ],
    //     });
    //   } else if (
    //     this.combinationsForItems[i].variant2 !== null &&
    //     this.combinationsForItems[i].variant3 !== null
    //   ) {
    //     this.itemsProduct.push({
    //       itemCode:
    //         table.rows[i].cells[4].getElementsByTagName('input')[0].value,
    //       itemActive: 1,
    //       itemPrice:
    //         table.rows[i].cells[1].getElementsByTagName('input')[0].value
    //       ,
    //       itemDiscountPrice:
    //         table.rows[i].cells[2].getElementsByTagName('input')[0].value
    //       ,
    //       itemShopPoints: [
    //         {
    //           shopPointId: this.themingService.shopId,
    //           itemQuantity:
    //             table.rows[i].cells[3].getElementsByTagName('input')[0].value,
    //         },
    //       ],
    //       itemVariantValues: [
    //         {
    //           variantValueName: this.combinationsForItems[i].variant2,
    //         },
    //         {
    //           variantValueName: this.combinationsForItems[i].variant3,
    //         },
    //       ],
    //     });
    //   } else if (this.combinationsForItems[i].variant1 !== null) {
    //     this.itemsProduct.push({
    //       itemCode:
    //         table.rows[i].cells[4].getElementsByTagName('input')[0].value,
    //       itemActive: 1,
    //       itemPrice:
    //         table.rows[i].cells[1].getElementsByTagName('input')[0].value
    //       ,
    //       itemDiscountPrice:
    //         table.rows[i].cells[2].getElementsByTagName('input')[0].value
    //       ,
    //       itemShopPoints: [
    //         {
    //           shopPointId: this.themingService.shopId,
    //           itemQuantity:
    //             table.rows[i].cells[3].getElementsByTagName('input')[0].value,
    //         },
    //       ],
    //       itemVariantValues: [
    //         {
    //           variantValueName: this.combinationsForItems[i].variant1,
    //         },
    //       ],
    //     });
    //   } else if (this.combinationsForItems[i].variant2 !== null) {
    //     this.itemsProduct.push({
    //       itemCode:
    //         table.rows[i].cells[4].getElementsByTagName('input')[0].value,
    //       itemActive: 1,
    //       itemPrice:
    //         table.rows[i].cells[1].getElementsByTagName('input')[0].value
    //       ,
    //       itemDiscountPrice:
    //         table.rows[i].cells[2].getElementsByTagName('input')[0].value
    //       ,
    //       itemShopPoints: [
    //         {
    //           shopPointId: this.themingService.shopId,
    //           itemQuantity:
    //             table.rows[i].cells[3].getElementsByTagName('input')[0].value,
    //         },
    //       ],
    //       itemVariantValues: [
    //         {
    //           variantValueName: this.combinationsForItems[i].variant2,
    //         },
    //       ],
    //     });
    //   } else if (this.combinationsForItems[i].variant3 !== null) {
    //     this.itemsProduct.push({
    //       itemCode:
    //         table.rows[i].cells[4].getElementsByTagName('input')[0].value,
    //       itemActive: 1,
    //       itemPrice:
    //         table.rows[i].cells[1].getElementsByTagName('input')[0].value
    //       ,
    //       itemDiscountPrice:
    //         table.rows[i].cells[2].getElementsByTagName('input')[0].value
    //       ,
    //       itemShopPoints: [
    //         {
    //           shopPointId: this.themingService.shopId,
    //           itemQuantity:
    //             table.rows[i].cells[3].getElementsByTagName('input')[0].value,
    //         },
    //       ],
    //       itemVariantValues: [
    //         {
    //           variantValueName: this.combinationsForItems[i].variant3,
    //         },
    //       ],
    //     });
    //   }
    // }
    console.log('Items array', this.itemsProduct);
  }

  goBack(event: any) {
    if (event) {
      this.router.navigate(['/products']);
    }
  }

  closeModal(){
    $('#createProductModal').modal('hide');
    $('#createValidationModal').modal('hide');
    $('#errorCreateProductModal').modal('hide');
    $('#errorProductModal').modal('hide');
  }

  createItemList(){
    this.combinationsForItems = <any[]>[];
    this.enableItemTable();
    this.fillVariantArrays();
    this.fillVariantArraysToGenerateItems();
    this.determineCombinationsLength();
  }
}
