<div class="container">
  <div class="container-inventory">
    <div class="header">
      <h1 class="title">{{ 'GENERAL_INFORMATION' | translate }}</h1>
    </div>

    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>

    <div class="form-card">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="info"></i>
          <strong>{{ 'GENERAL_INFORMATION' | translate }}</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" [ngClass]="{'visually-hidden': isEditingGeneral == true}" (click)="switchEditingGeneral()">
            <i class="feather" data-feather="edit"></i>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingGeneral" (click)="updateShopInfo()">Guardar</a>
        </div>
      </div>
      <div class="loading" *ngIf="loadingInfo">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="info-container row" *ngIf="!loadingInfo">
        <div class="data-container col-md-4" *ngIf="!isEditingGeneral">
          <label class="data-label" for="">{{ 'STORE_NAME' | translate }}</label>
          <output class="data-output">{{ shopInfo.shopName }}</output>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingGeneral">
          <label class="data-label" for="">{{ 'STORE_NAME' | translate }}</label>
          <input [(ngModel)]="shopInfo.shopName" class="form-control" type="text" value="{{ shopInfo.shopName }}" />
        </div>
        <div class="data-container col-md-4" *ngIf="!isEditingGeneral">
          <label class="data-label" for="">{{'EMAIL' | translate}}</label>
          <output class="data-output">{{ shopInfo.shopMail }}</output>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingGeneral">
          <label class="data-label" for="">{{'EMAIL' | translate}}</label>
          <input [(ngModel)]="shopInfo.shopMail" class="form-control" type="text" value="{{ shopInfo.shopMail }}" />
        </div>
        <div class="data-container col-md-4" *ngIf="!isEditingGeneral">
          <label class="data-label" for="">{{'CURRENCY' | translate}}</label>
          <output class="data-output">{{ currentCurrencyCode }}</output>
        </div>
        <div class="editing data-container form-group col-md-4" *ngIf="isEditingGeneral">
          <label class="data-label" for="">{{'CURRENCY' | translate}}</label>
          <!-- <input class="form-control" type="text" value="{{ shopTheme[0].currencyCode }}" /> -->
          <select class="form-control" (change)="changeCurrency($event)">
            <option *ngFor="let currency of currencies" value="{{currency.currencyId}}" [selected]="shopInfo.currencyId == currency.currencyId">({{currency.currencyCode}}) - {{currency.currencyName}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="form-card">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="map-pin"></i>
          <strong>{{ 'LOCATION' | translate }}</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" [ngClass]="{'visually-hidden': isEditingLocation == true}" (click)="switchEditingLocation()">
            <i class="feather" data-feather="edit"></i>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingLocation" (click)="updateShopLocation()">{{ 'SAVE' | translate }}</a>
        </div>
      </div>
      <div class="loading" *ngIf="loadingLocation">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="info-container row" *ngIf="!loadingLocation">

        <!-- Country -->
        <div class="data-container col-md-4">
          <label class="data-label" for="">{{ 'COUNTRY' | translate }}</label>
          <ng-container *ngIf="!isEditingLocation">
            <label>{{ getCountryName() }}</label>
          </ng-container>
          <ng-container *ngIf="isEditingLocation">
            <select class="form-control" (change)="changeCountry($event)">
              <option value="0"></option>
              <option *ngFor="let country of countries" [value]="country.countryId" [selected]="shopInfo.countryId == country.countryId">
                {{country.countryName}}
              </option>
            </select>
          </ng-container>
        </div>
      
        <!-- Province -->
        <div class="data-container col-md-4">
          <label class="data-label" for="">{{ 'PROVINCE' | translate }}</label>
          <ng-container *ngIf="!isEditingLocation">
            <label>{{ getProvinceName() }}</label>
          </ng-container>
          <ng-container *ngIf="isEditingLocation">
            <select class="form-control" (change)="changeGeoLocation1($event)">
              <option value="0">{{'SELECT_OPTION' | translate}}</option>
              <option *ngFor="let geoloc1 of geoLocation1" [value]="geoloc1.geolocation1Id" [selected]="shopInfo.shopGeoLocation1Id == geoloc1.geolocation1Id">
                {{geoloc1.geolocation1Name}}
              </option>
            </select>
          </ng-container>
        </div>
      
        <!-- Canton -->
        <div class="data-container col-md-4">
          <label class="data-label" for="">{{ 'CANTON' | translate }}</label>
          <ng-container *ngIf="!isEditingLocation">
            <label>{{ getCantonName() }}</label>
          </ng-container>
          <ng-container *ngIf="isEditingLocation">
            <select class="form-control" (change)="changeGeoLocation2($event)">
              <option value="0">{{'SELECT_OPTION' | translate}}</option>
              <option *ngFor="let geoloc2 of geoLocation2" [value]="geoloc2.geolocation2Id" [selected]="shopInfo.shopGeoLocation2Id == geoloc2.geolocation2Id">
                {{geoloc2.geolocation2Name}}
              </option>
            </select>
          </ng-container>
        </div>
      
        <!-- District -->
        <div class="data-container col-md-4">
          <label class="data-label" for="">{{ 'DISTRICT' | translate }}</label>
          <ng-container *ngIf="!isEditingLocation">
            <label>{{ getDistrictName() }}</label>
          </ng-container>
          <ng-container *ngIf="isEditingLocation">
            <select class="form-control" (change)="changeGeoLocation3($event)">
              <option value="0">{{'SELECT_OPTION' | translate}}</option>
              <option *ngFor="let geoloc3 of geoLocation3" [value]="geoloc3.geolocation3Id" [selected]="shopInfo.shopGeoLocation3Id == geoloc3.geolocation3Id">
                {{geoloc3.geolocation3Name}}
              </option>
            </select>
          </ng-container>
        </div>
      
      </div>
      
    </div>
    <app-tax-settings></app-tax-settings>
    <div class="form-card">
      <div class="label">
        <div class="label-group">
          <i class="feather" data-feather="smartphone"></i>
          <strong>{{ 'SOCIAL_NETWORKS' | translate }}</strong>
        </div>
        <div class="label-edit">
          <a class="edit-link" [ngClass]="{'visually-hidden': isEditingSocial == true}" (click)="switchEditingSocial()">
            <i class="feather" data-feather="edit"></i>
          </a>
          <a class="primary-btn edit-link" *ngIf="isEditingSocial" (click)="updateShopSocialMedia()">{{'SAVE' | translate}}</a>
        </div>
      </div>
      <div class="loading" *ngIf="loadingSocial">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="info-container row" *ngIf="!loadingSocial">
        <div class="col-md-6" *ngFor="let socialNet of shopSocialMedia">
          <div class="data-container" *ngIf="!isEditingSocial">
            <div class="social-icon">
              <ng-container *ngIf="socialNet.socialMediaName == 'Facebook'">
                <img src="assets/icons/facebook.svg" alt="Facebook" class="icon-svg" />
              </ng-container>
              <ng-container *ngIf="socialNet.socialMediaName == 'Instagram'">
                <img src="assets/icons/instagram.svg" alt="Instagram" class="icon-svg" />
              </ng-container>
              <ng-container *ngIf="socialNet.socialMediaName == 'Whatsapp'">
                <img src="assets/icons/whatsapp.svg" alt="Whatsapp" class="icon-svg" />
              </ng-container>
              <ng-container *ngIf="socialNet.socialMediaName == 'Tiktok'">
                <img src="assets/icons/tiktok.svg" alt="Tiktok" class="icon-svg" />
              </ng-container>
              <ng-container *ngIf="socialNet.socialMediaName == 'Linkedin'">
                <img src="assets/icons/linkedin.svg" alt="Linkedin" class="icon-svg" />
              </ng-container>
              <label class="data-label">{{socialNet.socialMediaName}}</label>
            </div>
            <output class="data-output">{{socialNet.socialMediaURL}}</output> 
          </div>
          <div class="editing data-container form-group" *ngIf="isEditingSocial">
            <label class="data-label">{{socialNet.socialMediaName}}</label>
            <input [(ngModel)]="socialNet.socialMediaURL" class="form-control" type="text" />
            <div *ngIf="socialNet.socialMediaName == 'Facebook'" class="form-text col-md-3">Ej: https://www.facebook.com/mobydygLATAM</div>
            <div *ngIf="socialNet.socialMediaName == 'Instagram'" class="form-text col-md-3">Ej: https://www.instagram.com/mobydyglatam</div>
            <div *ngIf="socialNet.socialMediaName == 'Whatsapp'" class="form-text col-md-3">Ej: https://wa.me/50612345678</div>
            <div *ngIf="socialNet.socialMediaName == 'Tiktok'" class="form-text col-md-3">Ej: https://www.tiktok.com/@user_name</div>
            <div *ngIf="socialNet.socialMediaName == 'Linkedin'" class="form-text col-md-3">Ej: https://www.linkedin.com/company/mobydyg</div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-card">
      <app-terms-and-conditions></app-terms-and-conditions>
    </div>
    <div class="form-card">
      <app-privacy-policy></app-privacy-policy>
    </div>
    <div class="form-card">
      <app-online-policy></app-online-policy>
    </div>
  </div>
</div>

<div class="modal product-modal" id="productModal" tabindex="-1">
  <div class="modal-dialog product-modal-dialog" role="document">
    <div class="modal-content product-modal-content">
      <div class="modal-body product-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal shop-data-confirm-modal fade" id="successShopDataModal" tabindex="-1">
  <div class="modal-dialog shop-data-modal-dialog" role="document">
    <div class="modal-content shop-data-modal-content">
      <div class="modal-body shop-data-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{'CHANGES_SAVED_SUCCESS' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
