import { Component, Input } from '@angular/core';
import { ITimekitComponent } from '../timekit-tabs/timekit-tabs.component';
import {  ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'app-timekit-options',
  templateUrl: './timekit-options.component.html',
  styleUrls: ['./timekit-options.component.scss'],
})

export class TimekitOptionsComponent implements ITimekitComponent {
  @Input()
  data: any;
  constructor(
    public themingService: ThemingService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {}

  getListingResources(): boolean {
    return this.settingsService.getListingResources();
  }

  getCreatingResources(): boolean {
    return this.settingsService.getCreateResources();
  }

  getListingProjects(): boolean {
    return this.settingsService.getListingProjects();
  }

  getCreateProjects(): boolean {
    return this.settingsService.getCreateProjects();
  }

}
