<div class="container">
	<div *ngIf="!categoryIdForSubCategories && !categoryIdForSubSubCategories" class="container-categories">
	  <div class="header">
		<h1 class="title">{{ 'CATEGORIES' | translate }}</h1>
		<button *ngIf="!showCreateCategoryModal" class="btn btn-outline-primary primary-btn" (click)="openCreateCategoryModal()">{{ 'NEW' | translate }}</button>
		<button *ngIf="showCreateCategoryModal" class="btn btn-primary remove-btn" (click)="closeCreateCategoryModal()">{{ 'CANCEL' | translate }}</button>
	  </div>
	  <app-create-category *ngIf="showCreateCategoryModal" (categoryCreated)="handleCategoryCreated()"></app-create-category>
	  <div class="form-card">
		<div class="loading" *ngIf="loading">
		  <div class="spinner-border" role="status"></div>
		</div>
		<table *ngIf="!loading" class="table table-bordered table-hover">
		  <thead>
			<th scope="col" class="table-start">Id</th>
			<th scope="col">{{ 'CATEGORY' | translate }}</th>
			<th scope="col">{{ 'IMAGE' | translate }}</th>
			<th scope="col">{{ 'SUBCATEGORY' | translate }}</th>
			<th scope="col">{{ 'SUB_SUBCATEGORY' | translate }}</th>
			<th scope="col">{{ 'EDIT' | translate }}</th>
			<th scope="col" class="table-end">{{ 'DELETE' | translate }}</th>
		  </thead>
		  <tbody>
			<tr *ngFor="let category of categories">
			  <td>{{ category.categoryId }}</td>
			  <td>{{ category.categoryName }}</td>
			  <td>
				<img class="image-box" *ngIf="category.categoryImageUrl" [src]="category.categoryImageUrl" alt="categoryImageUrl">
				<span *ngIf="!category.categoryImageUrl">{{ 'NO_IMAGE' | translate }}</span>
			  </td>
			  <td>
				<button class="btn info-btn" (click)="showSubCategories(category.categoryId)">
				  {{ 'SUBCATEGORY' | translate }}
				</button>
			  </td>
			  <td>
				<button class="btn info-btn" (click)="showSubSubCategories(category.categoryId)">
				  {{ 'SUB_SUBCATEGORY' | translate }}
				</button>
			  </td>
			  <td>
				<button class="btn btn-primary primary-btn" (click)="openEditCategoryModal(category.categoryId)">
				  {{ 'EDIT' | translate }}
				</button>
			  </td>
			  <td>
				<button class="btn btn-primary remove-btn" (click)="modalDelete = true; categoryIdToDelete = category.categoryId">
				  {{ 'DELETE' | translate }}
				</button>
			  </td>
			</tr>
		  </tbody>
		</table>
	  </div>
	</div>

	<div *ngIf="categoryIdForSubCategories">
	  <div class="btn-container">
		<a class="btn" (click)="backToCategories()">
		  <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 512 512">
			<path fill="#EA803F" d="M48 256c0 114.87 93.13 208 208 208s208-93.13 208-208S370.87 48 256 48S48 141.13 48 256m212.65-91.36a16 16 0 0 1 .09 22.63L208.42 240H342a16 16 0 0 1 0 32H208.42l52.32 52.73A16 16 0 1 1 238 347.27l-79.39-80a16 16 0 0 1 0-22.54l79.39-80a16 16 0 0 1 22.65-.09" />
		  </svg>
		</a>
	  </div>
	  <app-sub-categories [categoryId]="categoryIdForSubCategories"></app-sub-categories>
	</div>

	<div *ngIf="categoryIdForSubSubCategories">
	  <div class="btn-container">
		<a class="btn" (click)="backToCategories()">
		  <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 512 512">
			<path fill="#EA803F" d="M48 256c0 114.87 93.13 208 208 208s208-93.13 208-208S370.87 48 256 48S48 141.13 48 256m212.65-91.36a16 16 0 0 1 .09 22.63L208.42 240H342a16 16 0 0 1 0 32H208.42l52.32 52.73A16 16 0 1 1 238 347.27l-79.39-80a16 16 0 0 1 0-22.54l79.39-80a16 16 0 0 1 22.65-.09" />
		  </svg>
		</a>
	  </div>
	  <app-sub-subcategories [categoryId]="categoryIdForSubSubCategories"></app-sub-subcategories>
	</div>

	<app-confirmation-modal
	  *ngIf="modalDelete"
	  [title]="'CONFIRM_DELETE_CATEGORY' | translate"
    [message]="'DELETE_CATEGORY_WARNING' | translate"
    [confirmText]="'DELETE' | translate"
    [cancelText]="'CANCEL' | translate"
	  (response)="deleteCategory($event)">
	</app-confirmation-modal>


	<section class="modal-section" *ngIf="showEditCategoryModal">
		<div class="modal-content">
		  <div class="modal-body">
			<app-category *ngIf="showEditCategoryModal" (categoryUpdated)="handleCategoryUpdated()" [categoryId]="selectedCategoryId"></app-category>
			<div class="modal-btn">
			  <button class="btn btn-primary remove-btn" (click)="closeEditCategoryModal()">{{'CANCEL' | translate}}</button>
			</div>
		  </div>
		</div>
	  </section>

	<div class="modal delete-category-confirm-modal" id="deleteCategoryModal" tabindex="-1">
	  <div class="modal-dialog category-modal-dialog" role="document">
		<div class="modal-content category-modal-content">
		  <div class="modal-body category-modal-body">
			<h3 class="text-center">{{ 'SUCCESS' | translate }}</h3>
			<p class="text-center">{{ 'CATEGORY_DELETED_SUCCESS' | translate }}</p>
		  </div>
		  <div class="modal-buttons">
			<button type="button" class="btn btn-confirm" (click)="closeModal()">{{ 'CLOSE' | translate }}</button>
		  </div>
		</div>
	  </div>
	</div>

	<div class="modal category-confirm-modal" id="categoryLoadingModal" tabindex="-1">
	  <div class="modal-dialog category-modal-dialog" role="document">
		<div class="modal-content category-modal-content">
		  <div class="modal-body category-modal-body">
			<div class="loading">
			  <div class="spinner-border" role="status"></div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>
