import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { SettingsService } from 'src/app/settings.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss']
})
export class EditPaymentComponent implements OnInit {

  @Input() paymentId!: string;
  
  loading = false;
  isActive = false;
  
  payment: any = {};
  currentPaymentConfig: any = {}

  paymentMethodConfig: any = {};
  paymentMethodDescription: any;

  creditCardXPForm: any;
  creditCardForm: any;
  sinpeMovilForm: any;
  transferForm: any;
  payValidaForm: any;
  
  constructor(
    private apiService: ApiService,
    public themingService:  ThemingService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.creditCardForm = new FormGroup({
      enviroment: new FormControl(''),
      user: new FormControl(''),
      password: new FormControl('')
    });
    this.sinpeMovilForm = new FormGroup({
      phone: new FormControl(''),
      description: new FormControl('')
    });
    this.transferForm = new FormGroup({
      iban: new FormControl(''),
      bac: new FormControl(''),
      bn: new FormControl(''),
      bcr: new FormControl(''),
      description: new FormControl('')
    });
    this.payValidaForm = new FormGroup({
      integration: new FormControl(''),
      merchantId: new FormControl(''),
      clientSecret: new FormControl(''),
      notificationSecret: new FormControl(''),
      countryCode: new FormControl(''),
      currencyCode: new FormControl('')
    })
    this.creditCardXPForm = new FormGroup({
      integration: new FormControl(''),
      enviroment: new FormControl(''),
      user: new FormControl(''),
      password: new FormControl(''),
      apikey: new FormControl('')
    });
    this.getPaymentById(parseInt(this.paymentId));
  }

  getPaymentById(paymentId: number) {
    this.loading = true;
    this.apiService.getPaymentById(paymentId, this.themingService.shopId)
    .then((data: any) => {
      this.payment = data;
      console.log("payment", this.payment);
      this.currentPaymentConfig = JSON.parse(this.payment.config)
      console.log("config", this.currentPaymentConfig);
      this.setForms();
      if(this.payment.paymentStatus){
        this.isActive = true;
      }
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      setTimeout(() => {
        this.loading = false;
      }, 500); 
    });
  }

  setForms(){
    //Tarjeta Credito
    if(parseInt(this.paymentId) == 1){
      this.creditCardForm.get('user').setValue(this.currentPaymentConfig.user);
      this.creditCardForm.get('password').setValue(this.currentPaymentConfig.password);
      this.creditCardForm.get('enviroment').setValue(this.currentPaymentConfig.enviroment);
    }
    //Tarjeta Credito TASA 0
    if(parseInt(this.paymentId) == 10){
      this.creditCardForm.get('user').setValue(this.currentPaymentConfig.user);
      this.creditCardForm.get('password').setValue(this.currentPaymentConfig.password);
      this.creditCardForm.get('enviroment').setValue(this.currentPaymentConfig.enviroment);
    }
    //Sinpe Movil CR
    else if(parseInt(this.paymentId) == 2){
      const phoneNumber = this.sinpeMovilForm.get('phone').setValue(this.currentPaymentConfig.phone);
      const description = this.sinpeMovilForm.get('description').setValue(this.payment.paymentDescription);
    }
    //Transferencia
    else if(parseInt(this.paymentId) == 3){
      const iban = this.transferForm.get('iban').setValue(this.currentPaymentConfig.iban);
      const bac = this.transferForm.get('bac').setValue(this.currentPaymentConfig.bac);
      const bn = this.transferForm.get('bn').setValue(this.currentPaymentConfig.bn);
      const bcr = this.transferForm.get('bcr').setValue(this.currentPaymentConfig.bcr);
      const description = this.transferForm.get('description').setValue(this.payment.paymentDescription);
    }
    //PayValida
    else if(parseInt(this.paymentId) == 5){
      const integration = this.payValidaForm.get('integration').setValue(this.currentPaymentConfig.integration);
      const merchantId = this.payValidaForm.get('merchantId').setValue(this.currentPaymentConfig.merchantId);
      const clientSecret = this.payValidaForm.get('clientSecret').setValue(this.currentPaymentConfig.clientSecret);
      const notificationSecret = this.payValidaForm.get('notificationSecret').setValue(this.currentPaymentConfig.notificationSecret);
      const countryCode = this.payValidaForm.get('countryCode').setValue(this.currentPaymentConfig.countryCode);
      const currencyCode = this.payValidaForm.get('currencyCode').setValue(this.currentPaymentConfig.currencyCode);
    }
    // XPRESSPAGO
    else if(parseInt(this.paymentId) == 8){
      this.creditCardXPForm.get('user').setValue(this.currentPaymentConfig.user);
      this.creditCardXPForm.get('password').setValue(this.currentPaymentConfig.password);
      this.creditCardXPForm.get('apikey').setValue(this.currentPaymentConfig.APIKey);
      this.creditCardXPForm.get('enviroment').setValue(this.currentPaymentConfig.enviroment);
      this.creditCardXPForm.get('integration').setValue(this.currentPaymentConfig.integration);
    }
  }

  setListingPaymentMethods(){
    this.settingsService.setListingPaymentMethods();
  }

  validateFormData(){
    this.loading = true;
    console.log("submit form");
    // Pasalea de pago del BAC 
    if(parseInt(this.paymentId) == 1){
      const user = this.creditCardForm.get('user')?.value;
      const password = this.creditCardForm.get('password')?.value;
      const enviroment = this.creditCardForm.get('enviroment')?.value;
      if(user && password && enviroment){
        this.paymentMethodConfig.integration = "bac";
        this.paymentMethodConfig.user = user.toString();
        this.paymentMethodConfig.password = password.toString();
        this.paymentMethodConfig.enviroment = enviroment.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
    }
    // Pasalea de pago del BAC TASA 0
    if(parseInt(this.paymentId) == 10){
      const user = this.creditCardForm.get('user')?.value;
      const password = this.creditCardForm.get('password')?.value;
      const enviroment = this.creditCardForm.get('enviroment')?.value;
      if(user && password && enviroment){
        this.paymentMethodConfig.integration = "bac";
        this.paymentMethodConfig.user = user.toString();
        this.paymentMethodConfig.password = password.toString();
        this.paymentMethodConfig.enviroment = enviroment.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
    }
    //Sinpe Movil CR
    else if(parseInt(this.paymentId) == 2){
      const phoneNumber = this.sinpeMovilForm.get('phone')?.value;
      const description = this.sinpeMovilForm.get('description').value;
      if(phoneNumber){
        this.paymentMethodConfig = {"phone":phoneNumber.toString()};
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
      this.paymentMethodDescription = description;
    }
    //Transferencia
    else if(parseInt(this.paymentId) == 3){
      const iban = this.transferForm.get('iban')?.value;
      const bac = this.transferForm.get('bac')?.value;
      const bn = this.transferForm.get('bn')?.value;
      const bcr = this.transferForm.get('bcr')?.value;
      const description = this.transferForm.get('description').value;
      if(iban){
        this.paymentMethodConfig.iban = iban.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
      if(bac){
        this.paymentMethodConfig.bac = bac.toString();
      }
      if(bn){
        this.paymentMethodConfig.bn = bn.toString();
      }
      if(bcr){
        this.paymentMethodConfig.bcr = bcr.toString();
      }
      this.paymentMethodDescription = description;
      
    }
    // Payvalida
    else if(parseInt(this.paymentId) == 5){
      const merchantId = this.payValidaForm.get('merchantId')?.value;
      const clientSecret = this.payValidaForm.get('clientSecret')?.value;
      const notificationSecret = this.payValidaForm.get('notificationSecret')?.value;
      const countryCode = this.payValidaForm.get('countryCode')?.value;
      const currencyCode = this.payValidaForm.get('currencyCode')?.value;
      if(merchantId && clientSecret && notificationSecret && countryCode && currencyCode){
        this.paymentMethodConfig.integration = "payvalida";
        this.paymentMethodConfig.merchantId = merchantId.toString();
        this.paymentMethodConfig.clientSecret = clientSecret.toString();
        this.paymentMethodConfig.notificationSecret = notificationSecret.toString();
        this.paymentMethodConfig.countryCode = countryCode.toString();
        this.paymentMethodConfig.currencyCode = currencyCode.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
    }
    // XPRESSPAGO
    else if(parseInt(this.paymentId) == 8){
      const user = this.creditCardXPForm.get('user')?.value;
      const password = this.creditCardXPForm.get('password')?.value;
      const enviroment = this.creditCardXPForm.get('enviroment')?.value;
      const apikey = this.creditCardXPForm.get("apikey")?.value;
      if(user && password && enviroment){
        this.paymentMethodConfig.integration = "xpresspago";
        this.paymentMethodConfig.user = user.toString();
        this.paymentMethodConfig.password = password.toString();
        this.paymentMethodConfig.enviroment = enviroment.toString();
        this.paymentMethodConfig.APIKey = apikey.toString();
      }else{
        $('#requiredPaymentMethodModal').modal('show');
      }
    }
    this.updatePaymentMethod()
    console.log("config to save: ", this.paymentMethodConfig);
    console.log("descrip to save: ", this.paymentMethodDescription);
    this.loading = false;
  
  }

  updatePaymentMethod(){
    console.log("payload",
      this.themingService.shopId,
      parseInt(this.paymentId),
      this.paymentMethodConfig,
      this.paymentMethodDescription,
      this.isActive?1:0
    )
    this.apiService.updatePaymentMethod(
      this.themingService.shopId,
      parseInt(this.paymentId),
      this.paymentMethodConfig,
      this.paymentMethodDescription,
      this.isActive?1:0
    )
    .then((data: any) => {
      if(data.statusCode === 200){
        $('#successPaymentMethodModal').modal('show');
        this.loading = false;
      }
      else{
        $('#errorPaymentMethodModal').modal('show');
      }
    })
    .catch((error) => {
      console.error(error);
      this.loading = false;
    });
  }

  closeModal(){
    $('#successPaymentMethodModal').modal('hide');
    $('#errorPaymentMethodModal').modal('hide');
    $('#requiredPaymentMethodModal').modal('hide');
  }


}
