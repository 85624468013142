<!-- <app-side-bar></app-side-bar> -->

<section class="select-project">
  <div class="select-project">
    <div class="col-md-6">
      <label for="projects" class="form-label">{{'SELECT_PROJECT' | translate}}</label>
      <select class="form-select" (change)="onProjectChange($event)">
        <option selected disabled hidden>
          {{'SELECT-OPTION' | translate}}
        </option>
        <option *ngFor="let project of projects" [value]="project.projectId">
          {{ project.projectName }}
        </option>
      </select>
    </div>
  </div>
</section>



<full-calendar [options]="calendarOptions" id="calendar" class="container main-container"></full-calendar>
<p>{{ data }}</p>
<!-- Modal -->
<div id="appointmentModal" [ngClass]="{'visible': isModalOpen}" class="modal-overlay">

  <div class="modal-content">
    <div class="header">
      <button (click)="closeModal()" class="btn-close"></button>
      <h2 class="modal-title title">{{'BOOKING_DETAIL' | translate}}</h2>
    </div>

    <p><strong>{{'NAME' | translate}}:</strong> {{ selectedAppointment?.customers?.[0]?.name || 'No disponible' }}</p>
    <p><strong>{{'DATE' | translate}}:</strong> {{ getFormattedDate(selectedAppointment?.start) }} </p>
    <p><strong>{{'TIME' | translate}}:</strong> {{ getFormattedTime(selectedAppointment?.start) }} - {{
      getFormattedTime(selectedAppointment?.end) }}</p>
    <p><strong>Email:</strong> {{ selectedAppointment?.customers?.[0]?.email || 'No disponible' }}</p>
    <p><strong>{{'PHONE' | translate}}:</strong> {{ selectedAppointment?.customers?.[0]?.telefono || 'No disponible' }}</p>
    <p><strong>{{'TYPE_APPOINTMENT' | translate}}:</strong> {{ selectedAppointment?.customers?.[0]?.tipocita || 'No disponible' }}</p>
    <p><strong>{{'APPOINTMENT_LOCATION' | translate}}:</strong> {{ selectedAppointment?.customers?.[0]?.lugar_de_cita || 'No disponible' }}</p>

    <div class="buttons">
      <button (click)="updateAppointment()" class="custom-button btn-outline-primary primary-btn">{{'RESCHEDULE' | translate}}</button>
      <button (click)="cancelAppointment()" class="btn btn-primary remove-btn">{{'CANCEL_SCHEDULE' | translate}}</button>
    </div>

  </div>
</div>

<!-- Modal para disponibilidad de horarios -->
<div class="availability-modal-overlay modal" [ngClass]="{'visible': isAvailabilityModalOpen}">
  <div class="availability-modal-content modal-content">

    <div class="modal-header">
      <h2 class="modal-title-2 title">{{'SELECT_SCHEDULE_AVAILABILITY' | translate }}</h2>
      <span class="btn-close" (click)="closeAvailabilityModal()">&times;</span>
    </div>

    <ul class="modal-body">
      <li *ngFor="let slot of availableSlots" class="slot-item">
        <div class="slot-details">
          <div class="slot-info">
            <div class="slot-day"><strong>{{ slot.formattedStart }}</strong></div>
            <span class="slot-time">{{ slot.startTime }} - {{ slot.endTime }}</span>
          </div>
          <button (click)="selectSlot(slot)"
            class="custom-button-2 btn-outline-primary primary-btn">{{'SELECT' | translate}}</button>
        </div>
        <hr />
      </li>
    </ul>
    <div *ngIf="!availableSlots || availableSlots.length === 0">
      <p>{{'NO_AVAILABLE_TIME' | translate}}</p>
    </div>
  </div>
</div>



<!-- Modal de Confirmación para Cancelar Cita -->
<app-confirmation-modal *ngIf="modalCancelAppointment" [title]="'¿Seguro que quiere cancelar esta cita?'"
  [message]="'Al cancelar la cita, esta ya no estará disponible en el sistema. ¿Desea continuar?'"
  [confirmText]="'Cancelar Cita'" [cancelText]="'Cancelar'" (response)="handleCancelConfirmation($event)">
</app-confirmation-modal>

<!-- Modal de Éxito -->
<div class="modal-appointment delete-category-confirm-modal" id="deleteAppointmentModal" tabindex="-1"
  *ngIf="modalSuccess">
  <div class="modal-dialog category-modal-dialog">
    <div class="modal-content category-modal-content">
      <div class="modal-body category-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{'SUCESS_CANCEL_APPOINTMENT' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModalAppointment()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
