<div class="container">
  <div class="container-gift-card">

    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>
    <div class="form-card" *ngIf="!loading">
      <div class="gift-card-info-container">
        <div class="column-container">
          <div class="column-info">
            <div  class="info-container">
              <div class="form-label">{{'NAME' | translate }} :</div>
              {{ giftCard.name }}
            </div>
            <div class="info-container">
              <div class="form-label">{{'VALUE' | translate}} :</div>
              {{  appComponent.formatMoney(parseAmount(giftCard.amount)) }}
            </div>
            <div  class="info-container">
              <div class="form-label">{{'CODE' | translate }}:</div>
              {{ giftCard.code }}
            </div>
          </div>
          <div class="column-info">
            <div class="info-container">
              <div class="form-label">{{'STATUS' | translate}}:</div>
              <span *ngIf="giftCard.status === 0">{{'INACTIVE' | translate}}</span>
              <span *ngIf="giftCard.status === 1">{{'ACTIVE' | translate }}</span>
              <span *ngIf="giftCard.status !== 0 && giftCard.status !== 1">{{'PENDING' | translate}}</span>
            </div>
            <div class="info-container">
              <div class="form-label">{{'BALANCE' | translate}}:</div>
              {{  appComponent.formatMoney(parseAmount(giftCard.balance)) }}
            </div>
            <div  class="info-container">
              <div class="form-label">{{'VALIDITY' | translate}}:</div>
              {{ giftCard.validMonths }} {{ giftCard.validMonths === 1 ? 'mes' : 'meses' }}
            </div>
          </div>
        </div>
        <div class="images-container">
          <div class="form-label">{{'IMAGE' | translate}}:</div>
          <div class="image-list">
            <div class="image-box">
              <img *ngIf="giftCard.images" [src]="giftCard.images" />
              <span *ngIf="!giftCard.images">Sin Imagen</span>
            </div>
          </div>
        </div>
        <div  class="info-container">
          <div class="form-label"> {{'DAILY_SALES' | translate}} :</div>
          {{ formatDate(giftCard.date)  }}
        </div>
        <div  class="info-container">
          <div class="form-label"> {{'EMAIL' | translate}} :</div>
          {{ giftCard.giftCardEmail }}
        </div>
        <div  class="info-container">
          <div class="form-label">{{'TYPE_DELIVERY' | translate}} : </div>
          {{ giftCard.isVirtual === 1 ? "Virtual" : "Fisica" }}
        </div>
        <div class="info-container" *ngIf="giftCard.giftCardExactDirection && giftCard.giftCardExactDirection.trim() !== ''">
          <div class="form-label">{{'ADDRESS' | translate}}</div>
          {{ giftCard.giftCardExactDirection }}
        </div>
        <div class="info-container" *ngIf="giftCard.giftCardMessage && giftCard.giftCardMessage.trim() !== ''">
          <div class="form-label"> {{'MESSAGE_PERZONALIZED' | translate}} :</div>
          {{ giftCard.giftCardMessage }}
        </div>
      </div>
    </div>
  </div>
</div>
