import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import {  ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';

interface Project {
  projectId: string;
  projectName: string;
}

@Component({
  selector: 'app-timekit-projects',
  templateUrl: './timekit-projects.component.html',
  styleUrls: ['./timekit-projects.component.scss']
})

export class TimekitProjectsComponent implements OnInit {
  projects: Project[] = [];
  loading: boolean = false;
  showNoProjectsMessage: boolean = false;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.getTimekitProjects()
  }

  async getTimekitProjects() {
    this.loading = true;
    this.showNoProjectsMessage = false;

    try {
      const response = await this.apiService.getTimekitProjects(this.themingService.shopId) as { data: Project[] };

      this.projects = response.data || [];
    } catch (error) {
      console.error('Error al obtener proyectos:', error);
      this.projects = [];
    } finally {
      this.loading = false;
    }
  }

  setCreatingProjects(){
    this.settingsService.setCreatingProjects();
  }
}
