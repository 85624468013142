<div class="editor-container">
  <!-- .editor-container{
    display: flex;
    flex-direction: column;
  } -->
  <div class="label">
    <div class="label-group">
      <i class="feather" data-feather="info"></i>
      <strong>{{ 'TERMS_CONDITIONS' | translate }}</strong>
    </div>
    <div class="label-edit">
      <a class="edit-link" [hidden]="isEditingTerms" (click)="switchEditingTerms()">
        <i class="feather" data-feather="edit"></i>
      </a>
      <a class="primary-btn edit-link" *ngIf="isEditingTerms" (click)="saveTermsAndConditions()">{{'SAVE' | translate}}</a>
    </div>
  </div>
  <div class="terms-page-container">
    <div class="terms-container">
      <div class="page-card">
        <quill-editor class="terms-editor" [styles]="{
          borderRadius: '0 0 10px 10px',
          padding: '5px',
          marginBottom: '2vh',
          height: '350px'
        }"  id="termsAndConditions-edit" [modules]="modules" [(ngModel)]="termsAndConditionsContent"></quill-editor>
      </div>
    </div>
  </div>
</div>

<div class="modal terms-confirm-modal" id="successTermsModal" tabindex="-1">
  <div class="modal-dialog terms-modal-dialog" role="document">
    <div class="modal-content terms-modal-content">
      <div class="modal-body terms-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'TERMS_CONDITIONS_SAVED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal terms-confirm-modal fade" id="errorTermsModal" tabindex="-1">
  <div class="modal-dialog terms-modal-dialog" role="document">
    <div class="modal-content terms-modal-content">
      <div class="modal-body terms-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}!</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
