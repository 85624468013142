import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
declare var $: any;
@Component({
  selector: 'app-item-beneficiaries',
  templateUrl: './item-beneficiaries.component.html',
  styleUrls: ['./item-beneficiaries.component.scss']
})
export class ItemBeneficiariesComponent implements OnInit {
  @Input() clientId: string = '';
  @Input() saleId: number | null = null;
  @Input() itemId: number | null = null;
  @Input() clientName: string = ''; 

  beneficiaries: any[] = [];
  itemBeneficiaries: any[] = [];
  assignedBeneficiaries: Set<string> = new Set(); 
  unselectedItemBeneficiaryIds: string[] = []; 
  isModalOpen: boolean = false; 
  isClientRequiredModalOpen: boolean = false; 
  itemHolderRequired: number = 0;
  maxBeneficiaries: number = 0;
  loading: boolean = false;
  constructor(
    private apiService: ApiService,
    private themingService: ThemingService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientId'] || changes['saleId'] || changes['itemId']) {
      this.getBeneficiariesByClientId();
    }
  }

  async getBeneficiariesByClientId() {
    this.loading = true;
    if (!this.clientId || !this.saleId || !this.itemId) {
      return;
    }
  
    try {
      const data: any = await this.apiService.getBeneficiariesByClientId(
        this.clientId,
        this.themingService.shopId,
        this.saleId,
        this.itemId,
      );
      const itemData = data.item[0];
      this.itemBeneficiaries = data.itemBeneficiaries || [];
      this.beneficiaries = data.beneficiaries || [];
      this.maxBeneficiaries = itemData.itemBeneficiaries; 
      this.beneficiaries = [
        {
          beneficiaryNationalId: this.clientId,
          beneficiaryName: this.clientName,
          isClient: true,
          itemBeneficiaryId: null 
        },
        ...this.beneficiaries
      ];
      this.itemHolderRequired = itemData.itemHolderRequired;
      this.beneficiaries.forEach(beneficiary => {
        const item = this.itemBeneficiaries.find(
          (b: any) => b.beneficiaryNationalId === beneficiary.beneficiaryNationalId
        );
        beneficiary.itemBeneficiaryId = item ? item.itemBeneficiaryId : null;
      });
      this.assignedBeneficiaries = new Set(
        this.itemBeneficiaries.map((item: any) => item.beneficiaryNationalId)
      );
      this.loading = false;
    } catch (error) {
      console.error('Error fetching data from API:', error);
      this.loading = false;
    }
  }

  isChecked(beneficiaryNationalId: string): boolean {
    return this.assignedBeneficiaries.has(beneficiaryNationalId);
  }
  
  toggleBeneficiarySelection(event: Event, beneficiary: any) {
    const checkbox = event.target as HTMLInputElement;
    const itemBeneficiaryId = beneficiary.itemBeneficiaryId;
  
    if (!checkbox.checked) {
      this.assignedBeneficiaries.delete(beneficiary.beneficiaryNationalId);
  
      if (itemBeneficiaryId && !this.unselectedItemBeneficiaryIds.includes(itemBeneficiaryId)) {
        this.unselectedItemBeneficiaryIds.push(itemBeneficiaryId);
      }
    } else {
      if (this.assignedBeneficiaries.size >= this.maxBeneficiaries) {
        $('#maxBeneficiariesModal').modal('show'); 
        checkbox.checked = false;
        return;
      }
  
      this.assignedBeneficiaries.add(beneficiary.beneficiaryNationalId);
  
      if (itemBeneficiaryId) {
        this.unselectedItemBeneficiaryIds = this.unselectedItemBeneficiaryIds.filter(
          (id) => id !== itemBeneficiaryId
        );
      }
    }
  }
  
  validateBeforeSave(): boolean {
    const selectedBeneficiaries = this.beneficiaries.filter((beneficiary) =>
      this.assignedBeneficiaries.has(beneficiary.beneficiaryNationalId)
    );

    if (selectedBeneficiaries.length === 0) {
      $('#errorNoBeneficiariesModal').modal('show'); // Mostrar modal de error
      this.loading = false;
      return false;
    }
  
    if (this.itemHolderRequired === 1) {
      const isHolderIncluded = selectedBeneficiaries.some(
        (beneficiary) => beneficiary.beneficiaryNationalId === this.clientId
      );
  
      if (!isHolderIncluded) {
        $('#itemHolderModalRequired').modal('show');
        this.loading = false;
        return false; 
      }
    }
  
    return true; 
  }
  
  async saveBeneficiaries() {
    this.loading = true;
    if (!this.validateBeforeSave()) {
      return; 
    }
    const selectedBeneficiaries = this.beneficiaries.filter((beneficiary) =>
      this.assignedBeneficiaries.has(beneficiary.beneficiaryNationalId)
    );
    try {
      const data: any = await this.apiService.assignBeneficiaries(
        this.themingService.shopId,
        this.clientId,
        this.saleId!,
        this.itemId!,
        selectedBeneficiaries,
        this.unselectedItemBeneficiaryIds
      );
  
      if (data.statusCode === 200) {
        $('#successBeneficiariesModal').modal('show');
      } else {
        $('#errorBeneficiariesModal').modal('show'); 
      }
    } catch (error) {
      console.error('Error updating beneficiaries:', error);
      $('#errorBeneficiariesModal').modal('show');
    } finally {
      this.loading = false;
    }
  }
  
  closeModal(){
    $('#successBeneficiariesModal').modal('hide');
    $('#errorBeneficiariesModal').modal('hide');
    $('#errorNoBeneficiariesModal').modal('hide');
    $('#itemHolderModalRequired').modal('hide');
    $('#maxBeneficiariesModal').modal('hide');
  }
}


  

