<div class="container">
  <div class="container-collections">
    <div class="header">
        <h1 class="title">{{"COLLECTIONS" | translate}}</h1>
        <button class="btn btn-outline-primary primary-btn" (click)="setCreatingCollection()">{{'NEW' | translate}}</button>
    </div>
    <div class="form-card">
        <div class="loading" *ngIf="loading">
            <div class="spinner-border" role="status"></div>
        </div>
          <table *ngIf="!loading" class="table table-bordered table-hover">
              <thead>
                <th scope="col" class="table-start">Id</th>
                <th scope="col">{{'NAME' | translate}}</th>
                <th scope="col">{{'DESCRIPTION' | translate}}</th>
                <th scope="col">{{'EDIT' | translate}}</th>
                <th scope="col" class="table-end">{{'DELETE' | translate}}</th>
              </thead>
              <tbody>
                <tr *ngFor="let collection of collections">
                  <td>{{ collection.collectionId }}</td>
                  <td>
                    <a [routerLink]="['/collections', collection.collectionId]" routerLinkActive="active">{{ collection.collectionName }}</a>
                  </td>
                  <td>{{ collection.collectionDescription }}</td>
                  <td>
                    <button class="btn btn-primary primary-btn" (click)="setEditingCollection(collection.collectionId)">
                      {{'EDIT' | translate}}
                    </button>
                  </td>
                  <td>
                    <button class="btn btn-primary remove-btn" (click)="modalDelete = true; collectionIdToDelete = collection.collectionId">
                      {{'DELETE' | translate}}
                    </button>
                  </td>
                </tr>
              </tbody>
        </table>
    </div>
</div>

  <app-confirmation-modal *ngIf="modalDelete"
  [title]="'CONFIRM_DELETE_COLLECTION' | translate"
  [message]="'DELETE_COLLECTION_WARNING' | translate"
  [confirmText]="'DELETE' | translate"
  [cancelText]="'CANCEL' | translate"
  (response)="deteleCollection($event)">
  > </app-confirmation-modal>

	<div class="modal delete-collection-confirm-modal" id="deleteCollectionModal" tabindex="-1">
		<div class="modal-dialog collection-modal-dialog" role="document">
			<div class="modal-content collection-modal-content">
				<div class="modal-body collection-modal-body">
					<h3 class="text-center">{{'SUCCESS' | translate}}</h3>
					<p class="text-center">{{ 'COLLECTION_DELETED_SUCCESS' | translate }}</p>
				</div>
				<div class="modal-buttons">
					<button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>
