<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>
<div class="container">
  <div class="container-client">
    <div class="header">
      <h1 class="title">{{'NEW_CLIENT' | translate}}</h1>
      <app-back-arrow [route]="'/clients'"></app-back-arrow>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <form *ngIf="!loading" [formGroup]="clientForm" (ngSubmit)="createClient()" class="content row">
        <div class="title">
          <span>{{'INFORMATION' | translate}}</span>
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="firstName">{{'NAME' | translate}}</label>
          <input class="form-control" id="firstName" formControlName="firstName" type="text">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="lastName">{{'LAST_NAME' | translate}}</label>
          <input class="form-control" id="lastName" formControlName="lastName" type="text">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="gender">{{'GENDER' | translate}}</label>
          <div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="gender" id="genderMale" formControlName="gender" value="male">
              <label class="form-check-label" for="genderMale">{{'MALE' | translate}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="gender" id="genderFemale" formControlName="gender" value="female">
              <label class="form-check-label" for="genderFemale">{{ 'FEMALE' | translate}}</label>
            </div>
          </div>
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="email">{{ 'EMAIL' | translate}}</label>
          <input class="form-control" id="email" formControlName="email" type="email">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="phone">{{'PHONE' | translate}}</label>
          <input class="form-control" id="phone" formControlName="phone" type="text">
        </div>
        <div class="column col-md-4">
          <label class="form-label" for="nationalId">{{ 'ID_NUMBER' | translate }}</label>
          <input class="form-control" id="nationalId" formControlName="nationalId" type="text">
        </div>
        <div class="column col-md-6 pool-register">
          <label class="form-label" for="isPoolRegister">{{ 'ENABLE_ONLINE_PURCHASES' | translate }}</label>
          <input class="form-check-input" id="isPoolRegister" formControlName="isPoolRegister" type="checkbox">
        </div>
        <div class="column col-md-6">
          <label class="form-label" for="password">{{ 'PASSWORD' | translate }}</label>
          <div class="pool-register position-relative">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password" formControlName="password">
            <button type="button" class="btn btn-secondary" (click)="togglePasswordVisibility()">
              {{ showPassword ? ('HIDE' | translate) : ('SHOW' | translate) }}
            </button>
            <button type="button" class="btn btn-secondary" (click)="generatePassword()">{{ 'GENERATE' | translate }}</button>
            <div *ngIf="clientForm.get('password')?.touched && clientForm.get('password')?.errors as errors" class="password-errors-overlay">
              <div *ngIf="errors?.['passwordStrength']?.hasUpperCase === false" class="error-message">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{ 'PASSWORD_ERROR_UPPERCASE' | translate }}</span>
              </div>
              <div *ngIf="errors?.['passwordStrength']?.hasLowerCase === false" class="error-message">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{ 'PASSWORD_ERROR_LOWERCASE' | translate }}</span>
              </div>
              <div *ngIf="errors?.['passwordStrength']?.hasNumber === false" class="error-message">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{ 'PASSWORD_ERROR_NUMBER' | translate }}</span>
              </div>
              <div *ngIf="errors?.['passwordStrength']?.hasSpecialChar === false" class="error-message">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{ 'PASSWORD_ERROR_SPECIAL_CHAR' | translate }}</span>
              </div>
              <div *ngIf="errors?.['passwordStrength']?.isValidLength === false" class="error-message">
                <i class="fas fa-exclamation-circle"></i>
                <span>{{ 'PASSWORD_MINIMUM_LENGTH' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class=" logged-user-switch">
          <label class="form-label">{{'ONLY_VERIFIED_USERS' | translate}}</label>
          <mat-slide-toggle class="switch" id="isVerifiedClient" formControlName="isVerifiedClient" ></mat-slide-toggle>
        </div>
        <div class="create-btn">
          <button class="btn primary-btn" type="submit">{{ 'CREATE_CLIENT' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal client-confirm-modal" id="successClientModal" tabindex="-1">
    <div class="modal-dialog client-modal-dialog" role="document">
      <div class="modal-content client-modal-content">
        <div class="modal-body client-modal-body">
          <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
          <p class="text-center">{{ 'CLIENT_REGISTERED_SUCCESS' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal error-confirm-modal fade" id="requiredClientModal" tabindex="-1">
    <div class="modal-dialog error-modal-dialog" role="document">
      <div class="modal-content error-modal-content">
        <div class="modal-body error-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{ 'ALL_FIELDS_REQUIRED' | translate }}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal client-confirm-modal fade" id="errorClientModal" tabindex="-1">
    <div class="modal-dialog client-modal-dialog" role="document">
      <div class="modal-content client-modal-content">
        <div class="modal-body client-modal-body">
          <h3 class="text-center">{{'ERROR' | translate}}</h3>
          <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
        </div>
        <div class="modal-buttons">
          <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
