<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>
<app-gift-card-thumbnail></app-gift-card-thumbnail>

<div class="container">
  <div class="container-giftcards">
    <div class="nav-tab-container">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="home-tab" [routerLink]="['/giftCardTypes']" routerLinkActive="active" role="tab" aria-controls="home" aria-selected="true">{{'GIFT_CARDS_TYPES' | translate}}</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="home-tab" [routerLink]="['/giftCardItems']" routerLinkActive="active" role="tab" aria-controls="home" aria-selected="true"> {{'GIFT_CARDS' | translate}}</a>
        </li>
      </ul>
    </div>

    <div class="header">
      <h1 class="title">{{'GIFT_CARDS_TYPES' | translate}}</h1>
      <button type="button" class="btn btn-outline-primary primary-btn" [routerLink]="['/giftCardTypes/create']">
         {{'CREATE' | translate}}
      </button>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start"> {{'NAME' | translate}}</th>
          <th scope="col"> {{'MINIATURE' | translate}}</th>
          <th scope="col"> {{'AMOUNT' | translate}}</th>
          <th scope="col"> {{'VALIDITY' | translate}}</th>
          <th scope="col"> {{'QUANTITY' | translate}}</th>
          <th scope="col"> {{'EDIT' | translate}}</th>
          <th scope="col"class="table-end">{{'DELETE' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let giftCard of giftCards">
            <td>{{ giftCard.name }}</td>
            <td>
              <img [src]="giftCard.images" alt="Imagen de la tarjeta de regalo">
            </td>
            <td class="vertical-amount-column">
              <ng-container *ngFor="let amount of giftCard.amount.split(',')">
                {{ amount }}
                <br>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="giftCard.validMonths === 0; else showMonths">
                <span class="infinity-symbol">&#8734;</span>
              </ng-container>
              <ng-template #showMonths>
                {{ giftCard.validMonths }} {{ giftCard.validMonths === 1 ? ('MONTH' | translate) : ('MONTHS' | translate) }}
              </ng-template>
            </td>
            <td>
              {{ giftCard.stock === 999999 ? 'Ilimitada' : giftCard.stock }}
            </td>
            <td>
              <button type="button" class="btn btn-primary primary-btn" [routerLink]="['/giftCardTypes/', giftCard.giftCardTypeId]">
                 {{'EDIT' | translate}}
              </button>
            </td>
            <td>
              <button class="btn btn-primary remove-btn" (click)="modalDelete = true; giftCardIdToDelete = giftCard.giftCardTypeId">
                 {{'DELETE' | translate}}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-confirmation-modal *ngIf="modalDelete" [title]="'¿Seguro que quiere eliminar esta tarjeta?'" [message]="'Al eliminar esta tarjeta se eliminaran los datos que esten relacionados a ella'"
    [confirmText]="'Eliminar'" [cancelText]="'Cancelar'" (response)="deleteGiftCardType($event)">
    > </app-confirmation-modal>

    <div class="modal delete-giftcard-confirm-modal" id="deleteGiftCardModal" tabindex="-1">
      <div class="modal-dialog giftcard-modal-dialog" role="document">
        <div class="modal-content giftcard-modal-content">
          <div class="modal-body giftcard-modal-body">
            <h3 class="text-center"> {{'SUCCESS' | translate}}</h3>
            <p class="text-center">{{'DELETE_CARD_SUCCESS' | translate}}</p>
          </div>
          <div class="modal-buttons">
            <button type="button" class="btn btn-confirm" (click)="closeModal()"> {{'CLOSE' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
