<div class="container">
  <div class="container-shopPoint">
    <div class="header">
      <h1 class="title">{{'SHOP_POINTS' | translate}}</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingShopPoint()">{{'NEW' | translate}}</button>
    </div>
    <div class="form-card">
      <div class="search-container visually-hidden">
        <input
          type="text"
          placeholder="Buscar"
          [(ngModel)]="searchString"
          (keyup)="searchShopPoints()"
        />
      </div>
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <table *ngIf="!loading" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">{{'NAME' | translate}}</th>
          <th scope="col">{{'LOCATION' | translate}}</th>
          <th scope="col">{{'EDIT' | translate}}</th>
          <th scope="col" class="table-end">{{'DELETE' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let shopPoint of shopPoints">
            <td>
              <a [routerLink]="['/shopPoints', shopPoint.shopPointId]" routerLinkActive="active">{{ shopPoint.shopPointName }}</a>
            </td>
            <td>{{shopPoint.shopPointLocation}}</td>
            <td>
              <button
                class="btn btn-primary primary-btn"
                (click)="setEditingShopPoint(shopPoint.shopPointId)"
              >
                {{'EDIT' | translate}}
              </button>
            </td>
            <td>
              <button
                class="btn btn-primary remove-btn"
                (click)="modalDelete = true;  shopPointIdToDelete = shopPoint.shopPointId"
              >
                {{'DELETE' | translate}}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-confirmation-modal *ngIf="modalDelete" [title]="'CONFIRM_DELETE_POINT_OF_SALE' | translate"
[message]="'DELETE_POINT_OF_SALE_WARNING' | translate"
[confirmText]="'DELETE' | translate"
[cancelText]="'CANCEL' | translate" (response)="deleteShopPoint($event)">
</app-confirmation-modal>

<div class="modal delete-shopPoint-confirm-modal" id="deleteShopPointModal" tabindex="-1">
  <div class="modal-dialog shopPoint-modal-dialog" role="document">
    <div class="modal-content shopPoint-modal-content">
      <div class="modal-body shopPoint-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}!</h3>
        <p class="text-center">{{ 'POINT_OF_SALE_DELETED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
