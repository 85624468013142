<app-side-bar></app-side-bar>
<app-settings-modal></app-settings-modal>

<div class="container">
  <div class="container-orders">
    <h1 class="title">{{'YOUR_CASH_REGISTERS' | translate}}</h1>
    <div class="loading" *ngIf="loading">
      <div class="spinner-border" role="status"></div>
    </div>
    <div *ngIf="!loading" class="form-card">
      <table class="table table-bordered table-hover">
        <thead>
            <th scope="col" class="table-start">Id</th>
            <th scope="col">{{'STATUS' | translate}}</th>
            <th scope="col">{{'OPENING_DATE' | translate}}</th>
            <th scope="col">{{'OPENING_AMOUNT' | translate}}</th>
            <th scope="col">{{'CLOSING_DATE' | translate}}</th>
            <th scope="col" class="table-end">{{'CLOSING_AMOUNT'| translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let cashRegister of cashRegisters">
            <td>
              <a [routerLink]="['/cashRegister', cashRegister.cashRegisterId]" routerLinkActive="active">{{ cashRegister.cashRegisterId }}</a>
            </td>
            <td>{{ cashRegister.status === 1 ? 'Abierta' : 'Cerrada' }}</td>
            <td>{{ appComponent.formatDate(cashRegister.openDate)}}</td>
            <td>{{ appComponent.formatMoney(cashRegister.initialAmount) }}</td>
            <td>{{ cashRegister.status === 0 ? appComponent.formatDate(cashRegister.closeDate) : 'Pendiente' }}</td>
            <td>{{ cashRegister.status === 0 ? appComponent.formatMoney(cashRegister.finalAmount) : 'Pendiente' }}</td>
        </tbody>
      </table>
    </div>
  </div>
</div>
