<app-side-bar></app-side-bar>


<main class="tabs container main-container">
    <div class="tab-titles">
      <div
        *ngFor="let tab of tabs; let i = index"
        class="tab-title"
        [class.active]="i === activeTab"
        (click)="setActiveTab(i)"
      >
        {{ tab.title }}
      </div>
    </div>
    <div class="tab-content container" #tabContent></div>
</main>
