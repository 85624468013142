import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemingService } from 'src/app/theming.service';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/auth.service';
declare var $: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  permissions: any = {};
  hasAccess!: boolean;

  shopLogos: { white: string; large: string; small: string } = {
    white: '',
    large: '',
    small: ''
  };
  isLoading: boolean = true;
  shopName: string = '';

  constructor(
    private router: Router,
    public themingService: ThemingService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('es');
    translate.use('es');
  }

  ngOnInit(): void {
    this.authService.getUserPermissions().then((permissions) => {
      this.permissions = permissions;
      if ((this.themingService.shopId === 4 || this.themingService.shopId === 9) && this.permissions.calendar === true) {
        this.hasAccess = true;
      }else {
        this.hasAccess = false;
      }

    }).catch((error) => {
      console.error('Error al obtener permisos del usuario:', error);
    });
    this.getShopTheming();
  }

  getShopTheming() {
    this.themingService.getThemingService().then(() => {
      const theming = this.themingService.shopTheming[0];
      if (theming) {
        this.shopLogos.white = theming.shopLogoWhite;
        this.shopLogos.large = theming.shopLogoLarge;
        this.shopLogos.small = theming.shopLogoSmall;
        this.shopName = theming.shopName;
      }
    }).catch((error) => {
      console.error('Error al obtener el servicio de theming:', error);
    }).finally(() => {
      this.isLoading = false; // Los datos han sido cargados
    });
  }

  onSignOut() {
    this.authService.onSignOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  openModal() {
    $('#settingsModal').modal('show');
  }
}
