import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})

export class TranslateComponent {
  selectedLanguage = 'es';
  languages = [
    { value: 'es', viewValue: 'Español', flagClass: 'fi fi-es' },
    { value: 'en', viewValue: 'Inglés', flagClass: 'fi fi-us' }
  ];

  constructor(private translate: TranslateService) {
    this.initializeLanguage();
  }

  switchLanguage(language: string) {
    this.selectedLanguage = language;
    this.translate.use(language).subscribe(() => {
      localStorage.setItem('selectedLanguage', language);
    });
  }

  getSelectedLanguage() {
    return this.languages.find(lang => lang.value === this.selectedLanguage);
  }

  initializeLanguage() {
    const savedLang = localStorage.getItem('selectedLanguage') || 'es';
    this.selectedLanguage = savedLang;
    this.translate.use(savedLang);
  }
}
