
<div class="main">

  <div class="form-floating language-select-container">
    <button class="form-select " type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
      <span [class]="getSelectedLanguage()?.flagClass" class="flag-icon"></span>
      {{ getSelectedLanguage()?.viewValue }}
    </button>
    <label for="dropdownMenuButton">{{'LANGUAGE' | translate}}</label>

    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <li *ngFor="let option of languages">
        <a class="dropdown-item" (click)="switchLanguage(option.value)">
          <span [class]="option.flagClass" class="flag-icon"></span> {{ option.viewValue }}
        </a>
      </li>
    </ul>
  </div>

</div>
