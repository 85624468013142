<div class="container">
  <div class="container-inventory">
    <form #myForm="ngForm">
      <div class="form-card">
      <div class="header">
        <h1 class="title">{{ 'NEW_SUBCATEGORY' | translate }}</h1>
      </div>
      <div class="form-group-container">
        <div class="form-group trio">
          <div class="col-md-3">
            <label class="form-label is-required" for="product_name">{{'NAME' | translate}}</label>
            <input type="text" class="form-control" id="subCategoryName" placeholder="{{ 'SUBCATEGORY_NAME' | translate }}"
            (keyup)="changeSubCategoryName($event)" value="{{subCategory.subCategoryName}}" #subCategoryName/>
          </div>
          <div class="col-md-2">
            <label class="form-label" for="product_category">{{'PARENT_CATEGORY' | translate}}</label>
            <h6 class="select-label">{{ getCategoryNameById(categoryId) }}</h6>
          </div>
          <div class="form-group-image col-md-2">
            <div class="info-image">
            <label class="form-label">Banner</label>
            <div class="hover-text ">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="#6BBECD" d="M19 2a3 3 0 0 1 2.995 2.824L22 5v14a3 3 0 0 1-2.824 2.995L19 22H5a3 3 0 0 1-2.995-2.824L2 19V5a3 3 0 0 1 2.824-2.995L5 2zm-7 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"/></svg>
              <span class="tooltip-text col-md-8" id="top">Las medidas recomendadas para la imagen son de 1400 de ancho x 250px de alto.</span>
            </div>
          </div>
            <div class="image-container">
              <input type="file" id="optionImage" class="form-control-file" (change)="onImageChange($event)" hidden>
              <div *ngIf="imageUrl" class="image-preview">
                <img [src]="imageUrl" alt="Imagen de categoría previsualizada" class="img-thumbnail">
              </div>
              <div class="btn-container">
                <a *ngIf="imageUrl" class="remove-btn" (click)="removeImage()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="#e11d48" d="M19 4h-3.5l-1-1h-5l-1 1H5v2h14M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6z"/></svg>
                </a>
                <a class="add-btn" (click)="triggerFileInput()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 256 256"><path fill="#5E35B1" d="M208 56h-27.72L169 39.12A16 16 0 0 0 155.72 32h-55.44A16 16 0 0 0 87 39.12L75.72 56H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h160a24 24 0 0 0 24-24V80a24 24 0 0 0-24-24m-48 88h-24v24a8 8 0 0 1-16 0v-24H96a8 8 0 0 1 0-16h24v-24a8 8 0 0 1 16 0v24h24a8 8 0 0 1 0 16"/></svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-2 logged-user-switch">
            <label class="form-label">{{'ONLY_VERIFIED' | translate}}</label>
            <mat-slide-toggle [(ngModel)]="subCategory.isForVerifiedClient" class="switch" name="first"></mat-slide-toggle>
          </div>
        </div>
        <div class="form-buttons">
          <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
            {{'CREATE' | translate}}
          </button>
        </div>
      </div>
    </div>
    </form>
  </div>
</div>

<div class="modal category-confirm-modal" id="subCategoryLoadingModal" tabindex="-1">
  <div class="modal-dialog category-modal-dialog" role="document">
    <div class="modal-content category-modal-content">
      <div class="modal-body category-modal-body">
        <div class="loading">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal category-confirm-modal" id="successSubCategoryModal" tabindex="-1">
  <div class="modal-dialog category-modal-dialog" role="document">
    <div class="modal-content category-modal-content">
      <div class="modal-body category-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}!</h3>
        <p class="text-center">{{ 'SUBCATEGORY_CREATED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal category-confirm-modal fade" id="errorSubCategoryModal" tabindex="-1">
  <div class="modal-dialog category-modal-dialog" role="document">
    <div class="modal-content category-modal-content">
      <div class="modal-body category-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal error-confirm-modal fade" id="requiredSubCategoryModal" tabindex="-1">
  <div class="modal-dialog error-modal-dialog" role="document">
    <div class="modal-content error-modal-content">
      <div class="modal-body error-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}!</h3>
        <p class="text-center">{{ 'SUBCATEGORY_NAME_REQUIRED' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
