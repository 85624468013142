<div class="container">
  <div class="container-payment-methods">
    <div class="header">
      <h1 class="title">{{'PAYMENT_METHODS' | translate}}</h1>
      <button class="btn btn-outline-primary primary-btn" (click)="setCreatingPaymentMethod()">{{'ADD' | translate}}</button>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="d-flex justify-content-center align-items-center form-text" *ngIf="!loading && paymentMethods.length == 0">
        <h5>{{ 'NO_PAYMENT_METHODS_REGISTERED' | translate }}</h5>
      </div>
      <table *ngIf="!loading && paymentMethods.length > 0" class="table table-bordered table-hover">
        <thead>
          <th scope="col" class="table-start">{{'PAYMENT_METHOD' | translate}}</th>
          <th scope="col">{{'STATUS' | translate}}</th>
          <th scope="col">{{'EDIT' | translate}}</th>
          <th scope="col" class="table-end">{{'DELETE' | translate}}</th>
        </thead>
        <tbody>
          <tr *ngFor="let paymentMethod of paymentMethods">
            <td>
              <a [routerLink]="['/payment', paymentMethod.paymentTypeId]" routerLinkActive="active" *ngIf="paymentMethod.paymentTypeId != 1 && paymentMethod.paymentTypeId != 8">{{ paymentMethod.paymentTypeName }}</a>
              <a [routerLink]="['/payment', paymentMethod.paymentTypeId]" routerLinkActive="active" *ngIf="paymentMethod.paymentTypeId == 1">{{ paymentMethod.paymentTypeName }} - FAC BAC</a>
              <a [routerLink]="['/payment', paymentMethod.paymentTypeId]" routerLinkActive="active" *ngIf="paymentMethod.paymentTypeId == 8">{{ paymentMethod.paymentTypeName }} - XpressPago</a>
            </td>
            <td>{{'ACTIVE' | translate}}</td>
            <td>
              <button
                class="btn btn-primary primary-btn"
                (click)="setEditingPaymentMethod(paymentMethod.paymentTypeId)"
              >
                {{'EDIT' | translate}}
              </button>
            </td>
            <td>
              <button
                class="btn btn-primary remove-btn" (click)="modalDelete = true;  paymentTypeIdToDelete = paymentMethod.paymentTypeId">
                {{'DELETE' | translate}}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-confirmation-modal *ngIf="modalDelete"
  [title]="'DELETE_PAYMENT_METHOD_TITLE' | translate"
  [message]="'DELETE_PAYMENT_METHOD_MESSAGE' | translate"
  [confirmText]="'DELETE' | translate"
  [cancelText]="'CANCEL' | translate"
  (response)="deletePayment($event)">
</app-confirmation-modal>

<div class="modal delete-paymentMethod-confirm-modal" id="deletePaymentModal" tabindex="-1">
  <div class="modal-dialog paymentMethod-modal-dialog" role="document">
    <div class="modal-content paymentMethod-modal-content">
      <div class="modal-body paymentMethod-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}!</h3>
        <p class="text-center">{{ 'POINT_OF_SALE_DELETED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
