import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Auth } from 'aws-amplify';
import { ApiService } from 'src/app/api.service';
import { AppComponent } from 'src/app/app.component';
import { ThemingService } from 'src/app/theming.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  clientId = 0;
  client: any = {};
  beneficiaries: any;
  sales: any[] = [];
  saleStateName: any;

  emptySales = false; 
  searchString: string = '';
  currentPage: number = 1;
  amount: number = 20;


  loading: boolean = false;

  constructor(
    public appComponent: AppComponent,
    private apiService: ApiService,
    public themingService: ThemingService,
    public route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe((params) => {
      let clientId = Number(params.get('clientId'));
      if (clientId != this.clientId) {
        this.appComponent.goToTop();
        this.clientId = clientId;
      }
    });
   }

  async ngOnInit(): Promise<void> {
    this.saleStateName = {
      0: 'Cancelado',
      1: 'Pendiente de verificación',
      2: 'Preparando orden',
      3: 'Entregado',
      4: 'Pendiente Compra Click',
      5: 'Completado',
      6: 'Parcial'
    };
    await this.getClient();
    this.getClientSales();
}

  async getClient() {
    this.loading = true;
    console.log(
      this.themingService.shopId,
      this.clientId
    )
    await this.apiService
      .getClient(
        this.themingService.shopId,
        this.clientId
      )
      .then((data: any) => {
        console.log("clients: ",data)
        this.client = data.client;
        this.beneficiaries = data.beneficiaries;
      }).catch((error: Error)=>{
        console.log(error)
      })
    this.loading = false;
  }

  async getClientSales(){
    this.loading = true;
    console.log("natID",this.client)
    console.log("natID",this.client.nationalId)
    await this.apiService
      .getClientSales(
        this.client.nationalId,
        this.currentPage - 1,
        this.amount,
        this.searchString,
        this.themingService.shopTheming[0].shopId
      )
      .then((data: any) => {
        console.log("CLIENT ORDERS: ",data)
        this.sales = data.map((sale: any) => {
          sale.saleShortCode = sale.saleOrderCode.substring(
            sale.saleOrderCode.length - 5
          );
          sale.saleDate = this.appComponent.formatDate(sale.saleDate);
          let dateArray = sale.saleDate.split(",");
          sale.saleOnlyDate = dateArray[0];
          return sale;
        });
        console.log("final sales: ",this.sales);
        if(this.sales.length == 0){
          this.emptySales = true;
        }
      });
    this.loading = false;
  }
}
