import { AbstractControl, ValidationErrors, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {
  loading: boolean = false;
  clientForm: FormGroup;
  showPassword: boolean = false;
  username = '';

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.clientForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      phone: ['', Validators.required],
      nationalId: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [{ value: '', disabled: true }, [Validators.required, this.passwordValidator]],
      isPoolRegister: [false],
      isVerifiedClient: [false]
    });
  }

  ngOnInit(): void {
    this.handlePhoneInput();
    this.handleIsPoolRegisterToggle();
  }

  handlePhoneInput(): void {
    this.clientForm.get('phone')?.valueChanges.subscribe((value) => {
      if (value && !value.startsWith('+')) {
        this.clientForm.get('phone')?.setValue('+' + value, { emitEvent: false });
      }
    });
  }

  handleIsPoolRegisterToggle(): void {
    this.clientForm.get('isPoolRegister')?.valueChanges.subscribe((isChecked) => {
      if (isChecked) {
        this.clientForm.get('password')?.enable();  
      } else {
        this.clientForm.get('password')?.disable(); 
        this.clientForm.get('password')?.reset(); 
        this.showPassword = false;
      }
    });
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    if (!password) return null; 

    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);
    const isValidLength = password.length >= 8;

    return !(hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar && isValidLength) ? {
      passwordStrength: {
        hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar, isValidLength
      }
    } : null;
  }

  getPasswordErrors(): string[] {
    const passwordControl = this.clientForm.get('password');
    const errors = passwordControl?.errors?.['passwordStrength'];
    const messages: string[] = [];

    if (errors) {
      if (!errors.hasUpperCase) messages.push('Debe contener al menos una letra mayúscula.');
      if (!errors.hasLowerCase) messages.push('Debe contener al menos una letra minúscula.');
      if (!errors.hasNumber) messages.push('Debe contener al menos un número.');
      if (!errors.hasSpecialChar) messages.push('Debe contener al menos un carácter especial.');
      if (!errors.isValidLength) messages.push('Debe tener al menos 8 caracteres.');
    }

    return messages;
  }

  generatePassword(): void {
    if (this.clientForm.get('password')?.enabled) {
      const length = 12;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
      let password = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
      }
      this.clientForm.controls['password'].setValue(password);
    }
  }

  togglePasswordVisibility(): void {
    if (this.clientForm.get('password')?.enabled) {
      this.showPassword = !this.showPassword;
    }
  }

  createClient(): void {
    if (this.clientForm.valid) {
      this.loading = true;
      const clientData = this.clientForm.value;
      clientData.isVerifiedClient = clientData.isVerifiedClient ? 1 : 0;

      this.apiService.createClient(
        this.themingService.shopId,
        clientData.firstName,
        clientData.lastName,
        clientData.phone,
        clientData.nationalId,
        clientData.email,
        this.username || '',
        clientData.isPoolRegister,
        clientData.password,
        clientData.isVerifiedClient
      ).then((data: any) => {
        this.loading = false;
        if (data.statusCode == 200) {
          $('#successClientModal').modal('show');
          $('#successClientModal').on('hidden.bs.modal', () => {
            this.clientForm.reset();
            this.router.navigate(['/clients']);
          });
        } else {
          this.showErrorModal();
        }
      }).catch((error) => {
        console.log(error);
        this.loading = false;
        this.showErrorModal();
      });
    } else {
      this.showRequiredFieldsModal();
    }
  }

  showErrorModal(): void {
    $('#errorClientModal').modal('show');
    $('#errorClientModal').on('hidden.bs.modal', () => {
      this.clientForm.markAsPristine();
    });
  }

  showRequiredFieldsModal(): void {
    $('#requiredClientModal').modal('show');
  }

  closeModal() {
    $('#successClientModal').modal('hide');
    $('#errorClientModal').modal('hide');
    $('#requiredClientModal').modal('hide');
  }
}
