import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { ThemingService } from './theming.service';
import { WebsocketService } from './websocket.service';
import { TranslateService } from '@ngx-translate/core';
declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  UserLogged: any;
  param = { value: 'world' };

  constructor(
    private themingService: ThemingService,
    public websocketService: WebsocketService,
    private translate: TranslateService
  ) {
    this.websocketService.createWebSocket();
      // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es');
      // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es');
  }

  ngOnInit() {}

  // formatDate(date: Date) {
  //   return date.toLocaleDateString(
  //     'es-' + this.themingService.shopTheming[0].countryCode,
  //     {
  //       day: 'numeric',
  //       month: 'numeric',
  //       year: 'numeric',
  //       hour: '2-digit',
  //       minute: '2-digit',
  //       hour12: true,
  //     }
  //   );
  // }

  formatDate(dateTime: any){
    const [date, time] = dateTime.split("T");
    const [year, month, day] = date.split("-");
    const [hours, minutes, seconds] = time.replace("Z", "").split(":");

    let hours12 = parseInt(hours, 10) % 12 || 12;
    const ampm = parseInt(hours, 10) >= 12 ? 'pm' : 'am';

    const dateTimeFormated = `${day}/${month}/${year} ${hours12}:${minutes} ${ampm}`;
    return dateTimeFormated;
  }

  formatMoney(amount: number) {
    return amount.toLocaleString(
      this.themingService.shopTheming[0].countryLanguage,
      {
        style: 'currency',
        currency: this.themingService.shopTheming[0].currencyCode,
      }
    );
  }

  goToTop() {
    $('html, body').animate({ scrollTop: 0 }, 200, 'swing', function () {});
  }

  randomCacheID(imageURL: string) {
    if (imageURL != 'undefined' && imageURL != '' && imageURL != 'null') {
      var length = 20;
      var result = '';
      var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return `${imageURL}?${result}`;
    } else {
      return '';
    }
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
