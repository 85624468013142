import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ITimekitComponent } from '../timekit-tabs/timekit-tabs.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ThemingService } from 'src/app/theming.service';

interface Project {
  timekitProjectId: number;
  shopId: number;
  timekitResourceId: number;
  projectId: string;
  projectName: string;
}

@Component({
  selector: 'app-timekit-bookings',
  templateUrl: './timekit-bookings.component.html',
  styleUrls: ['./timekit-bookings.component.scss']
})

export class TimekitBookingsComponent implements ITimekitComponent, OnInit, OnDestroy {
  @Input() data: any;
  public projects: Project[] = [];
  public selectedProjectId: string = '';
  public form: FormGroup;

  constructor(
    private el: ElementRef,
    private fb: FormBuilder,
    private apiService: ApiService,
    public themingService: ThemingService,
  ) {
    this.form = this.fb.group({
      resources: ['']
    });
  }

  ngOnInit(): void {
    this.getTimekitProjects();
  }

  ngOnDestroy(): void {
    this.removeBookingJs();
  }

  async getTimekitProjects() {
    try {
      const response = await this.apiService.getTimekitProjects(this.themingService.shopId) as { data: Project[] };
      this.projects = response.data || [];
    } catch (error) {
      console.error('Error al obtener proyectos:', error);
      this.projects = [];
    }
  }

  onProjectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedProjectId = selectElement.value;
    this.updateBookingConfig();
  }

  updateBookingConfig() {
    if (!this.selectedProjectId) {
      console.error('No project selected');
      return;
    }

    const config: any = {
      app_key: 'live_api_key_ozl1I0U8YlxlmwskC7qKBHaBYSAWMSa6',
      project_id: this.selectedProjectId,
      fullcalendar: {
        headerToolbar: {
          left: '',
          center: '',
          right: 'today, prev, next',
          locale: 'es',
        },
      }
    };

    (window as any).timekitBookingConfig = config;
    this.loadBookingJs();
  }

  loadBookingJs() {
    this.removeBookingJs();

    const script = document.createElement('script');
    script.src = 'https://cdn.timekit.io/booking-js/v3/booking.min.js';
    script.defer = true;
    script.onload = () => {
      this.initializeBooking();
    };
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.timekit.io/booking-js/v3/booking.min.css';
    document.head.appendChild(link);
  }

  removeBookingJs() {
    const script = document.querySelector('script[src="https://cdn.timekit.io/booking-js/v3/booking.min.js"]');
    if (script) document.body.removeChild(script);

    const bookingWidget = document.getElementById('bookingjs-widget');
    if (bookingWidget) bookingWidget.innerHTML = '';
  }

  initializeBooking() {
    if ((window as any).timekitBooking) {
      (window as any).timekitBooking().init();
    }
  }
}
