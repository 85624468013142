import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/api.service';
import { ShopTheming, ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';
declare var $: any;

@Component({
  selector: 'app-timekit-create-resource',
  templateUrl: './timekit-create-resource.component.html',
  styleUrls: ['./timekit-create-resource.component.scss']
})

export class TimekitCreateResourceComponent implements OnInit {
  data: any;
  resourceForm: FormGroup;
  resources: any[] = [];
  selectedResource: any;
  loading: boolean = false;
  public shopTheme: ShopTheming[] = [];
  resourceName!: string;
  showNoResourcesMessage: boolean = false;
  timekitResourceId: number = 0;

  ngOnInit(): void {
  }
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService,
  ) {
    const countryTimeZone = this.themingService.shopTheming[0]?.countryTimeZone || '';

    this.resourceForm = this.fb.group({
      name: ['', Validators.required],
      timezone: [countryTimeZone, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [''],
    });
  }

  createResource(): void {
    if (this.resourceForm.valid) {
      this.loading = true;
      const resourceData = this.resourceForm.value;

      this.apiService
        .createResource(
          this.themingService.shopId,
          resourceData.name,
          resourceData.email,
          resourceData.password,
          resourceData.timezone
        )
        .then((data: any) => {
          this.loading = false;

          if (data.statusCode === 200) {
            const parsedBody = JSON.parse(data.body);
            const resourceName = parsedBody.timekit_response?.data?.name;
            const resourceId = parsedBody.timekit_response?.data?.id;

            if (typeof resourceName === 'string' && resourceName.trim() !== '' &&
                typeof resourceId === 'string' && resourceId.trim() !== '') {
              this.createTimekitResource(resourceName, resourceId);
            } else {
              console.error('Resource ID or Resource Name is missing or invalid. Aborting save to database.');
            }

            $('#successResourceModal').modal('show');
            $('#successResourceModal').on('hidden.bs.modal', () => {
              this.resourceForm.reset();
            });
          } else {
            this.showErrorModal();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.showErrorModal();
        });
    } else {
      this.showRequiredFieldsModal();
    }
  }

  createTimekitResource(resourceName: string, resourceId: string): void {

    this.apiService
      .createTimekitResources(
        this.themingService.shopId,
        resourceName,
        resourceId
      )
      .then((data: any) => {
        if (data.status === 200 && data.msg === 'success') {
        } else {
          console.error('Error details:', data.msg || 'No additional error details available');
        }
      })
      .catch((error) => {
        this.loading = false;
      });
  }

  showErrorModal(): void {
    $('#errorResourceModal').modal('show');
    $('#errorResourceModal').on('hidden.bs.modal', () => {
      this.resourceForm.markAsPristine();
    });
  }

  showRequiredFieldsModal(): void {
    $('#requiredResourceModal').modal('show');
  }

  closeModal() {
    $('#successResourceModal').modal('hide');
    $('#errorResourceModal').modal('hide');
    $('#requiredResourceModal').modal('hide');
  }

  setListingResources(){
    this.settingsService.setListingResources();
  }

}
