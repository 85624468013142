import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import {  ThemingService } from 'src/app/theming.service';
import { SettingsService } from 'src/app/settings.service';

@Component({
  selector: 'app-timekit-resources',
  templateUrl: './timekit-resources.component.html',
  styleUrls: ['./timekit-resources.component.scss']
})

export class TimekitResourcesComponent implements OnInit {

  showNoResourcesMessage: boolean = false;
  resources: any[] = [];
  loading: boolean = false;

  constructor(
    private apiService: ApiService,
    public themingService: ThemingService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit(): void {
    this.getTimekitResources()
  }

  async getTimekitResources() {
    this.loading = true;
    this.showNoResourcesMessage = false;

    try {
      const response = await this.apiService.getTimekitResources(this.themingService.shopId) as { data: { resourceId: string, resourceName: string }[] };

      this.resources = response.data || [];

    } catch (error) {
      console.error('Error al obtener recursos:', error);
      this.resources = [];
    } finally {
      this.loading = false;
    }
  }

  setCreatingResources(){
    this.settingsService.setCreatingResources();
  }
}
