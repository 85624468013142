<div class="container">
  <div *ngIf="supplier" class="container-supplier">
    <div class="header">
      <h1 class="title">{{ 'EDIT_SUPPLIER' | translate }}</h1>
      <app-back-arrow-settings [callback]="setListingSuppliers"></app-back-arrow-settings>
    </div>
    <div class="form-card">
      <div class="loading" *ngIf="loading">
        <div class="spinner-border" role="status"></div>
      </div>
      <div *ngIf="!loading" class="form-group-container">
        <label class="form-label" for="supplier_name">{{'NAME' | translate}}</label>
        <input type="text" class="form-control" id="supplierName"
          placeholder="{{'SUPPLIER_NAME' | translate}}"
          (keyup)="changeSupplierName($event)" value="{{supplier.supplierName}}" #supplierName/>
        <label class="form-label" for="supplier_description">{{'DESCRIPTION' | translate}}</label>
        <textarea class="form-control" id="supplierDescription" placeholder="{{'SUPPLIER_DESCRIPTION' | translate}}"
          (keyup)="ChangeSupplierDescription($event)" #supplierDescription>{{supplier.supplierDescription}}</textarea>
        <div class="form-buttons">
          <button type="button" class="btn btn-outline-secondary submit-btn" (click)="validateFormData()">
            {{'SAVE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal supplier-confirm-modal fade" id="successSupplierModal" tabindex="-1">
  <div class="modal-dialog supplier-modal-dialog" role="document">
    <div class="modal-content supplier-modal-content">
      <div class="modal-body supplier-modal-body">
        <h3 class="text-center">{{'SUCCESS' | translate}}</h3>
        <p class="text-center">{{ 'SUPPLIER_SAVED_SUCCESS' | translate }}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal supplier-confirm-modal fade" id="errorSupplierModal" tabindex="-1">
  <div class="modal-dialog supplier-modal-dialog" role="document">
    <div class="modal-content supplier-modal-content">
      <div class="modal-body supplier-modal-body">
        <h3 class="text-center">{{'ERROR' | translate}}</h3>
        <p class="text-center">{{'ERROR_SAVING_CHANGES' | translate}}</p>
      </div>
      <div class="modal-buttons">
        <button type="button" class="btn btn-confirm" (click)="closeModal()">{{'CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>
